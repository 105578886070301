function SingleSubmitCtrl(
  $q,
  $log,
  $scope,
  $state,
  $stateParams,
  FormErrorType,
  formConverterService,
  $translate,
  formModelService,
  $injector,
  toaster,
  dynamicEvidenceService,
  $uibModal,
  offlineFormService,
  $rootScope,
  formErrorService,
  $exceptionHandler,
  $timeout
) {

  function handleInvalidState(errMsg) {
    $scope.submissionFailedError = errMsg;
    $log.error(errMsg);
    return $q.reject();
  }

  function getEvidence(formFields) {
    if ($scope.formDefinition.evidence && $scope.formDefinition.evidence.include_in_PDF) {
      return dynamicEvidenceService.getEvidenceFromJson($stateParams.formId, formFields)
        .then(function (allRequiredEvidence) {
          return allRequiredEvidence;

        });
    } else {
      return $q.when();
    }
  }

  function isOfflineSubmission() {
    return $state.current.name === "main.forms.offline.edit";
  }

  function showApplicationReviewState() {
    var stateParams = {formId: $stateParams.formId, id: $stateParams.id};

    if (isOfflineSubmission()) {
      stateParams.offline = true;
    }

    // If the submitted form is a clone, it will have been reassigned back to the original owner during submission.
    // Therefore the form's post-submit view will be inaccessible to the current user, so send them to form list view
    // instead.
    var nextState = $scope.formModelInstance.isClone() ? 'main.forms.list' : 'main.forms.review';
    return $state.go(nextState, stateParams, {location: 'replace'});
  }

  /**
   * Submit a form remotely
   * @return {Promise}
   */
  $scope.submitRemotely = function () {

    if (!$scope.formDefinition.is_submit_once) {
      return handleInvalidState("This application cannot be submitted");

    } else if ($scope.formModelInstance.isSubmitted()) {
      return handleInvalidState("This application cannot be submitted again");
    }

    $scope.submissionFailedError = null;
    $scope.submissionInProgress = true;
    var customAuthToken = $scope.formModelInstance.token;

    var cleanModel = formModelService.getSubmittableFormModelFields($scope.formDefinition, $scope.model);

    return $q(function (resolve, reject) {
      return $scope.validateCurrentPage() ? resolve() : reject();
    })
      .catch(function () {
        $scope.submissionInProgress = false;
        console.warn("Validation failed on the current page");
        return $q.reject(FormErrorType.VALIDATION_FAILED);
      })
      .then(function () {
        return $scope.validateAllPages()
          .then(function () {
            try {
              $scope.formService.preSubmissionHandler($scope.model, cleanModel);
            } catch(e) {
              var o = Offline.check();
              $timeout(function () {

                var formErrorType = $rootScope.app.isOffline ? FormErrorType.OFFLINE_CALCULATION_ERROR : FormErrorType.CALCULATION_ERROR;
                formErrorService.setErrorState(
                  'fatal',
                  formErrorType
                );

                $exceptionHandler(e, 'Calculation error', undefined, {
                  form_data: $scope.model
                });
              }, 0);

              return $q.reject(e);
            }
            var deferred = $q.defer();
            var conditionsService = $injector.get($scope.formDefinition.formConditions);

            getEvidence(cleanModel)
              .catch(function(e) {
                var err = new Error(FormErrorType.EVIDENCE_GENERATION_ERROR);
                $exceptionHandler(err, 'Evidence generation error', undefined, {
                  error: e,
                });
              })
              .then(function (evidence) {
                var pdfHtmlOptions = {evidence: evidence};

                var postParams = {
                  dirtyModel: $scope.model,
                  formModel: cleanModel,
                  pdfHtml: formConverterService.toPdfHtml(conditionsService, $scope.form, $scope.schema, cleanModel, pdfHtmlOptions)
                };

                return formModelService.submitForm($stateParams.id, postParams, customAuthToken);
              })
              .then(function () {
                $scope.formModelInstance.setSubmittedFields(cleanModel);

                offlineFormService.updateForm($stateParams.id, function (offlineForm) {
                  offlineForm.form.submittedFields = cleanModel;
                });
                return formModelService.getUpdatedFieldValues(cleanModel, $scope.formDefinition);
              })
              .then(function (newFacts) {
                // We can't update the facts for a cloned form, because at this point it has been re-assigned
                // (to the owner of the form it was cloned from). Calling the update
                if (angular.isObject(newFacts) && !$scope.formModelInstance.isClone()) {
                  return formModelService.updateFactsByFormId($stateParams.id, newFacts, customAuthToken);
                }
                return $q.resolve();
              })
              .then(deferred.resolve)
              .catch(deferred.reject);

            return deferred.promise;
          })
          .then(function () {
            return showApplicationReviewState().then(function () {
              toaster.pop('success', 'Your application has been submitted to ' + $translate.instant('app.organisation'));
            });
          })
          .catch(function (e) {
            if (e.data && e.data.errorCode === 'illegalFormUpdate') {
              var modal = $uibModal.open({
                size: 'sm',
                templateUrl: 'modules/forms/views/modals/formSubmissionFailed.html'
              });

              modal.result.finally(showApplicationReviewState);

            } else if (e !== FormErrorType.VALIDATION_FAILED) {
              $('html, body').animate({scrollTop: 0}, 50);
              console.warn("Submission failed on other pages");
              $scope.submissionFailedError = "Submission of your application failed, but your application data has not been lost. Please try again later.";

              var err = new Error(FormErrorType.FORM_SUBMISSION_ERROR);
              $exceptionHandler(err, 'Form submission error', undefined, {
                data: $scope.form,
                error: e,
              });
            }
          })
          .finally(function () {
            $scope.submissionInProgress = false;
          });
      });
  };

  $rootScope.$on('form-error-fatal', function(e, args) {
    $scope.submissionInProgress = false;
  });
}

SingleSubmitCtrl.$inject = [
  '$q',
  '$log',
  '$scope',
  '$state',
  '$stateParams',
  'FormErrorType',
  'formConverterService',
  '$translate',
  'formModelService',
  '$injector',
  'toaster',
  'dynamicEvidenceService',
  '$uibModal',
  'offlineFormService',
  '$rootScope',
  'formErrorService',
  '$exceptionHandler',
  '$timeout'
];

angular.module('kerp-forms.forms').controller('SingleSubmitCtrl', SingleSubmitCtrl);
