'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'HE_FCA_FORM',
    [
      'FormUI',
      'herefordshireCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      'fieldDefinitionService',
      'HE_FCA_CONDITIONS',
      '$window',

      function (
        FormUI,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        fieldDefinitionService,
        conditions,
        $window
      ) {

        var constants = $window.kerpCfa.getConstants('HE_FCA');

        var formUI = new FormUI();

        function periodicPaymentField(fieldName, options) {
          return {
            type: 'section',
            htmlClass: 'row',
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-4"
              }
            ]
          };
        }

        function fullWidthPeriodicPaymentField(fieldName, options, condition) {
          condition = condition || 'true';

          return {
            type: 'section',
            htmlClass: 'row',
            condition: condition,
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-7 col-sm-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-3 col-sm-4"
              }
            ]
          };
        }

        function fullWidthPeriodicPaymentFieldWithShareCount(fieldName, options, condition) {
          const field = fullWidthPeriodicPaymentField(fieldName, options, condition);
          field.items.push({
            key: fieldName + 'ShareCount',
            condition: 'model.' + fieldName,
            htmlClass: "col-md-7 col-sm-4"
          });
          return field;
        }

        function descriptionFor(fieldName) {
          return {
            key: fieldName + 'Description',
            condition: 'model.' + fieldName
          };
        }


        function paymentField(fieldName, overrides) {
          return angular.extend({
            key: fieldName,
            feedback: false
          }, overrides);
        }

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel);
        };

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          var details = (model.person || {}).details || {};
          var withholdFinancialDetailsAndPayFullCost = intro.provideFinancialDetails === 'payFullCost';
          var withholdFinancialDetailsAndArrangeOwnCare = intro.provideFinancialDetails === 'arrangeMyOwn';
          var capitalOverThreshold = intro.provideFinancialDetails === 'capitalOverThreshold';

          var excludeNonResidentialCaretypes = intro.excludeNonResidentialCaretypes === true || details.alreadyInPermanentCare === 'yes';
          let hiddenPages = [];

          if (withholdFinancialDetailsAndPayFullCost || capitalOverThreshold) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9];
          } else if (withholdFinancialDetailsAndArrangeOwnCare) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9, 10, 11];
          } else {
            hiddenPages = [];
            if (excludeNonResidentialCaretypes) {
              hiddenPages = [4, 8, 9];
            }
          }

          return hiddenPages;
        };

        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            htmlClass: 'row',
            items: [
              {
                type: 'fieldset',
                title: 'People acting on your behalf',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/agentVideoGuidance.html'
                  },
                  'agent.hasAppointee',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>Appointeeship is granted by the Department for Work and Pensions and it gives a person the authority to deal with the benefits of someone who cannot manage their own affairs because they’re mentally incapable or severely disabled. Additional information is available on <a href=\"https://www.gov.uk/become-appointee-for-someone-claiming-benefits\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasAppointee',
                    key: 'agent.appointeeDetails'
                  },
                  'agent.hasDeputy',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>Deputyship is awarded by the Office of Public Guardian and it gives a person the authority to deal with someone’s finances as they ‘lack mental capacity’. This means they cannot make a decision for themselves at the time it needs to be made. They may still be able to make decisions for themselves at certain times. Additional information is available on <a href=\"https://www.gov.uk/become-deputy\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasDeputy',
                    key: 'agent.deputyDetails'
                  },
                  'agent.hasPowerOfAttorney',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>A power of attorney is a legal document that allows someone to make decisions for you, or act on your behalf, if you\'re no longer able to or if you no longer want to make your own decisions. A lasting power of attorney has to be registered with the Office of the Public Guardian before it comes into force and can be used, and your attorney can act for you. Additional information is available on <a href=\"https://www.gov.uk/power-of-attorney\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasPowerOfAttorney',
                    type: 'fieldset',
                    items: [
                      'agent.powerOfAttorneyDetails.name',
                      'agent.powerOfAttorneyDetails.surname',
                      'agent.powerOfAttorneyDetails.addressLine1',
                      'agent.powerOfAttorneyDetails.addressLine2',
                      'agent.powerOfAttorneyDetails.addressLine3',
                      'agent.powerOfAttorneyDetails.postcode',
                      'agent.powerOfAttorneyDetails.phoneNumber',
                      'agent.powerOfAttorneyDetails.email',
                      'agent.powerOfAttorneyDetails.relationship',
                      'agent.powerOfAttorneyDetails.registeredWithOPG',
                      {
                        key: 'agent.powerOfAttorneyDetails.opgRegNo',
                        title: 'Please enter your OPG registration number',
                        condition: 'model.agent.powerOfAttorneyDetails.registeredWithOPG'
                      }
                    ],
                  },
                  'agent.isCompletedByAgent',
                  {
                    condition: 'model.agent.isCompletedByAgent',
                    key: 'agent.completedByDetails'
                  }
                ]
              },
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 2',
            page: 2,
            htmlClass: "row",
            items: [
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: ['clientID']
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About you',
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_02_about-you.html'
                  },
                  'person.details.title',
                  'person.details.firstName',
                  'person.details.middleName',
                  'person.details.lastName',
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/addressLookup.html",
                    homeAddressDescription: "If you have moved permanently into a Care Home please put the address of your previous main home."
                  },
                  // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                  {
                    key: 'person.details.address.line1',
                    title: 'Address line 1',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line2',
                    title: 'Address line 2',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line3',
                    title: 'Address line 3',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.postcode',
                    title: 'Postcode',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.uprn',
                    title: 'Property Reference',
                    type: "hidden"
                  },
                  'person.details.homePhoneNumber',
                  'person.details.mobilePhoneNumber',
                  'person.details.email',
                  {
                    key: 'person.details.dob',
                    description: 'We need this information to calculate your State Pension age'
                  }
                ]
              },


              {
                type: 'fieldset',
                title: "Declaring your financial circumstances",
                htmlClass: "col-md-12",
                items: [
                  'person.intro.provideFinancialDetails',
                  {
                    key: 'person.details.nationalInsuranceNumber',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.details.alreadyInPermanentCare',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.excludeNonResidentialCaretypes',
                    condition: 'model.person.details.alreadyInPermanentCare === "no"'
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.details.alreadyInPermanentCare === "yes"',
                    items: [
                      {
                        type: "fieldset",
                        items: [
                          {
                            type: "help",
                            helpvalue: "<div class=\"html-view-remove\"><p>Please enter the address of the Care Home.</p></div"
                          },
                          {
                            key: 'person.details.careHomeAddress.line1',
                            title: 'Address line 1',
                          },
                          {
                            key: 'person.details.careHomeAddress.line2',
                            title: 'Address line 2',
                          },
                          {
                            key: 'person.details.careHomeAddress.line3',
                            title: 'Address line 3',
                          },
                          {
                            key: 'person.details.careHomeAddress.postcode',
                            title: 'Postcode',
                          },

                        ]
                      },
                      'person.details.dateEnteredResidentialCare',
                    ],
                  },
                  {
                    key: 'person.intro.hasPartner',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/HE_FCA/templates/partnerDescription.html')
                  },
                  {
                    key: 'person.intro.partnerStatus',
                    condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.doesOtherRecieveCarersAllowance',
                    condition: 'model.person.details.alreadyInPermanentCare === "no"'
                  },
                  {
                    key: 'person.intro.carersAllowanceIsEntitled',
                    condition: 'model.person.details.alreadyInPermanentCare === "no"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/HE_FCA/templates/carersAllowanceEntitlement.html')
                  },
                ]
              },

              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About your partner',
                condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                items: [

                  'partner.details.firstName',
                  'partner.details.middleName',
                  'partner.details.lastName',
                  'partner.details.homePhoneNumber',
                  'partner.details.mobilePhoneNumber',
                  'partner.details.email',
                  'partner.details.dob',

                  {
                    key: 'partner.details.sameAddress',
                    title: 'Does your partner live at your former home?',
                    condition: 'model.person.details.alreadyInPermanentCare === "yes"',
                  },

                  {
                    key: 'partner.details.sameAddress',
                    condition: 'model.person.details.alreadyInPermanentCare !== "yes"',
                  },

                  {
                    key: 'partner.intro.alreadyReceivingCare',
                    condition: 'model.partner.details.sameAddress === true',
                  },

                  {
                    type: 'fieldset',
                    condition: 'model.partner.details.sameAddress === false',
                    items: [
                      {
                        key: 'partner.details.partnerReceivesResidentialCare',
                        condition: 'model.partner.details.sameAddress === false',
                      },

                      {
                        type: "template",
                        templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/partnerAddressLookup.html",
                        homeAddressDescription: "Please provide your partner's address."
                      },
                      {
                        key: 'partner.intro.address.line1',
                        title: 'Partner address line 1',
                        type: "hidden"
                      },
                      {
                        key: 'partner.intro.address.line2',
                        title: 'Partner address line 2',
                        type: "hidden"
                      },
                      {
                        key: 'partner.intro.address.line3',
                        title: 'Partner address line 3',
                        type: "hidden"
                      },
                      {
                        key: 'partner.intro.address.postcode',
                        title: 'Partner postcode',
                        type: "hidden"
                      },
                      {
                        key: 'partner.intro.address.uprn',
                        title: 'Property Reference',
                        type: "hidden"
                      },
                    ]
                  },

                  {
                    type: 'fieldset',
                    condition: 'model.partner.details.sameAddress === true',
                    items: [
                      {
                        key: 'partner.intro.discloseFinances',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/HE_FCA/templates/partnersFinancesDescription.html')
                      },
                      {
                        key: 'partner.details.nationalInsuranceNumber',
                        condition: 'model.partner.intro.discloseFinances'
                      },
                      {
                        key: 'partner.intro.carersAllowanceIsEntitled',
                        condition: 'model.partner.intro.discloseFinances'
                      },
                      {
                        key: 'partner.intro.doesOtherRecieveCarersAllowance',
                        condition: 'model.partner.intro.discloseFinances'
                      }
                    ],
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 3',
            page: 3,
            title: 'Property/Land you own or rent',
            items: [
              'person.property.ownership',
              {
                key: 'person.property.jointTenantPropertyDetails',
                condition: 'model.person.property.ownership === "ownedJointlyTenants"',
                title: 'Details of the Property owned as Joint Tenants'
              },
              {
                key: 'person.property.jointCommonPropertyDetails',
                condition: 'model.person.property.ownership === "ownedJointlyCommon"',
                title: 'Details of the Property owned as Tenants in common'
              },
              {
                key: 'person.property.equityShare',
                condition: 'model.person.property.ownership === "ownedJointlyTenants" || model.person.property.ownership === "ownedJointlyCommon"',
              },
              {
                key: 'person.property.namedOnContract',
                condition: 'model.person.property.ownership === "councilTenant" || model.person.property.ownership === "rentedPrivately"',
                title: 'Are you named on the tenancy agreement?',
                description: 'We may ask you to provide your tenancy agreement and confirmation of service charges.',
              },
              'person.property.propertyType',
              'person.property.noOfBedrooms',

              {
                type: 'fieldset',
                condition: 'model.person.property.ownership === "councilTenant" || model.person.property.ownership === "rentedPrivately"',
                items: [
                  'person.property.landlordName',
                  'person.property.landlordAddress',
                  'person.property.landlordTelephone',
                ]
              },

              {
                key: 'person.property.otherProperty',
                condition: 'model.person.property.ownership === "other"'
              },
              {
                key: 'person.property.value',
                description: "If you are interested in all types of care costs, please enter the approximate value of your home address. If you are <em>only</em> interested in Non Residential Care costs, we do not need to know the value of your home address so you can enter £0.00.",
                condition: conditions.toString('person.property.value')
              },
              'person.property.hasAdditionalProperties',
              {
                key: 'person.property.additionalProperties',
                condition: 'model.person.property.hasAdditionalProperties',
                add: "Add property",
                title: 'We need to know value(s) of any property/properties, including land, owned and/or jointly owned by you in this country or abroad other than your main home.',
                validationMessage: 'Enter details of at least one property'
              },
              'person.property.additionalInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 4',
            page: 4,
            title: 'People living with you',
            items: [
              {
                key: 'peopleLivingWithYou.hasDependentChildren',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/HE_FCA/templates/dependentChildrenDescription.html')
              },
              {
                key: 'peopleLivingWithYou.dependentChildren',
                condition: 'model.peopleLivingWithYou.hasDependentChildren',
                add: "Add Child",
                title: 'Dependent children details',
                validationMessage: 'Complete all required fields for at least one child'
              },
              {
                key: 'peopleLivingWithYou.hasOtherPeople'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/nonDependentsDescription.html'
              },
              {
                key: 'peopleLivingWithYou.otherPeople',
                condition: 'model.peopleLivingWithYou.hasOtherPeople',
                add: "Add Person",
                title: 'Details of other people living with you',
                validationMessage: 'Complete all required fields for at least one person'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 5',
            page: 5,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income and benefits',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_04_income-intro.html'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'person.income.hasWage',
                        title: 'Are you currently employed?'
                      },
                      {
                        key: 'person.income.wages',
                        condition: 'model.person.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pension or Annuity</h4>"
                      },
                      {
                        key: 'person.income.hasPrivatePension',
                        title: 'Are you in receipt of private/works pension or annuity?'
                      },
                      {
                        key: 'person.income.privatePensions',
                        condition: 'model.person.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      {
                        key: 'person.details.transferHalfPensionToPartner',
                        title: 'Disregard half pension and transfer to partner?',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/HE_FCA/templates/transferHalfPensionToPartner.html'),
                        condition: 'model.person.income.hasPrivatePension && model.person.intro.hasPartner && model.partner.details.partnerReceivesResidentialCare !== true'
                      },

                      periodicPaymentField('person.income.retirementPension'),

                      'person.income.hasDeferredPension',

                      {
                        type: "fieldset",
                        condition: '!(model.partner.income.hasUniversalCredit && model.partner.income.universalCreditIsJoint)',
                        items: [
                          {
                            key: 'person.income.hasUniversalCredit',
                            title: 'Are you in receipt of Universal Credit?'
                          },
                          {
                            type: 'template',
                            templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/universalCreditGuidance.html'
                          },
                          {
                            key: 'person.income.universalCreditIsJoint',
                            condition: 'model.person.intro.hasPartner && model.partner.details.sameAddress && model.person.income.hasUniversalCredit'
                          },
                          {
                            key: 'person.income.universalCreditBreakdown',
                            notitle: true,
                            title: 'Universal Credit Allowances',
                            condition: 'model.person.income.hasUniversalCredit'
                          },
                        ]
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Are you in receipt of any of the benefits listed below?</h4>"
                      },

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.incomeSupportPayment',
                        condition: '!model.partner.income.incomeSupportPaymentIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.incapacityBenefit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.employmentSupport',
                        condition: '!model.partner.income.employmentSupportIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      {
                        key: 'person.income.employmentSupportType',
                        condition: 'model.person.income.employmentSupport',
                        description: 'Income Related ESA is a means tested benefit that is no longer available for new claimants, but some people still receive Income Related ESA if they have not yet moved over to Universal Credit. New Style ESA (previously known as Contribution based ESA) is a contributory benefit.  Normally, this means you will claim this benefit if you have been paid or credited with enough National Insurance contributions in the 2 full tax years before the year you claim.  For further information please visit <a href="https://www.gov.uk/employment-support-allowance">the government website</a>.'
                      },
                      {
                        key: 'person.income.employmentSupportGroup',
                        condition: 'model.person.income.employmentSupport'
                      },
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.jobSeekersAllowance',
                        condition: '!model.partner.income.jobSeekersAllowanceIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.rentalIncome',
                        condition: '!model.partner.income.rentalIncomeIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.workingTaxCredit',
                        condition: '!model.partner.income.workingTaxCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.maintenance'),
                      periodicPaymentField('person.income.warPensionMobilitySupplement'),
                      periodicPaymentField('person.income.warWidowsPension'),
                      periodicPaymentField('person.income.warSpecialPayment'),

                      periodicPaymentField('person.income.industrialInjuriesBenefit'),
                      {
                        key: 'person.income.industrialInjuriesIncludesCAA',
                        condition: 'model.person.income.industrialInjuriesBenefit'
                      },
                      periodicPaymentField('person.income.warPension'),
                      {
                        key: 'person.income.warPensionIncludesCAA',
                        condition: 'model.person.income.warPension'
                      },
                      paymentField('person.income.constantAttendanceAllowance', {
                        condition: 'model.person.income.warPensionIncludesCAA',
                        description: 'Please specify how much Constant Attendance Allowance you receive as part of your War Pension.'
                      }),
                      periodicPaymentField('person.income.otherConstantAttendanceAllowance', {
                        condition: conditions.toString('person.income.otherConstantAttendanceAllowance'),
                        descrption: 'Please state the amount of Constant Attendance Allowance you receive'
                      }),

                      periodicPaymentField('person.income.carersAllowance'),

                      paymentField('person.income.attendanceAllowance', {
                        condition: conditions.toString('person.income.attendanceAllowance')
                      }),
                      paymentField('person.income.dlaCare', {
                        condition: conditions.toString('person.income.dlaCare')
                      }),
                      paymentField('person.income.pipDailyLiving', {
                        condition: conditions.toString('person.income.pipDailyLiving')
                      }),

                      {
                        key: 'person.income.receivesExtraCare',
                        condition: '(model.person.income.attendanceAllowance && model.person.income.attendanceAllowance === "high") || (model.person.income.dlaCare && model.person.income.dlaCare === "high")'
                      },

                      paymentField('person.income.mobilityAllowance'),

                      'person.income.mobilityCar',

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.pensionGuaranteeCredit',
                        condition: '!model.partner.income.pensionGuaranteeCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.pensionSavingsCredit',
                        condition: '!model.partner.income.pensionSavingsCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.charitableIncome'),

                      periodicPaymentField('person.income.selfEmployedIncome'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.bondIncome',
                        condition: '!model.partner.income.bondIncomeIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      {
                        key: 'person.income.bondIncomeIsLifeAssured',
                        condition: 'model.person.income.bondIncome'
                      },

                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },
                      'person.details.inEducation',
                      {
                        key: 'person.details.educationDetails',
                        condition: 'model.person.details.inEducation'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's income and benefits",
                    htmlClass: "col-md-6",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    items: [
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'partner.income.hasWage',
                        title: 'Is your partner currently employed?'
                      },
                      {
                        key: 'partner.income.wages',
                        condition: 'model.partner.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pension or Annuity</h4>"
                      },
                      {
                        key: 'partner.income.hasPrivatePension',
                        title: 'Is your partner in receipt of private/works pension or annuity?'
                      },
                      {
                        key: 'partner.income.privatePensions',
                        condition: 'model.partner.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      periodicPaymentField('partner.income.retirementPension'),

                      'partner.income.hasDeferredPension',

                      {
                        type: "fieldset",
                        condition: '!(model.person.income.hasUniversalCredit && model.person.income.universalCreditIsJoint)',
                        items: [

                          {
                            key: 'partner.income.hasUniversalCredit',
                            title: 'Is your partner in receipt of Universal Credit?'
                          },
                          {
                            key: 'partner.income.universalCreditIsJoint',
                            condition: 'model.person.intro.hasPartner && model.partner.details.sameAddress && model.partner.income.hasUniversalCredit'
                          },
                          {
                            key: 'partner.income.universalCreditBreakdown',
                            notitle: true,
                            title: 'Universal Credit Allowances',
                            condition: 'model.partner.income.hasUniversalCredit'
                          },
                        ]
                      },

                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Is your partner in receipt of any of the benefits listed below?</h4>"
                      },

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.incomeSupportPayment',
                        condition: '!model.person.income.incomeSupportPaymentIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.incapacityBenefit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.employmentSupport',
                        condition: '!model.person.income.employmentSupportIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      {
                        key: 'partner.income.employmentSupportType',
                        condition: 'model.person.intro.hasPartner && model.partner.income.employmentSupport',
                        description: 'Income Related ESA is a means tested benefit that is no longer available for new claimants, but some people still receive Income Related ESA if they have not yet moved over to Universal Credit. New Style ESA (previously known as Contribution based ESA) is a contributory benefit.  Normally, this means your partner will claim this benefit if they have been paid or credited with enough National Insurance contributions in the 2 full tax years before the year hey claim.  For further information please visit <a href="https://www.gov.uk/employment-support-allowance">the government website</a>.'
                      },
                      {
                        key: 'partner.income.employmentSupportGroup',
                        condition: 'model.person.intro.hasPartner && model.partner.income.employmentSupport'
                      },
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.jobSeekersAllowance',
                        condition: '!model.person.income.jobSeekersAllowanceIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.rentalIncome',
                        condition: '!model.person.income.rentalIncomeIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),


                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.workingTaxCredit',
                        condition: '!model.person.income.workingTaxCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('partner.income.maintenance'),
                      periodicPaymentField('partner.income.warPensionMobilitySupplement'),
                      periodicPaymentField('partner.income.warWidowsPension'),
                      periodicPaymentField('partner.income.warSpecialPayment'),

                      periodicPaymentField('partner.income.industrialInjuriesBenefit'),
                      {
                        key: 'partner.income.industrialInjuriesIncludesCAA',
                        condition: 'model.partner.income.industrialInjuriesBenefit'
                      },
                      periodicPaymentField('partner.income.warPension'),
                      {
                        key: 'partner.income.warPensionIncludesCAA',
                        condition: 'model.partner.income.warPension'
                      },
                      paymentField('partner.income.constantAttendanceAllowance', {
                        condition: 'model.partner.income.warPensionIncludesCAA',
                        description: 'Please specify how much Constant Attendance Allowance your partner receives as part of their War Pension.'
                      }),
                      periodicPaymentField('partner.income.otherConstantAttendanceAllowance', {
                        condition: conditions.toString('partner.income.otherConstantAttendanceAllowance'),
                        descrption: 'Please state the amount of Constant Attendance Allowance your partner receives'
                      }),

                      periodicPaymentField('partner.income.carersAllowance'),

                      paymentField('partner.income.attendanceAllowance', {
                        condition: conditions.toString('partner.income.attendanceAllowance')
                      }),
                      paymentField('partner.income.dlaCare', {
                        condition: conditions.toString('partner.income.dlaCare')
                      }),
                      paymentField('partner.income.pipDailyLiving', {
                        condition: conditions.toString('partner.income.pipDailyLiving')
                      }),

                      paymentField('partner.income.mobilityAllowance'),

                      'partner.income.mobilityCar',

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.pensionGuaranteeCredit',
                        condition: '!model.person.income.pensionGuaranteeCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.pensionSavingsCredit',
                        condition: '!model.person.income.pensionSavingsCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('partner.income.charitableIncome'),

                      periodicPaymentField('partner.income.selfEmployedIncome'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.bondIncome',
                        condition: '!model.person.income.bondIncomeIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      {
                        key: 'partner.income.bondIncomeIsLifeAssured',
                        condition: 'model.partner.income.bondIncome'
                      },

                      'partner.income.hasOtherBenefit',
                      {
                        key: 'partner.income.otherBenefit',
                        condition: 'model.partner.income.hasOtherBenefit'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 6',
            page: 6,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your money or investments',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_03_capital-intro.html'
                      },
                      {
                        key: 'person.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasSavingsInvestments',
                        title: 'Does your partner have any money or investments which are held in accounts where they are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you and someone else are both named account holders?',
                        description: 'Only include joint accounts where the amount is owned by more than one person. Accounts with additional names for access-only reasons are treated as single owner accounts.'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/capitalDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of your savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      },
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsAndInvestmentsIncludeLifeAssurance'
                      }

                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.partner.capital.hasSavingsInvestments',
                        key: 'partner.capital.savingsInvestments',
                        add: "Add account",
                        title: "Give details of your partner's savings and investments",
                        validationMessage: 'Enter details of at least one account'
                      },
                      {
                        condition: 'model.partner.capital.hasSavingsInvestments',
                        key: 'partner.capital.savingsAndInvestmentsIncludeLifeAssurance'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of jointly owned savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      },
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsAndInvestmentsIncludeLifeAssurance'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your shareholdings',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasShareholdings',
                        title: 'Are you the sole owner of any company shares?'
                      },
                      {
                        key: 'person.capital.shareholdings',
                        condition: 'model.person.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of your shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasShareholdings',
                        title: 'Is your partner the sole owner of any company shares?'
                      },
                      {
                        key: 'partner.capital.shareholdings',
                        condition: 'model.partner.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: "Give details of your partner's shareholdings",
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasShareholdings',
                        title: 'Do you and someone else jointly own any company shares?'
                      },
                      {
                        key: 'joint.capital.shareholdings',
                        condition: 'model.joint.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of jointly owned shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 7',
            page: 7,
            title: 'Sale or Transfer of property, money or investments',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12_capital_transfers-gifting-intro.html'
              },
              {
                key: 'deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/deprivationOfAssetsGuidance.html'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.assetValue',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.dateOfDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.assetsDisposed',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/deprivationOfAssetsDescription.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.reasonForDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/deprivationOfAssetsReason.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 8',
            page: 8,
            title: 'Household Expenditure',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/householdExpensesDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-8",
                    items: [

                      fullWidthPeriodicPaymentFieldWithShareCount('person.expenses.mortgageLessHousing', {}, '!model.person.income.hasUniversalCredit'),
                      fullWidthPeriodicPaymentFieldWithShareCount('person.expenses.mortgage', {}, 'model.person.income.hasUniversalCredit'),

                      fullWidthPeriodicPaymentFieldWithShareCount('person.expenses.rentLessHousing', {}, '!model.person.income.hasUniversalCredit'),
                      fullWidthPeriodicPaymentFieldWithShareCount('person.expenses.rent', {}, 'model.person.income.hasUniversalCredit'),

                      paymentField('person.expenses.councilTax'),

                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/councilTaxDescription.html'
                      },

                      'person.expenses.hasHomeOrContentsInsurance',
                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.hasHomeOrContentsInsurance',
                        items: [
                          'person.expenses.isHomeInsuranceCombined',

                          fullWidthPeriodicPaymentFieldWithShareCount('person.expenses.combinedHomeInsurance', {}, 'model.person.expenses.isHomeInsuranceCombined'),

                          fullWidthPeriodicPaymentFieldWithShareCount('person.expenses.homeInsurance', {}, 'model.person.expenses.isHomeInsuranceCombined === false'),

                          fullWidthPeriodicPaymentFieldWithShareCount('person.expenses.contentsInsurance', {}, 'model.person.expenses.isHomeInsuranceCombined === false'),
                          ]
                      },

                      fullWidthPeriodicPaymentFieldWithShareCount('person.expenses.groundRent'),

                      fullWidthPeriodicPaymentField('person.expenses.serviceCharge'),

                      'person.expenses.hasAdditionalExpenses',

                      {
                        condition: 'model.person.expenses.hasAdditionalExpenses',
                        key: 'person.expenses.additionalExpenses'
                      }

                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 9',
            page: 9,
            title: 'Disability-Related Expenses',
            items: [
              {
                type: 'fieldset',
                items: [

                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/dre.html"
                  },


                  {
                    type: 'fieldset',
                    title: "Specialist Equipment Hire or Purchase",
                    items: [
                      // hire / purchases
                      'person.expenses.disabilityRelated.hasSpecialistEquipment',
                      {
                        type: 'fieldset',


                        condition: 'model.person.expenses.disabilityRelated.hasSpecialistEquipment',
                        items: [

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.bedTurning', {
                            description: 'Enter the cost of any privately purchased / hired items that are for your sole use only.'
                          }),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.poweredRecliningChair', {
                            description: 'Enter the cost of any privately purchased / hired items that are for your sole use only.'
                          }),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.poweredWheelchairPurchase', {
                            description: 'Enter the cost of any privately purchased / hired items that are for your sole use only.'
                          }),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.manualWheelchairPurchase', {
                            description: 'Enter the cost of any privately purchased / hired items that are for your sole use only.'
                          }),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.mobilityScooter', {
                            description: 'Enter the cost of any privately purchased / hired items that are for your sole use only.'
                          }),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.stairLift', {
                            description: 'Enter the cost of any privately purchased / hired items that are for your sole use only.'
                          }),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.hoistPurchase', {
                            description: 'Enter the cost of any privately purchased / hired items that are for your sole use only.'
                          }),

                          'person.expenses.disabilityRelated.hasOtherHirePurchaseSpecialEquipment',
                          {
                            key: 'person.expenses.disabilityRelated.otherHirePurchaseSpecialEquipment',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherHirePurchaseSpecialEquipment'
                          },
                        ]
                      },
                    ]
                  },

                  // maintenance
                  {
                    type: 'fieldset',
                    title: "Specialist Equipment Maintenance",
                    items: [
                      'person.expenses.disabilityRelated.hasSpecialistEquipmentMaintenance',

                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasSpecialistEquipmentMaintenance',
                        items: [

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.bedTurningMaintenance'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.poweredRecliningChairMaintenance'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.poweredWheelchairMaintenance'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.manualWheelchairMaintenance'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.mobilityScooterMaintenance'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.stairLiftMaintenance'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.hoistMaintenance'),


                          'person.expenses.disabilityRelated.hasOtherSpecialEquipmentMaintenance',
                          {
                            key: 'person.expenses.disabilityRelated.otherSpecialEquipmentMaintenance',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherSpecialEquipmentMaintenance'
                          },

                        ]
                      },
                    ]
                  },

                  // care alarm
                  {
                    type: 'fieldset',
                    title: "Care Alarm",
                    items: [
                      'person.expenses.disabilityRelated.hasCareAlarm',

                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasCareAlarm',
                        items: [

                          'person.expenses.disabilityRelated.hasTelecare',
                          'person.expenses.disabilityRelated.telecareIncludesVAT',

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.alarm', {
                            description: 'Enter the cost you pay unless it is included in your Housing Benefit payment or Universal Credit Housing Allowance. You will be asked to provide information and show evidence.'
                          }),

                          'person.expenses.disabilityRelated.hasOtherCareAlarm',

                          {
                            type: 'fieldset',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherCareAlarm',
                            items: [
                              fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.otherAlarmAmount'),
                              'person.expenses.disabilityRelated.otherAlarmDescription'
                            ]
                          }
                        ]
                      },
                    ]
                  },

                  // healthcare
                  {
                    type: 'fieldset',
                    title: "Personal Healthcare",
                    items: [
                      'person.expenses.disabilityRelated.hasPersonalHealthcare',

                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasPersonalHealthcare',
                        items: [
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.medication'),
                          descriptionFor('person.expenses.disabilityRelated.medication'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.privateTherapy'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.chiropodist'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.incontinenceAids'),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.prescription'),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.creamsAndWipes'),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.dietary'),


                          'person.expenses.disabilityRelated.hasOtherPersonalHealthcare',
                          {
                            key: 'person.expenses.disabilityRelated.otherPersonalHealthcare',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherPersonalHealthcare'
                          },
                        ]
                      },
                    ]
                  },


                  // domestic help
                  {
                    type: 'fieldset',
                    title: "Help around the home",
                    items: [
                      'person.expenses.disabilityRelated.hasDomesticHelp',
                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasDomesticHelp',
                        items: [

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.domesticHelp', {
                            description: 'Enter the cost of professional help for cleaning, cooking or shopping services, where evidence exists of you receiving these service and no one else in the household can perform these tasks for you. Payments to close relatives and partners are not allowed.'
                          }),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.gardening', {
                            description: 'Enter the annual cost of basic maintenance if you live alone and you are unable to maintain your garden yourself.'
                          }),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.windowCleaner'),

                          'person.expenses.disabilityRelated.hasOtherDomesticHelp',
                          {
                            key: 'person.expenses.disabilityRelated.otherDomesticHelp',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherDomesticHelp'
                          },
                        ]
                      },
                    ]
                  },



                  // communication
                  {
                    type: 'fieldset',
                    title: "Communication Aids",
                    items: [
                      'person.expenses.disabilityRelated.hasCommunicationAids',
                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasCommunicationAids',
                        items: [

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.phone'),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.internetAccess'),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.communicationEquipment', {
                            description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                          }),


                          'person.expenses.disabilityRelated.hasOtherCommunicationAids',
                          {
                            key: 'person.expenses.disabilityRelated.otherCommunicationAids',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherCommunicationAids'
                          },
                        ]
                      },
                    ]
                  },

                  // fixtures and fittings
                  {
                    type: 'fieldset',
                    title: "Property fixtures and fittings",
                    items: [
                      'person.expenses.disabilityRelated.hasFixturesFittings',
                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasFixturesFittings',
                        items: [

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.propertyAdaptions'),

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.damage'),

                          'person.expenses.disabilityRelated.hasOtherFixturesFittings',
                          {
                            key: 'person.expenses.disabilityRelated.otherFixturesFittings',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherFixturesFittings'
                          },
                        ]
                      },
                    ]
                  },

                  // transport
                  {
                    type: 'fieldset',
                    title: "Transport costs",
                    items: [
                      'person.expenses.disabilityRelated.hasTransportCosts',
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/transportCostsGuidance.html'
                      },
                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasTransportCosts',
                        items: [

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.transport'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.vehicleAdaptions'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.taxiFares'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.transportMoneyToOther'),

                          'person.expenses.disabilityRelated.hasOtherTransportCosts',
                          {
                            key: 'person.expenses.disabilityRelated.otherTransportCosts',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherTransportCosts'
                          },
                        ]
                      },
                    ]
                  },

                  // heating
                  {
                    type: 'fieldset',
                    title: "Energy usage",
                    items: [
                      'person.expenses.disabilityRelated.hasEnergyCosts',
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/energyUsageGuidance.html'
                      },
                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasEnergyCosts',
                        items: [

                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.electric'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.gas'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.combinedElectricAndGas'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.oil'),
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.coalOrWood'),

                          'person.expenses.disabilityRelated.hasOtherEnergyCosts',
                          {
                            key: 'person.expenses.disabilityRelated.otherEnergyCosts',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherEnergyCosts'
                          },
                        ]
                      },
                    ]
                  },

                  // laundry
                  {
                    type: 'fieldset',
                    title: "Laundry",
                    items: [
                      'person.expenses.disabilityRelated.hasLaundryCosts',
                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.hasLaundryCosts',
                        items: [

                          paymentField('person.expenses.disabilityRelated.washingPerWeek'),

                          'person.expenses.disabilityRelated.hasOtherLaundryCosts',
                          {
                            key: 'person.expenses.disabilityRelated.otherLaundryCosts',
                            condition: 'model.person.expenses.disabilityRelated.hasOtherLaundryCosts'
                          },
                        ]
                      },
                    ]
                  },


                  // other
                  {
                    type: 'fieldset',
                    title: "Other Disability-Related Expenses",
                    items: [

                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.waterRates', {
                        description: 'Enter the cost of metered water only.'
                      }),

                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.privateCare'),
                      descriptionFor('person.expenses.disabilityRelated.privateCare'),

                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.additionalBedding', {
                        description: 'Enter the amount you spend on extra bedding due to wear and tear or incontinence, over and above anything provided to you by the NHS'
                      }),

                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.holidayCosts', {
                        description: 'Enter any above average amount you pay (e.g. for a carer if you are unable to go on holiday without assistance).'
                      }),
                      descriptionFor('person.expenses.disabilityRelated.holidayCosts'),

                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.specialClothes', {
                        description: 'Enter additional cost of clothing purchased due to weight loss/gain or excessive wear and tear caused by illness or disability. Include any additional cost of specialist footwear when compared to non-specialist.'
                      }),

                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.animal', {
                        description: 'Enter the amount you spend on any assistance dog or other animal.'
                      }),

                      'person.expenses.disabilityRelated.hasOtherDRE',
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/dreGuidance.html'
                      },
                      {
                        key: 'person.expenses.disabilityRelated.otherDRE',
                        condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                      },
                    ]
                  },

                  {
                    key: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                    htmlClass: 'invisible'
                  }

                ]
              },
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 10',
            page: 10,
            title: 'Other information',
            items: [
              'otherInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 11',
            page: 11,
            title: 'Declaration',
            items: [
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/declaration-intro.html"
              },
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/declaration-financial-assessment.html"
              },
              'declarations.financialAssessmentDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/declaration-changes.html"
              },
              'declarations.financialChangesDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/declaration-failure-to-pay.html"
              },
              'declarations.failureToPayDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/declaration-deprivation.html"
              },
              'declarations.deprivationDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/HE_FCA/templates/declaration-evidence.html"
              },
              'declarations.declaration'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 12',
            page: 12,
            title: 'Cost of care',
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/costOfCareIntro.html'
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Non-residential',
                    htmlClass: "col-md-6",
                    condition: conditions.toString('showNonResidentialAndRespite'),
                    items: [
                      {
                        key: 'careAssessment.nonResidential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'HE_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.nonResidential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum non-residential care contribution',
                        condition: conditions.toString('showCostOfCare'),
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: [
                          'careAssessment.nonResidential.workings.grossIncome',
                          'careAssessment.nonResidential.workings.capitalTariff',
                          'careAssessment.nonResidential.workings.disregardsTotal',
                          'careAssessment.nonResidential.workings.expensesTotal',
                          'careAssessment.nonResidential.workings.dreTotal',
                          'careAssessment.nonResidential.workings.livingAllowanceTotal',
                        ],
                        condition: conditions.toString('showBreakdown'),
                      },
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/costOfCarePayFullCost.html',
                        condition: conditions.toString('costOfCarePayFullCost'),
                      },
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/costOfCareArrangeOwn.html',
                        condition: conditions.toString('costOfCareArrangeOwn'),
                      }
                    ]
                  },
                ]
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Respite',
                    htmlClass: "col-md-6",
                    condition: conditions.toString('showNonResidentialAndRespite'),
                    items: [
                      {
                        key: 'careAssessment.respite.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'HE_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.respite,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Respite',
                        condition: conditions.toString('showCostOfCare'),
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: [
                          'careAssessment.respite.workings.grossIncome',
                          'careAssessment.respite.workings.capitalTariff',
                          'careAssessment.respite.workings.disregardsTotal',
                          'careAssessment.respite.workings.expensesTotal',
                          'careAssessment.respite.workings.dreTotal',
                          'careAssessment.respite.workings.livingAllowanceTotal',
                        ],
                        condition: conditions.toString('showBreakdown'),
                      },
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/costOfCarePayFullCost.html',
                        condition: conditions.toString('costOfCarePayFullCost'),
                      },
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/costOfCareArrangeOwn.html',
                        condition: conditions.toString('costOfCareArrangeOwn'),
                      }
                    ]
                  }

                ]
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Residential',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/cannotProvideCostOfCare.html',
                        condition: conditions.toString('cannotShowCostOfCare')
                      },
                      {
                        key: 'careAssessment.residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'HE_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution',
                        condition: conditions.toString('canShowCostOfCare')
                      },
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/residential12WeekDisclaimer.html',
                        condition: conditions.toString('show12WeekDisclaimer')
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: [
                          'careAssessment.residential.workings.grossIncome',
                          'careAssessment.residential.workings.capitalTariff',
                          'careAssessment.residential.workings.disregardsTotal',
                          'careAssessment.residential.workings.livingAllowanceTotal',
                        ],
                        condition: conditions.toString('canShowCostOfCare')
                      },
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/costOfCarePayFullCost.html',
                        condition: conditions.toString('costOfCarePayFullCost'),
                      },
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/HE_FCA/templates/costOfCareArrangeOwn.html',
                        condition: conditions.toString('costOfCareArrangeOwn'),
                      }
                    ]
                  }
                ]
              },

            ]
          },

          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);
