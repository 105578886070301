(function (angular) {

    'use strict';

    /* jshint eqeqeq:false, eqnull:true */

    angular.module('kerp-forms.forms').factory(
      'HB_CTR_FSM_CONDITIONS', ['FormConditions', 'dateUtils', function (FormConditions, dateUtils) {

        var service = FormConditions.clone({

          conditions: {
            applicant_othername_other: function () {
              return this.lib.visibleAndIcontains('applicant_othername', 'Yes');
            },
            applicant_mainhome_movedin: function () {
              return this.lib.visibleAndIcontains('applicant_mainhome', 'Main home now');
            },
            applicant_knownmovedate: function () {
              return this.lib.visibleAndIcontains('applicant_mainhome', 'Moving in within the next few weeks');
            },
            applicant_movedate: function () {
              return this.lib.visibleAndIcontains('applicant_knownmovedate', 'Yes');
            },
            template_applicant_address: ["applicant_knownmovedate"],
            applicant_current_uprn: ["applicant_knownmovedate"],
            applicant_current_address1: ["applicant_knownmovedate"],
            applicant_current_address2: ["applicant_knownmovedate"],
            applicant_current_address3: ["applicant_knownmovedate"],
            applicant_current_postcode: ["applicant_knownmovedate"],
            template_applicant_previous_address: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('applicant_mainhome_movedin'), 5);
            },
            applicant_previous_uprn: ["template_applicant_previous_address"],
            applicant_previous_address1: ["template_applicant_previous_address"],
            applicant_previous_address2: ["template_applicant_previous_address"],
            applicant_previous_address3: ["template_applicant_previous_address"],
            applicant_previous_postcode: ["template_applicant_previous_address"],
            applicant_previous_address_status: ["template_applicant_previous_address"],
            applicant_previous_address_homeowner: function () {
              return this.lib.visibleAndIcontains('applicant_previous_address_status', 'Homeowner');
            },
            applicant_previous_address_sold: ["applicant_previous_address_homeowner"],
            applicant_previous_address_soldvalue: function () {
              return this.lib.visibleAndIcontains('applicant_previous_address_sold', 'Yes');
            },
            applicant_previous_address_soldreceived: ["applicant_previous_address_soldvalue"],
            applicant_previous_address_forsale: function () {
              return this.lib.visibleAndIcontains('applicant_previous_address_sold', 'No');
            },
            applicant_previous_address_empty: ["applicant_previous_address_forsale"],
            applicant_residentsincedate: function () {
              return this.lib.visibleAndIcontains('applicant_arrived_in_last_five', 'Yes');
            },
            applicant_hostel_name: function () {
              return this.lib.visibleAndIcontains('applicant_hostel', 'Yes');
            },
            help_applicant_hostel_address: ["applicant_hostel_name"],
            applicant_hostel_addressline1: ["applicant_hostel_name"],
            applicant_hostel_addressline3: ["applicant_hostel_addressline2"],
            applicant_hostel_addressline2: ["applicant_hostel_name", function () {
              return this.lib.getModelVisible('applicant_hostel_addressline1');
            }],
            applicant_hostel_postcode: ["applicant_hostel_name"],
            applicant_previousclaim_name: function () {
              return this.lib.visibleAndIcontains('applicant_previousclaim', 'Yes');
            },
            applicant_previousclaim_council: ["applicant_previousclaim_name"],
            applicant_previousclaim_address: ["applicant_previousclaim_name"],
            applicant_fulltime_student: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_student', 'Student') !== -1;
            },
            applicant_remandcustody: function () {
              return this.lib.visibleAndIcontains('applicant_custody', 'Yes');
            },
            applicant_sentence: function () {
              return this.lib.visibleAndIcontains('applicant_remandcustody', 'Sentenced');
            },
            applicant_released: function () {
              return this.lib.visibleAndIcontains('applicant_sentence', 'Yes');
            },
            applicant_custodyweeks: function () {
              return this.lib.visibleAndIcontains('applicant_remandcustody', 'On remand');
            },
            applicant_hospitalin: function () {
              return this.lib.visibleAndIcontains('applicant_hospital', 'Yes');
            },
            applicant_hospitalout: ["applicant_hospitalin"],
            partner_title: function () {
              return this.lib.visibleAndIcontains('couple', 'Yes');
            },
            partner_forename: ["partner_title"],
            partner_surname: ["partner_title"],
            partner_othername: ["partner_title"],
            partner_othername_other: function () {
              return this.lib.visibleAndIcontains('partner_othername', 'Yes');
            },
            partner_gender: ["partner_title"],
            partner_dob: ["partner_title"],
            partner_nationality: ["partner_title"],
            partner_ni: ["partner_title"],
            partner_mainhome: ["partner_title"],
            partner_mainhome_movedin: function () {
              return this.lib.visibleAndIcontains('partner_mainhome', 'Main home now');
            },
            partner_knownmovedate: function () {
              return this.lib.visibleAndIcontains('partner_mainhome', 'Moving in within the next few weeks');
            },
            partner_movedate: function () {
              return this.lib.visibleAndIcontains('partner_knownmovedate', 'Yes');
            },
            partner_contact: ["partner_title"],
            partner_email: ["partner_title"],
            template_partner_mainhome: ["partner_knownmovedate"],
            partner_current_uprn: ["partner_knownmovedate"],
            partner_current_address1: ["partner_knownmovedate"],
            partner_current_address2: ["partner_knownmovedate"],
            partner_current_address3: ["partner_knownmovedate"],
            partner_current_postcode: ["partner_knownmovedate"],
            template_partner_previous_address: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('partner_mainhome_movedin'), 5);
            },
            partner_previous_uprn: ["template_partner_previous_address"],
            partner_previous_address1: ["template_partner_previous_address"],
            partner_previous_address2: ["template_partner_previous_address"],
            partner_previous_address3: ["template_partner_previous_address"],
            partner_previous_postcode: ["template_partner_previous_address"],
            partner_previous_address_status: ["template_partner_previous_address"],
            partner_previous_address_homeowner: function () {
              return this.lib.visibleAndIcontains('partner_previous_address_status', 'Homeowner');
            },
            partner_previous_address_sold: ["partner_previous_address_homeowner"],
            partner_previous_address_soldvalue: function () {
              return this.lib.visibleAndIcontains('partner_previous_address_sold', 'Yes');
            },
            partner_previous_address_soldreceived: ["partner_previous_address_soldvalue"],
            partner_previous_address_forsale: function () {
              return this.lib.visibleAndIcontains('partner_previous_address_sold', 'No');
            },
            partner_previous_address_empty: ["partner_previous_address_forsale"],
            partner_arrived_in_last_five: ["partner_title"],
            partner_residentsincedate: function () {
              return this.lib.visibleAndIcontains('partner_arrived_in_last_five', 'Yes');
            },
            partner_mappa: ["partner_title"],
            partner_hostel: ["partner_title"],
            partner_hostel_name: function () {
              return this.lib.visibleAndIcontains('partner_hostel', 'Yes');
            },
            partner_hostel_addressline1: ["partner_hostel_name"],
            partner_hostel_addressline2: ["partner_hostel_name", function () {
              return this.lib.getModelVisible('partner_hostel_addressline1');
            }],
            partner_hostel_addressline3: ["partner_hostel_addressline2"],
            partner_hostel_postcode: ["partner_hostel_name"],
            partner_previousclaim: ["partner_title"],
            partner_previousclaim_name: function () {
              return this.lib.visibleAndIcontains('partner_previousclaim', 'Yes');
            },
            partner_previousclaim_council: ["partner_previousclaim_name"],
            partner_previousclaim_address: ["partner_previousclaim_name"],
            partner_student: ["partner_title"],
            partner_fulltime_student: function () {
              return this.lib.visibleAndArrayContainsElement('partner_student', 'Student') > -1;
            },
            partner_hospital: ["partner_title"],
            partner_hospitalin: function () {
              return this.lib.visibleAndIcontains('partner_hospital', 'Yes');
            },
            partner_hospitalout: ["partner_hospitalin"],
            applicant_uc: function () {
              return this.lib.visibleAndIcontains('couple', 'No');
            },
            couple_uc: ["partner_title"],
            applicant_pendingucdate: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_uc', 'Waiting to hear') !== -1;
            },
            couple_pendingucdate: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'We are waiting to hear about our claim');
            },
            applicant_ucvalue: function () {
              return this.lib.visibleAndIcontains('applicant_uc', 'Yes') || this.lib.visibleAndIcontains('couple_uc', 'I get Universal Credit');
            },
            applicant_ucnonworkincome: ["applicant_ucvalue"],
            applicant_uctakehomepay: ["applicant_ucvalue"],
            applicant_uctakenoff: ["applicant_ucvalue"],
            partner_ucvalue: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'My partner gets Universal Credit');
            },
            partner_nonworkincome: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'My partner gets Universal Credit');
            },
            partner_takehomepay: ["partner_ucvalue"],
            partner_takenoff: ["partner_ucvalue"],
            couple_ucvalue: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'We both get Universal Credit');
            },
            couple_nonworkincome: ["couple_ucvalue"],
            couple_takehomepay: ["couple_ucvalue"],
            couple_takenoff: ["couple_ucvalue"],
            applicant_incomesupport: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'Neither of us get Universal Credit') || this.lib.visibleAndIcontains('applicant_uc', 'No');
            },
            applicant_incomesupport_date: function () {
              return this.lib.visibleAndIcontains('applicant_incomesupport', 'Yes');
            },
            applicant_incomesupport_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_incomesupport', 'I\'m waiting to hear');
            },
            partner_incomesupport: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'Neither of us get Universal Credit');
            },
            partner_incomesupport_date: function () {
              return this.lib.visibleAndIcontains('partner_incomesupport', 'Yes');
            },
            partner_incomesupport_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_incomesupport', 'They are waiting to hear');
            },
            applicant_jsa: function () {
              return this.lib.visibleAndIcontains('applicant_incomesupport', 'No');
            },
            applicant_jsa_date: function () {
              return this.lib.visibleAndIcontains('applicant_jsa', 'Yes');
            },
            applicant_jsa_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_jsa', 'I\'m waiting to hear');
            },
            partner_jsa: function () {
              return this.lib.visibleAndIcontains('partner_incomesupport', 'No');
            },
            partner_jsa_date: function () {
              return this.lib.visibleAndIcontains('partner_jsa', 'Yes');
            },
            partner_jsa_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_jsa', 'Waiting');
            },
            applicant_incomeesa: function () {
              return this.lib.visibleAndIcontains('applicant_jsa', 'No') || this.lib.visibleAndIcontains('applicant_jsa', 'I\'m waiting to hear');
            },
            applicant_incomeesa_date: function () {
              return this.lib.visibleAndIcontains('applicant_incomeesa', 'Yes');
            },
            applicant_incomeesa_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_incomeesa', 'I\'m waiting to hear');
            },
            applicant_incomeesarate: ["applicant_incomeesa_date"],
            partner_incomeesa: function () {
              return this.lib.visibleAndIcontains('partner_jsa', 'No') || this.lib.visibleAndIcontains('partner_jsa', 'Waiting');
            },
            partner_incomeesa_date: function () {
              return this.lib.visibleAndIcontains('partner_incomeesa', 'Yes');
            },
            partner_incomeesa_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_incomeesa', 'They are waiting to hear');
            },
            partner_incomeesarate: ["partner_incomeesa_date"],
            applicant_pensioncreditguarantee_date: function () {
              return this.lib.visibleAndIcontains('applicant_pensioncreditguarantee', 'Yes');
            },
            applicant_pensioncreditguarantee_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_pensioncreditguarantee', 'I\'m waiting to hear');
            },
            partner_pensioncreditguarantee: ["partner_title"],
            partner_pensioncreditguarantee_date: function () {
              return this.lib.visibleAndIcontains('partner_pensioncreditguarantee', 'Yes');
            },
            partner_pensioncreditguarantee_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_pensioncreditguarantee', 'They are waiting to hear');
            },
            applicant_pensioncreditsavings_date: function () {
              return this.lib.visibleAndIcontains('applicant_pensioncreditsavings', 'Yes');
            },
            applicant_pensioncreditsavings_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_pensioncreditsavings', 'I\'m waiting to hear');
            },
            partner_pensioncreditsavings: ["partner_title"],
            partner_pensioncreditsavings_date: function () {
              return this.lib.visibleAndIcontains('partner_pensioncreditsavings', 'Yes');
            },
            partner_pensioncreditsavings_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_pensioncreditsavings', 'They are waiting to hear');
            },
            applicant_dla: ["applicant_uc"],
            applicant_dlafrequency: function () {
              return this.lib.visibleAndIcontains('applicant_dla', 'Yes');
            },
            applicant_dlacare4weeks: function () {
              return this.lib.visibleAndIcontains('applicant_dlafrequency', 'Every 4 weeks');
            },
            applicant_dlacareweek: function () {
              return this.lib.visibleAndIcontains('applicant_dlafrequency', 'Every week');
            },
            applicant_dlamot4weeks: ["applicant_dlacare4weeks"],
            applicant_dlamotweek: ["applicant_dlacareweek"],
            couple_dla: ["partner_title"],
            applicant_dlafrequency1: function () {
              return this.lib.visibleAndIcontains('couple_dla', 'I get DLA') || this.lib.visibleAndIcontains('couple_dla', 'We both get DLA');
            },
            applicant_dlacare4weeks1: function () {
              return this.lib.visibleAndIcontains('applicant_dlafrequency1', 'Every 4 weeks');
            },
            applicant_dlacareweek1: function () {
              return this.lib.visibleAndIcontains('applicant_dlafrequency1', 'Every week');
            },
            applicant_dlamot4weeks1: ["applicant_dlacare4weeks1"],
            applicant_dlamotweek1: ["applicant_dlacareweek1"],
            partner_dlafrequency: function () {
              return this.lib.visibleAndIcontains('couple_dla', 'My partner gets DLA') || this.lib.visibleAndIcontains('couple_dla', 'We both get DLA');
            },
            partner_dlacare4weeks: function () {
              return this.lib.visibleAndIcontains('partner_dlafrequency', 'Every 4 weeks');
            },
            partner_dlacareweek: function () {
              return this.lib.visibleAndIcontains('partner_dlafrequency', 'Every week');
            },
            partner_dlamot4weeks: ["partner_dlacare4weeks"],
            partner_dlamotweek: ["partner_dlacareweek"],
            applicant_pip: ["applicant_uc"],
            couple_pip: ["partner_title"],
            applicant_pipdaily: function () {
              return this.lib.visibleAndIcontains('applicant_pip', 'Yes') || this.lib.visibleAndIcontains('couple_pip', 'I get PIP') || this.lib.visibleAndIcontains('couple_pip', 'We both get PIP');
            },
            applicant_pipmot: ["applicant_pipdaily"],
            partner_pipdaily: function () {
              return this.lib.visibleAndIcontains('couple_pip', 'My partner gets PIP') || this.lib.visibleAndIcontains('couple_pip', 'We both get PIP');
            },
            partner_pipmot: ["partner_pipdaily"],
            applicant_aa: ["applicant_uc"],
            couple_aa: ["partner_title"],
            applicant_calookingafter: ["applicant_uc"],
            applicant_carername: function () {
              return this.lib.visibleAndIcontains('applicant_calookingafter', 'Yes');
            },
            applicant_careraddress1: ["applicant_carername"],
            applicant_careraddress2: ["applicant_carername"],
            applicant_careraddress3: ["applicant_carername"],
            applicant_carerpostcode: ["applicant_carername"],
            applicant_ca_entitlement: ["applicant_uc"],
            applicant_ca8weeks: ["applicant_uc"],
            applicant_ca: function () {
              return this.lib.visibleAndIcontains('applicant_ca8weeks', 'Yes');
            },

            applicant_cafrequency: function () {
              return this.lib.visibleAndIcontains('applicant_ca', 'Yes');
            },
            applicant_castop: function () {
              return this.lib.visibleAndIcontains('applicant_ca', 'No');
            },
            applicant_carate: ["applicant_cafrequency"],
            couple_calookingafter: ["partner_title"],
            carer_name: function () {
              return this.lib.visibleAndIcontains('couple_calookingafter', 'Yes');
            },
            carer_address1: ["carer_name"],
            carer_address2: ["carer_name"],
            carer_address3: ["carer_name"],
            carer_postcode: ["carer_name"],
            couple_ca_entitlement: ["partner_title"],
            couple_ca8weeks: ["partner_title"],
            applicant_ca1: function () {
              return this.lib.visibleAndIcontains('couple_ca8weeks', 'I have') || this.lib.visibleAndIcontains('couple_ca8weeks', 'Both of us have');
            },

            applicant_cafrequency1: function () {
              return this.lib.visibleAndIcontains('applicant_ca1', 'Yes');
            },
            applicant_castop1: function () {
              return this.lib.visibleAndIcontains('applicant_ca1', 'No');
            },
            applicant_carate1: ["applicant_cafrequency1"],
            partner_ca: function () {
              return this.lib.visibleAndIcontains('couple_ca8weeks', 'My partner has') || this.lib.visibleAndIcontains('couple_ca8weeks', 'Both of us have');
            },
            partner_castop: function () {
              return this.lib.visibleAndIcontains('partner_ca', 'No');
            },
            partner_cafrequency: function () {
              return this.lib.visibleAndIcontains('partner_ca', 'Yes');
            },
            partner_carate: ["partner_cafrequency"],
            partner_disabled: ["partner_title"],
            applicant_livingaway: function () {
              return this.lib.visibleAndIcontains('couple', 'No');
            },
            couple_livingaway: function () {
              return this.lib.visibleAndIcontains('couple', 'Yes');
            },
            applicant_livingaway_reason: function () {
              return this.lib.visibleAndIcontains('applicant_livingaway', 'Yes') || this.lib.visibleAndIcontains('couple_livingaway', 'I am') || this.lib.visibleAndIcontains('couple_livingaway', 'We both are');
            },
            applicant_livingaway_lastlive: ["applicant_livingaway_reason"],
            applicant_livingaway_intendtoreturn: ["applicant_livingaway_reason"],
            applicant_livingaway_returndate: function () {
              return this.lib.visibleAndIcontains('applicant_livingaway_intendtoreturn', 'Yes');
            },
            template_applicant_livingaway_address: ["applicant_livingaway_reason"],
            applicant_livingaway_uprn: ["applicant_livingaway_reason"],
            applicant_livingaway_addressline1: ["applicant_livingaway_reason"],
            applicant_livingaway_addressline2: ["applicant_livingaway_reason"],
            applicant_livingaway_addressline3: ["applicant_livingaway_reason"],
            applicant_livingaway_postcode: ["applicant_livingaway_reason"],
            applicant_livingaway_sublet: ["applicant_livingaway_reason"],
            applicant_livingaway_lives: function () {
              return this.lib.visibleAndIcontains('applicant_livingaway_sublet', 'Yes');
            },
            partner_livingaway_reason: function () {
              return this.lib.visibleAndIcontains('couple_livingaway', 'My partner is') || this.lib.visibleAndIcontains('couple_livingaway', 'We both are');
            },
            partner_livingaway_lastlive: ["partner_livingaway_reason"],
            partner_livingaway_intendtoreturn: ["partner_livingaway_reason"],
            partner_livingaway_returndate: function () {
              return this.lib.visibleAndIcontains('partner_livingaway_intendtoreturn', 'Yes');
            },
            template_partner_livingaway_address: ["partner_livingaway_reason"],
            partner_livingaway_uprn: ["partner_livingaway_reason"],
            partner_livingaway_addressline1: ["partner_livingaway_reason"],
            partner_livingaway_addressline2: ["partner_livingaway_reason"],
            partner_livingaway_addressline3: ["partner_livingaway_reason"],
            partner_livingaway_postcode: ["partner_livingaway_reason"],
            partner_livingaway_sublet: ["partner_livingaway_reason"],
            partner_livingaway_lives: function () {
              return this.lib.visibleAndIcontains('partner_livingaway_sublet', 'Yes');
            },
            household_diedname: function () {
              return this.lib.visibleAndIcontains('household_died', 'Yes');
            },
            household_dieddate: ["household_diedname"],
            applicant_household_diedrelationship: function () {
              return this.lib.visibleAndIcontains('household_died', 'Yes') && this.lib.visibleAndIcontains('couple', 'No');
            },
            partner_household_diedrelationship: function () {
              return this.lib.visibleAndIcontains('household_died', 'Yes') && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            partner_pregnant: ["couple_livingaway"],
            applicant_duedate: function () {
              return this.lib.visibleAndIcontains('applicant_pregnant', 'Yes');
            },
            partner_duedate: function () {
              return this.lib.visibleAndIcontains('partner_pregnant', 'Yes');
            },
            couple_dependantchildren: ["couple_livingaway"],
            applicant_dependantchildren: ["applicant_livingaway"],
            dependantchildren_no: function () {
              return this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes') || this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes');
            },
            fieldset_dependant_child1: function () {
              return this.lib.visibleAndGtOrEq('dependantchildren_no', '1');
            },
            help_dependant_child1: function () {
              return this.lib.visibleAndGtOrEq('dependantchildren_no', '1');
            },
            dependantchild1_forename: ["dependantchildren_no", "fieldset_dependant_child1"],
            dependantchild1_surname: ["dependantchildren_no", "fieldset_dependant_child1"],
            dependantchild1_dob: ["dependantchildren_no", "fieldset_dependant_child1"],
            dependantchild1_gender: ["dependantchildren_no", "fieldset_dependant_child1"],
            dependantchild1_relationship_applicant: [function () {
              return this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes') || this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_relationship_partner: [function () {
              return this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_cb_applicant: [function () {
              return this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_cb_partner: [function () {
              return this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_applicantaddress: [function () {
              return this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_coupleaddress: ["dependantchild1_relationship_partner", 'fieldset_dependant_child1'],
            dependantchild1_addressline1: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_applicantaddress', 'Yes') || this.lib.visibleAndIcontains('dependantchild1_coupleaddress', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_addressline2: ["dependantchild1_addressline1", 'fieldset_dependant_child1'],
            dependantchild1_addressline3: ["dependantchild1_addressline1", 'fieldset_dependant_child1'],
            dependantchild1_postcode: ["dependantchild1_addressline1", 'fieldset_dependant_child1'],
            dependantchild1_childcare_couple: ["dependantchild1_relationship_partner", 'fieldset_dependant_child1'],
            dependantchild1_childcare_applicant: ["dependantchild1_applicantaddress", 'fieldset_dependant_child1'],
            dependantchild1_childcare_number: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_childcare_couple', 'Yes') || this.lib.visibleAndIcontains('dependantchild1_childcare_applicant', 'Yes');
            }, 'fieldset_dependant_child1'],
            help_dependantchild1_child_care_1: ['fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_provider: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_addressline1: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_addressline2: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_addressline3: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_postcode: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_tel: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_ofsted: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_amount: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            dependantchild1_childcare1_frequency: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_1'],
            help_dependantchild1_child_care_2: ['fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_provider: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_addressline1: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_addressline2: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_addressline3: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_postcode: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_tel: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_ofsted: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_amount: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            dependantchild1_childcare2_frequency: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_2'],
            help_dependantchild1_child_care_3: ['fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_provider: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_addressline1: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_addressline2: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_addressline3: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_postcode: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_tel: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_ofsted: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_amount: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            dependantchild1_childcare3_frequency: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_3'],
            help_dependantchild1_child_care_4: ['fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_provider: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_addressline1: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_addressline2: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_addressline3: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_postcode: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_tel: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_ofsted: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_amount: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_childcare4_frequency: ["dependantchild1_childcare_number", 'fieldset_dependantchild1_child_care_4'],
            dependantchild1_blind: ["dependantchild1_childcare_number", 'fieldset_dependant_child1'],
            dependantchild1_dla_applicant: ["dependantchild1_applicantaddress", 'fieldset_dependant_child1'],
            dependantchild1_dla_couple: ["dependantchild1_relationship_partner", 'fieldset_dependant_child1'],
            dependantchild1_dlarate: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_dla_applicant', 'Yes') || this.lib.visibleAndIcontains('dependantchild1_dla_couple', 'I get DLA for this Child') || this.lib.visibleAndIcontains('dependantchild1_dla_couple', 'My partner gets DLA for this Child');
            }, 'fieldset_dependant_child1'],
            dependantchild1_dlacare4weeks: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_dlarate', 'Every 4 weeks');
            }, 'fieldset_dependant_child1'],
            dependantchild1_dlacareweek: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_dlarate', 'Every week');
            }, 'fieldset_dependant_child1'],
            dependantchild1_dlamot4weeks: ["dependantchild1_dlacare4weeks", 'fieldset_dependant_child1'],
            dependantchild1_dlamotweek: ["dependantchild1_dlacareweek", 'fieldset_dependant_child1'],
            dependantchild1_pipdaily: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_pip', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_pipmot: ["dependantchild1_pipdaily", 'fieldset_dependant_child1'],
            dependantchild1_bedroom: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_dla_applicant', 'Yes') || this.lib.visibleAndIcontains('dependantchild1_dla_couple', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_kirkleesschool: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_school', 'Yes');
            }, 'fieldset_dependant_child1'],
            dependantchild1_kirkleesschool_addressline1: ["dependantchild1_kirkleesschool", 'fieldset_dependant_child1'],
            dependantchild1_kirkleesschool_addressline2: ["dependantchild1_kirkleesschool", 'fieldset_dependant_child1'],
            dependantchild1_kirkleesschool_addressline3: ["dependantchild1_kirkleesschool", 'fieldset_dependant_child1'],
            dependantchild1_kirkleesschool_postcode: ["dependantchild1_kirkleesschool", 'fieldset_dependant_child1'],
            dependantchild1_otherschool_name: [function () {
              return this.lib.visibleAndIcontains('dependantchild1_school', 'No');
            }, 'fieldset_dependant_child1'],
            dependantchild1_otherschool_addressline1: ["dependantchild1_otherschool_name", 'fieldset_dependant_child1'],
            dependantchild1_otherschool_addressline2: ["dependantchild1_otherschool_name", 'fieldset_dependant_child1'],
            dependantchild1_otherschool_addressline3: ["dependantchild1_otherschool_name", 'fieldset_dependant_child1'],
            dependantchild1_otherschool_postcode: ["dependantchild1_otherschool_name", 'fieldset_dependant_child1'],
            help_dependantchild2: ['fieldset_dependantchild2'],
            dependantchild2_forename: function () {
              return this.lib.visibleAndGtOrEq('dependantchildren_no', 2);
            },
            dependantchild2_surname: ["dependantchild2_forename", 'fieldset_dependantchild2'],
            dependantchild2_dob: ["dependantchild2_forename", 'fieldset_dependantchild2'],
            dependantchild2_gender: ["dependantchild2_forename", 'fieldset_dependantchild2'],
            dependantchild2_relationship_applicant: ["dependantchild2_forename", "dependantchild1_relationship_applicant", 'fieldset_dependantchild2'],
            dependantchild2_relationship_partner: ["dependantchild2_forename", "dependantchild1_relationship_partner", 'fieldset_dependantchild2'],
            dependantchild2_cb_applicant: ["dependantchild2_forename", "dependantchild1_cb_applicant", 'fieldset_dependantchild2'],
            dependantchild2_cb_partner: ["dependantchild2_forename", "dependantchild1_cb_partner", 'fieldset_dependantchild2'],
            dependantchild2_applicantaddress: ["dependantchild2_forename", "dependantchild1_applicantaddress", 'fieldset_dependantchild2'],
            dependantchild2_coupleaddress: ["dependantchild2_forename", "dependantchild1_relationship_partner", 'fieldset_dependantchild2'],
            dependantchild2_addressline1: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_applicantaddress', 'Yes') || this.lib.visibleAndIcontains('dependantchild2_coupleaddress', 'Yes');
            }, 'fieldset_dependantchild2'],
            dependantchild2_addressline2: ["dependantchild2_addressline1", 'fieldset_dependantchild2'],
            dependantchild2_addressline3: ["dependantchild2_addressline1", 'fieldset_dependantchild2'],
            dependantchild2_postcode: ["dependantchild2_addressline1", 'fieldset_dependantchild2'],
            dependantchild2_childcare_couple: ["dependantchild2_relationship_partner", 'fieldset_dependantchild2'],
            dependantchild2_childcare_applicant: ["dependantchild2_applicantaddress", 'fieldset_dependantchild2'],
            dependantchild2_childcare_number: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_childcare_couple', 'Yes') || this.lib.visibleAndIcontains('dependantchild2_childcare_applicant', 'Yes');
            }, 'fieldset_dependantchild2'],
            help_dependantchild2_child_care_1: ['fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_provider: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_addressline1: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_addressline2: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_addressline3: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_postcode: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_tel: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_ofsted: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_amount: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            dependantchild2_childcare1_frequency: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_1'],
            help_dependantchild2_child_care_2: ['fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_provider: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_addressline1: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_addressline2: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_addressline3: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_postcode: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_tel: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_ofsted: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_amount: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            dependantchild2_childcare2_frequency: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_2'],
            help_dependantchild2_child_care_3: ['fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_provider: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_addressline1: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_addressline2: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_addressline3: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_postcode: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_tel: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_ofsted: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_amount: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            dependantchild2_childcare3_frequency: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_3'],
            help_dependantchild2_child_care_4: ['fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_provider: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_addressline1: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_addressline2: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_addressline3: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_postcode: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_tel: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_ofsted: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_amount: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_childcare4_frequency: ["dependantchild2_childcare_number", 'fieldset_dependantchild2_child_care_4'],
            dependantchild2_blind: ["dependantchild2_childcare_number", 'fieldset_dependantchild2'],
            dependantchild2_dla_applicant: ["dependantchild2_applicantaddress", 'fieldset_dependantchild2'],
            dependantchild2_dla_couple: ["dependantchild2_relationship_partner", 'fieldset_dependantchild2'],
            dependantchild2_dlarate: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_dla_applicant', 'Yes') || this.lib.visibleAndIcontains('dependantchild2_dla_couple', 'I get DLA for this Child') || this.lib.visibleAndIcontains('dependantchild2_dla_couple', 'My partner gets DLA for this Child');
            }, 'fieldset_dependantchild2'],
            dependantchild2_dlacare4weeks: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_dlarate', 'Every 4 weeks');
            }, 'fieldset_dependantchild2'],
            dependantchild2_dlacareweek: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_dlarate', 'Every week');
            }, 'fieldset_dependantchild2'],
            dependantchild2_dlamot4weeks: ["dependantchild2_dlacare4weeks", 'fieldset_dependantchild2'],
            dependantchild2_dlamotweek: ["dependantchild2_dlacareweek", 'fieldset_dependantchild2'],
            dependantchild2_pipdaily: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_pip', 'Yes');
            }, 'fieldset_dependantchild2'],
            dependantchild2_pipmot: ["dependantchild2_pipdaily", 'fieldset_dependantchild2'],
            dependantchild2_bedroom: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_dla_applicant', 'Yes') || this.lib.visibleAndIcontains('dependantchild2_dla_couple', 'Yes');
            }, 'fieldset_dependantchild2'],
            dependantchild2_kirkleesschool: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_school', 'Yes');
            }, 'fieldset_dependantchild2'],
            dependantchild2_kirkleesschool_addressline1: ["dependantchild2_kirkleesschool", 'fieldset_dependantchild2'],
            dependantchild2_kirkleesschool_addressline2: ["dependantchild2_kirkleesschool", 'fieldset_dependantchild2'],
            dependantchild2_kirkleesschool_addressline3: ["dependantchild2_kirkleesschool", 'fieldset_dependantchild2'],
            dependantchild2_kirkleesschool_postcode: ["dependantchild2_kirkleesschool", 'fieldset_dependantchild2'],
            dependantchild2_otherschool_name: [function () {
              return this.lib.visibleAndIcontains('dependantchild2_school', 'No');
            }, 'fieldset_dependantchild2'],
            dependantchild2_otherschool_addressline1: ["dependantchild2_otherschool_name", 'fieldset_dependantchild2'],
            dependantchild2_otherschool_addressline2: ["dependantchild2_otherschool_name", 'fieldset_dependantchild2'],
            dependantchild2_otherschool_addressline3: ["dependantchild2_otherschool_name", 'fieldset_dependantchild2'],
            dependantchild2_otherschool_postcode: ["dependantchild2_otherschool_name", 'fieldset_dependantchild2'],
            help_dependantchild3: ['fieldset_dependantchild3'],
            dependantchild3_forename: [function () {
              return this.lib.visibleAndGtOrEq('dependantchildren_no', 3);
            }, 'fieldset_dependantchild3'],
            dependantchild3_surname: ["dependantchild3_forename", 'fieldset_dependantchild3'],
            dependantchild3_dob: ["dependantchild3_forename", 'fieldset_dependantchild3'],
            dependantchild3_gender: ["dependantchild3_forename", 'fieldset_dependantchild3'],
            dependantchild3_relationship_applicant: ["dependantchild3_forename", "dependantchild1_relationship_applicant", 'fieldset_dependantchild3'],
            dependantchild3_relationship_partner: ["dependantchild3_forename", "dependantchild1_relationship_partner", 'fieldset_dependantchild3'],
            dependantchild3_cb_applicant: ["dependantchild3_forename", "dependantchild1_cb_applicant", 'fieldset_dependantchild3'],
            dependantchild3_cb_partner: ["dependantchild3_forename", "dependantchild1_cb_partner", 'fieldset_dependantchild3'],
            dependantchild3_applicantaddress: ["dependantchild3_forename", "dependantchild1_applicantaddress", 'fieldset_dependantchild3'],
            dependantchild3_coupleaddress: ["dependantchild3_forename", "dependantchild1_relationship_partner", 'fieldset_dependantchild3'],
            dependantchild3_addressline1: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_applicantaddress', 'Yes') || this.lib.visibleAndIcontains('dependantchild3_coupleaddress', 'Yes');
            }, 'fieldset_dependantchild3'],
            dependantchild3_addressline2: ["dependantchild3_addressline1", 'fieldset_dependantchild3'],
            dependantchild3_addressline3: ["dependantchild3_addressline1", 'fieldset_dependantchild3'],
            dependantchild3_postcode: ["dependantchild3_addressline1", 'fieldset_dependantchild3'],
            dependantchild3_childcare_couple: ["dependantchild3_relationship_partner", 'fieldset_dependantchild3'],
            dependantchild3_childcare_applicant: ["dependantchild3_applicantaddress", 'fieldset_dependantchild3'],
            dependantchild3_childcare_number: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_childcare_couple', 'Yes') || this.lib.visibleAndIcontains('dependantchild3_childcare_applicant', 'Yes');
            }, 'fieldset_dependantchild3'],
            help_dependantchild3_child_care_1: ['fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_provider: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_addressline1: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_addressline2: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_addressline3: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_postcode: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_tel: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_ofsted: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_amount: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            dependantchild3_childcare1_frequency: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_1'],
            help_dependantchild3_child_care_2: ['fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_provider: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_addressline1: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_addressline2: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_addressline3: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_postcode: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_tel: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_ofsted: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_amount: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            dependantchild3_childcare2_frequency: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_2'],
            help_dependantchild3_child_care_3: ['fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_provider: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_addressline1: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_addressline2: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_addressline3: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_postcode: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_tel: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_ofsted: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_amount: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            dependantchild3_childcare3_frequency: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_3'],
            help_dependantchild3_child_care_4: ['fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_provider: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_addressline1: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_addressline2: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_addressline3: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_postcode: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_tel: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_ofsted: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_amount: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_childcare4_frequency: ["dependantchild3_childcare_number", 'fieldset_dependantchild3_child_care_4'],
            dependantchild3_blind: ["dependantchild3_childcare_number", 'fieldset_dependantchild3'],
            dependantchild3_dla_applicant: ["dependantchild3_applicantaddress", 'fieldset_dependantchild3'],
            dependantchild3_dla_couple: ["dependantchild3_relationship_partner", 'fieldset_dependantchild3'],
            dependantchild3_dlarate: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_dla_applicant', 'Yes') || this.lib.visibleAndIcontains('dependantchild3_dla_couple', 'I get DLA for this Child') || this.lib.visibleAndIcontains('dependantchild3_dla_couple', 'My partner gets DLA for this Child');
            }, 'fieldset_dependantchild3'],
            dependantchild3_dlacare4weeks: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_dlarate', 'Every 4 weeks');
            }, 'fieldset_dependantchild3'],
            dependantchild3_dlacareweek: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_dlarate', 'Every week');
            }, 'fieldset_dependantchild3'],
            dependantchild3_dlamot4weeks: ["dependantchild3_dlacare4weeks", 'fieldset_dependantchild3'],
            dependantchild3_dlamotweek: ["dependantchild3_dlacareweek", 'fieldset_dependantchild3'],
            dependantchild3_pipdaily: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_pip', 'Yes');
            }, 'fieldset_dependantchild3'],
            dependantchild3_pipmot: ["dependantchild3_pipdaily", 'fieldset_dependantchild3'],
            dependantchild3_bedroom: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_dla_applicant', 'Yes') || this.lib.visibleAndIcontains('dependantchild3_dla_couple', 'Yes');
            }, 'fieldset_dependantchild3'],
            dependantchild3_kirkleesschool: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_school', 'Yes');
            }, 'fieldset_dependantchild3'],
            dependantchild3_kirkleesschool_addressline1: ["dependantchild3_kirkleesschool", 'fieldset_dependantchild3'],
            dependantchild3_kirkleesschool_addressline2: ["dependantchild3_kirkleesschool", 'fieldset_dependantchild3'],
            dependantchild3_kirkleesschool_addressline3: ["dependantchild3_kirkleesschool", 'fieldset_dependantchild3'],
            dependantchild3_kirkleesschool_postcode: ["dependantchild3_kirkleesschool", 'fieldset_dependantchild3'],
            dependantchild3_otherschool_name: [function () {
              return this.lib.visibleAndIcontains('dependantchild3_school', 'No');
            }, 'fieldset_dependantchild3'],
            dependantchild3_otherschool_addressline1: ["dependantchild3_otherschool_name", 'fieldset_dependantchild3'],
            dependantchild3_otherschool_addressline2: ["dependantchild3_otherschool_name", 'fieldset_dependantchild3'],
            dependantchild3_otherschool_addressline3: ["dependantchild3_otherschool_name", 'fieldset_dependantchild3'],
            dependantchild3_otherschool_postcode: ["dependantchild3_otherschool_name", 'fieldset_dependantchild3'],
            help_dependantchild4: ['fieldset_dependantchild4'],
            dependantchild4_forename: [function () {
              return this.lib.visibleAndGtOrEq('dependantchildren_no', 4);
            }, 'fieldset_dependantchild4'],
            dependantchild4_surname: ["dependantchild4_forename", 'fieldset_dependantchild4'],
            dependantchild4_dob: ["dependantchild4_forename", 'fieldset_dependantchild4'],
            dependantchild4_gender: ["dependantchild4_forename", 'fieldset_dependantchild4'],
            dependantchild4_relationship_applicant: ["dependantchild4_forename", "dependantchild1_relationship_applicant", 'fieldset_dependantchild4'],
            dependantchild4_relationship_partner: ["dependantchild4_forename", "dependantchild1_relationship_partner", 'fieldset_dependantchild4'],
            dependantchild4_cb_applicant: ["dependantchild4_forename", "dependantchild1_cb_applicant", 'fieldset_dependantchild4'],
            dependantchild4_cb_partner: ["dependantchild4_forename", "dependantchild1_cb_partner", 'fieldset_dependantchild4'],
            dependantchild4_applicantaddress: ["dependantchild4_forename", "dependantchild1_applicantaddress", 'fieldset_dependantchild4'],
            dependantchild4_coupleaddress: ["dependantchild4_forename", "dependantchild1_relationship_partner", 'fieldset_dependantchild4'],
            dependantchild4_addressline1: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_applicantaddress', 'Yes') || this.lib.visibleAndIcontains('dependantchild4_coupleaddress', 'Yes');
            }, 'fieldset_dependantchild4'],
            dependantchild4_addressline2: ["dependantchild4_addressline1", 'fieldset_dependantchild4'],
            dependantchild4_addressline3: ["dependantchild4_addressline1", 'fieldset_dependantchild4'],
            dependantchild4_postcode: ["dependantchild4_addressline1", 'fieldset_dependantchild4'],
            dependantchild4_childcare_couple: ["dependantchild4_relationship_partner", 'fieldset_dependantchild4'],
            dependantchild4_childcare_applicant: ["dependantchild4_applicantaddress", 'fieldset_dependantchild4'],
            dependantchild4_childcare_number: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_childcare_couple', 'Yes') || this.lib.visibleAndIcontains('dependantchild4_childcare_applicant', 'Yes');
            }, 'fieldset_dependantchild4'],
            help_dependantchild4_child_care_1: ['fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_provider: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_addressline1: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_addressline2: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_addressline3: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_postcode: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_tel: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_ofsted: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_amount: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            dependantchild4_childcare1_frequency: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_1'],
            help_dependantchild4_child_care_2: ['fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_provider: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_addressline1: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_addressline2: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_addressline3: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_postcode: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_tel: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_ofsted: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_amount: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            dependantchild4_childcare2_frequency: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_2'],
            help_dependantchild4_child_care_3: ['fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_provider: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_addressline1: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_addressline2: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_addressline3: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_postcode: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_tel: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_ofsted: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_amount: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            dependantchild4_childcare3_frequency: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_3'],
            help_dependantchild4_child_care_4: ['fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_provider: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_addressline1: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_addressline2: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_addressline3: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_postcode: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_tel: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_ofsted: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_amount: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_childcare4_frequency: ["dependantchild4_childcare_number", 'fieldset_dependantchild4_child_care_4'],
            dependantchild4_blind: ["dependantchild4_childcare_number", 'fieldset_dependantchild4'],
            dependantchild4_dla_applicant: ["dependantchild4_applicantaddress", 'fieldset_dependantchild4'],
            dependantchild4_dla_couple: ["dependantchild4_relationship_partner", 'fieldset_dependantchild4'],
            dependantchild4_dlarate: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_dla_applicant', 'Yes') || this.lib.visibleAndIcontains('dependantchild4_dla_couple', 'I get DLA for this Child') || this.lib.visibleAndIcontains('dependantchild4_dla_couple', 'My partner gets DLA for this Child');
            }, 'fieldset_dependantchild4'],
            dependantchild4_dlacare4weeks: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_dlarate', 'Every 4 weeks');
            }, 'fieldset_dependantchild4'],
            dependantchild4_dlacareweek: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_dlarate', 'Every week');
            }, 'fieldset_dependantchild4'],
            dependantchild4_dlamot4weeks: ["dependantchild4_dlacare4weeks", 'fieldset_dependantchild4'],
            dependantchild4_dlamotweek: ["dependantchild4_dlacareweek", 'fieldset_dependantchild4'],
            dependantchild4_pipdaily: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_pip', 'Yes');
            }, 'fieldset_dependantchild4'],
            dependantchild4_pipmot: ["dependantchild4_pipdaily", 'fieldset_dependantchild4'],
            dependantchild4_bedroom: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_dla_applicant', 'Yes') || this.lib.visibleAndIcontains('dependantchild4_dla_couple', 'Yes');
            }, 'fieldset_dependantchild4'],
            dependantchild4_kirkleesschool: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_school', 'Yes');
            }, 'fieldset_dependantchild4'],
            dependantchild4_kirkleesschool_addressline1: ["dependantchild4_kirkleesschool", 'fieldset_dependantchild4'],
            dependantchild4_kirkleesschool_addressline2: ["dependantchild4_kirkleesschool", 'fieldset_dependantchild4'],
            dependantchild4_kirkleesschool_addressline3: ["dependantchild4_kirkleesschool", 'fieldset_dependantchild4'],
            dependantchild4_kirkleesschool_postcode: ["dependantchild4_kirkleesschool", 'fieldset_dependantchild4'],
            dependantchild4_otherschool_name: [function () {
              return this.lib.visibleAndIcontains('dependantchild4_school', 'No');
            }, 'fieldset_dependantchild4'],
            dependantchild4_otherschool_addressline1: ["dependantchild4_otherschool_name", 'fieldset_dependantchild4'],
            dependantchild4_otherschool_addressline2: ["dependantchild4_otherschool_name", 'fieldset_dependantchild4'],
            dependantchild4_otherschool_addressline3: ["dependantchild4_otherschool_name", 'fieldset_dependantchild4'],
            dependantchild4_otherschool_postcode: ["dependantchild4_otherschool_name", 'fieldset_dependantchild4'],
            other_adultsno: ['has_other_adults'],
            jointtenants: ['has_other_adults'],
            boarders: ['has_other_adults'],
            nondependants: ['has_other_adults'],
            jointtenant1_forename: ['has_joint_tenant'],
            jointtenant1_surname: ['has_joint_tenant'],
            jointtenant1_dob: ["has_joint_tenant"],
            jointtenant1_gender: ["has_joint_tenant"],
            jointtenant1_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 1) && this.lib.visibleAndIcontains('couple', 'No');
            },
            jointtenant1_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 1) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            jointtenant1_relationship_otheradults: ["jointtenant1_surname"],
            jointtenant1_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('jointtenant1_relationship_otheradults', 'Yes');
            },
            jointtenant1_student: ["jointtenant1_surname"],
            jointtenant1_mental: ["jointtenant1_surname"],
            jointtenant1_blind: ["jointtenant1_surname"],

            jointtenant2_forename: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 2);
            },
            jointtenant2_surname: ["jointtenant2_forename"],
            jointtenant2_dob: ["jointtenant2_forename"],
            jointtenant2_gender: ["jointtenant2_forename"],
            jointtenant2_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 2) && this.lib.visibleAndIcontains('couple', 'No');
            },
            jointtenant2_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 2) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            jointtenant2_relationship_otheradults: ["jointtenant2_forename"],
            jointtenant2_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('jointtenant2_relationship_otheradults', 'Yes');
            },
            jointtenant2_student: ["jointtenant2_forename"],
            jointtenant2_mental: ["jointtenant2_forename"],
            jointtenant2_blind: ["jointtenant2_forename"],
            jointtenant3_forename: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 3);
            },
            jointtenant3_surname: ["jointtenant3_forename"],
            jointtenant3_dob: ["jointtenant3_forename"],
            jointtenant3_gender: ["jointtenant3_forename"],
            jointtenant3_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 3) && this.lib.visibleAndIcontains('couple', 'No');
            },
            jointtenant3_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 3) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            jointtenant3_relationship_otheradults: ["jointtenant3_forename"],
            jointtenant3_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('jointtenant3_relationship_otheradults', 'Yes');
            },
            jointtenant3_student: ["jointtenant3_forename"],
            jointtenant3_mental: ["jointtenant3_forename"],
            jointtenant3_blind: ["jointtenant3_forename"],
            jointtenant4_forename: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 4);
            },
            jointtenant4_surname: ["jointtenant4_forename"],
            jointtenant4_dob: ["jointtenant4_forename"],
            jointtenant4_gender: ["jointtenant4_forename"],
            jointtenant4_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 4) && this.lib.visibleAndIcontains('couple', 'No');
            },
            jointtenant4_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 4) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            jointtenant4_relationship_otheradults: ["jointtenant4_forename"],
            jointtenant4_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('jointtenant4_relationship_otheradults', 'Yes');
            },
            jointtenant4_student: ["jointtenant4_forename"],
            jointtenant4_mental: ["jointtenant4_forename"],
            jointtenant4_blind: ["jointtenant4_forename"],
            boarder1_forename: function () {
              return this.lib.visibleAndGtOrEq('boarders', 1);
            },
            boarder1_surname: ["boarder1_forename"],
            boarder1_dob: ["boarder1_forename"],
            boarder1_gender: ["boarder1_forename"],
            boarder1_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('boarders', 1) && this.lib.visibleAndIcontains('couple', 'No');
            },
            boarder1_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('boarders', 1) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            boarder1_relationship_otheradults: ["boarder1_forename"],
            boarder1_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('boarder1_relationship_otheradults', 'Yes');
            },
            boarder1_student: ["boarder1_forename"],
            boarder1_rentamount: ["boarder1_forename"],
            boarder1_rentheating: ["boarder1_forename"],
            boarder1_custody: ["boarder1_forename"],
            boarder1_custody_released: function () {
              return this.lib.visibleAndIcontains('boarder1_custody', 'Yes');
            },
            boarder1_hospital: ["boarder1_forename"],
            boarder1_hospital_in: function () {
              return this.lib.visibleAndIcontains('boarder1_hospital', 'Yes');
            },
            boarder1_hospital_knownout: ["boarder1_hospital_in"],
            boarder1_hospital_out: function () {
              return this.lib.visibleAndIcontains('boarder1_hospital_knownout', 'Yes');
            },
            boarder1_mental: ["boarder1_forename"],
            boarder2_forename: function () {
              return this.lib.visibleAndGtOrEq('boarders', 2);
            },
            boarder2_surname: ["boarder2_forename"],
            boarder2_dob: ["boarder2_forename"],
            boarder2_gender: ["boarder2_forename"],
            boarder2_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('boarders', 2) && this.lib.visibleAndIcontains('couple', 'No');
            },
            boarder2_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('boarders', 2) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            boarder2_relationship_otheradults: ["boarder2_forename"],
            boarder2_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('boarder2_relationship_otheradults', 'Yes');
            },
            boarder2_student: ["boarder2_forename"],
            boarder2_rentamount: ["boarder2_forename"],
            boarder2_rentheating: ["boarder2_forename"],
            boarder2_custody: ["boarder2_forename"],
            boarder2_custody_released: function () {
              return this.lib.visibleAndIcontains('boarder2_custody', 'Yes');
            },
            boarder2_hospital: ["boarder2_forename"],
            boarder2_hospital_in: function () {
              return this.lib.visibleAndIcontains('boarder2_hospital', 'Yes');
            },
            boarder2_hospital_knownout: ["boarder2_hospital_in"],
            boarder2_hospital_out: function () {
              return this.lib.visibleAndIcontains('boarder2_hospital_knownout', 'Yes');
            },
            boarder2_mental: ["boarder2_forename"],
            boarder3_forename: function () {
              return this.lib.visibleAndGtOrEq('boarders', 3);
            },
            boarder3_surname: ["boarder3_forename"],
            boarder3_dob: ["boarder3_forename"],
            boarder3_gender: ["boarder3_forename"],
            boarder3_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('boarders', 3) && this.lib.visibleAndIcontains('couple', 'No');
            },
            boarder3_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('boarders', 3) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            boarder3_relationship_otheradults: ["boarder3_forename"],
            boarder3_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('boarder3_relationship_otheradults', 'Yes');
            },
            boarder3_student: ["boarder3_forename"],
            boarder3_rentamount: ["boarder3_forename"],
            boarder3_rentheating: ["boarder3_forename"],
            boarder3_custody: ["boarder3_forename"],
            boarder3_custody_released: function () {
              return this.lib.visibleAndIcontains('boarder3_custody', 'Yes');
            },
            boarder3_hospital: ["boarder3_forename"],
            boarder3_hospital_in: function () {
              return this.lib.visibleAndIcontains('boarder3_hospital', 'Yes');
            },
            boarder3_hospital_knownout: ["boarder3_hospital_in"],
            boarder3_hospital_out: function () {
              return this.lib.visibleAndIcontains('boarder3_hospital_knownout', 'Yes');
            },
            boarder3_mental: ["boarder3_forename"],
            boarder4_forename: function () {
              return this.lib.visibleAndGtOrEq('boarders', 4);
            },
            boarder4_surname: ["boarder4_forename"],
            boarder4_dob: ["boarder4_forename"],
            boarder4_gender: ["boarder4_forename"],
            boarder4_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('boarders', 4) && this.lib.visibleAndIcontains('couple', 'No');
            },
            boarder4_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('boarders', 4) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            boarder4_relationship_otheradults: ["boarder4_forename"],
            boarder4_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('boarder4_relationship_otheradults', 'Yes');
            },
            boarder4_student: ["boarder4_forename"],
            boarder4_rentamount: ["boarder4_forename"],
            boarder4_rentheating: ["boarder4_forename"],
            boarder4_custody: ["boarder4_forename"],
            boarder4_custody_released: function () {
              return this.lib.visibleAndIcontains('boarder4_custody', 'Yes');
            },
            boarder4_hospital: ["boarder4_forename"],
            boarder4_hospital_in: function () {
              return this.lib.visibleAndIcontains('boarder4_hospital', 'Yes');
            },
            boarder4_hospital_knownout: ["boarder4_hospital_in"],
            boarder4_hospital_out: function () {
              return this.lib.visibleAndIcontains('boarder4_hospital_knownout', 'Yes');
            },
            boarder4_mental: ["boarder4_forename"],
            nondependant1_forename: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1);
            },
            nondependant1_surname: ["nondependant1_forename"],
            nondependant1_dob: ["nondependant1_forename"],
            nondependant1_gender: ["nondependant1_forename"],
            nondependant1_ni: ["nondependant1_forename"],
            nondependant1_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('couple', 'No');
            },
            nondependant1_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            nondependant1_relationship_otheradults: ["nondependant1_forename"],
            nondependant1_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('nondependant1_relationship_otheradults', 'Yes');
            },
            nondependant1_student: ["nondependant1_forename"],
            nondependant1_care: ["nondependant1_forename"],
            nondependant1_care35: function () {
              return this.lib.visibleAndIcontains('nondependant1_care', 'Yes');
            },
            nondependant1_care24: ["nondependant1_care35"],
            nondependant1_vol: ["nondependant1_care35"],
            nondependant1_pip: ["nondependant1_forename"],
            nondependant1_pipdaily: function () {
              return this.lib.visibleAndIcontains('nondependant1_pip', 'Yes');
            },
            nondependant1_pipmot: ["nondependant1_pipdaily"],
            nondependant1_mental: ["nondependant1_forename"],
            nondependant1_benefits: ["nondependant1_forename"],
            nondependant1_dlacare: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndArrayContainsElement('nondependant1_benefits', 'Disability Living Allowance') !== -1;
            },
            nondependant1_dlamot: ["nondependant1_dlacare"],
            nondependant1_esa: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndArrayContainsElement('nondependant1_benefits', 'Income-related Employment and Support Allowance') !== -1;
            },
            nondependant1_uc: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndArrayContainsElement('nondependant1_benefits', 'Universal Credit') !== -1;
            },
            nondependant1_work: ["nondependant1_forename"],
            nondependant1_work_hours: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('nondependant1_work', 'Yes');
            },
            nondependant1_work_pay: ["nondependant1_work_hours"],
            nondependant1_work_pay_frequency: ["nondependant1_work_hours"],
            nondependant1_contjsa: ["nondependant1_forename"],
            nondependant1_contjsa_amount: function () {
              return this.lib.visibleAndIcontains('nondependant1_contjsa', 'Yes');
            },
            nondependant1_contjsa_frequency: ["nondependant1_contjsa_amount"],
            nondependant1_contesa: ["nondependant1_forename"],
            nondependant1_contesarate: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('nondependant1_contesa', 'Yes');
            },
            nondependant1_contesafrequency: ["nondependant1_contesarate"],
            nondependant1_contesaamount: ["nondependant1_contesarate"],
            nondependant1_otherincome: ["nondependant1_forename"],
            nondependant1_otherincome_number: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('nondependant1_otherincome', 'Yes');
            },
            nondependant1_otherincome1_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant1_otherincome_number', 1);
            },
            nondependant1_otherincome1_amount: ["nondependant1_otherincome1_type"],
            nondependant1_otherincome1_frequency: ["nondependant1_otherincome1_type"],
            nondependant1_otherincome2_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant1_otherincome_number', 2);
            },
            nondependant1_otherincome2_amount: ["nondependant1_otherincome2_type"],
            nondependant1_otherincome2_frequency: ["nondependant1_otherincome2_type"],
            nondependant1_otherincome3_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant1_otherincome_number', 3);
            },
            nondependant1_otherincome3_amount: ["nondependant1_otherincome3_type"],
            nondependant1_otherincome3_frequency: ["nondependant1_otherincome3_type"],
            nondependant1_otherincome4_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant1_otherincome_number', 4);
            },
            nondependant1_otherincome4_amount: ["nondependant1_otherincome4_type"],
            nondependant1_otherincome4_frequency: ["nondependant1_otherincome4_type"],
            nondependant1_custody: ["nondependant1_forename"],
            nondependant1_custody_released: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('nondependant1_custody', 'Yes');
            },
            nondependant1_hospital: ["nondependant1_forename"],
            nondependant1_hospital_in: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('nondependant1_hospital', 'Yes');
            },
            nondependant1_hospital_knownout: ["nondependant1_hospital_in"],
            nondependant1_hospital_out: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('nondependant1_hospital_knownout', 'Yes');
            },
            nondependant2_forename: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2);
            },
            nondependant2_surname: ["nondependant2_forename"],
            nondependant2_dob: ["nondependant2_forename"],
            nondependant2_gender: ["nondependant2_forename"],
            nondependant2_ni: ["nondependant2_forename"],
            nondependant2_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('couple', 'No');
            },
            nondependant2_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            nondependant2_relationship_otheradults: ["nondependant2_forename"],
            nondependant2_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('nondependant2_relationship_otheradults', 'Yes');
            },
            nondependant2_student: ["nondependant2_forename"],
            nondependant2_care: ["nondependant2_forename"],
            nondependant2_care35: function () {
              return this.lib.visibleAndIcontains('nondependant2_care', 'Yes');
            },
            nondependant2_care24: ["nondependant2_care35"],
            nondependant2_vol: ["nondependant2_care35"],
            nondependant2_pip: ["nondependant2_forename"],
            nondependant2_pipdaily: function () {
              return this.lib.visibleAndIcontains('nondependant2_pip', 'Yes');
            },
            nondependant2_pipmot: ["nondependant2_pipdaily"],
            nondependant2_mental: ["nondependant2_forename"],
            nondependant2_benefits: ["nondependant2_forename"],
            nondependant2_dlacare: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndArrayContainsElement('nondependant2_benefits', 'Disability Living Allowance') !== -1;
            },
            nondependant2_dlamot: ["nondependant2_dlacare"],
            nondependant2_esa: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndArrayContainsElement('nondependant2_benefits', 'Income-related Employment and Support Allowance') !== -1;
            },
            nondependant2_uc: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndArrayContainsElement('nondependant2_benefits', 'Universal Credit') !== -1;
            },
            nondependant2_work: ["nondependant2_forename"],
            nondependant2_work_hours: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('nondependant2_work', 'Yes');
            },
            nondependant2_work_pay: ["nondependant2_work_hours"],
            nondependant2_work_pay_frequency: ["nondependant2_work_hours"],
            nondependant2_contjsa: ["nondependant2_forename"],
            nondependant2_contjsa_amount: function () {
              return this.lib.visibleAndIcontains('nondependant2_contjsa', 'Yes');
            },
            nondependant2_contjsa_frequency: ["nondependant2_contjsa_amount"],
            nondependant2_contesa: ["nondependant2_forename"],
            nondependant2_contesarate: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('nondependant2_contesa', 'Yes');
            },
            nondependant2_contesafrequency: ["nondependant2_contesarate"],
            nondependant2_contesaamount: ["nondependant2_contesarate"],
            nondependant2_otherincome: ["nondependant2_forename"],
            nondependant2_otherincome_number: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('nondependant2_otherincome', 'Yes');
            },
            nondependant2_otherincome1_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant2_otherincome_number', 1);
            },
            nondependant2_otherincome1_amount: ["nondependant2_otherincome1_type"],
            nondependant2_otherincome1_frequency: ["nondependant2_otherincome1_type"],
            nondependant2_otherincome2_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant2_otherincome_number', 2);
            },
            nondependant2_otherincome2_amount: ["nondependant2_otherincome2_type"],
            nondependant2_otherincome2_frequency: ["nondependant2_otherincome2_type"],
            nondependant2_otherincome3_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant2_otherincome_number', 3);
            },
            nondependant2_otherincome3_amount: ["nondependant2_otherincome3_type"],
            nondependant2_otherincome3_frequency: ["nondependant2_otherincome3_type"],
            nondependant2_otherincome4_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant2_otherincome_number', 4);
            },
            nondependant2_otherincome4_amount: ["nondependant2_otherincome4_type"],
            nondependant2_otherincome4_frequency: ["nondependant2_otherincome4_type"],
            nondependant2_custody: ["nondependant2_forename"],
            nondependant2_custody_released: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('nondependant2_custody', 'Yes');
            },
            nondependant2_hospital: ["nondependant2_forename"],
            nondependant2_hospital_in: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('nondependant2_hospital', 'Yes');
            },
            nondependant2_hospital_knownout: ["nondependant2_hospital_in"],
            nondependant2_hospital_out: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('nondependant2_hospital_knownout', 'Yes');
            },
            nondependant3_forename: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3);
            },
            nondependant3_surname: ["nondependant3_forename"],
            nondependant3_dob: ["nondependant3_forename"],
            nondependant3_gender: ["nondependant3_forename"],
            nondependant3_ni: ["nondependant3_forename"],
            nondependant3_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('couple', 'No');
            },
            nondependant3_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            nondependant3_relationship_otheradults: ["nondependant3_forename"],
            nondependant3_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('nondependant3_relationship_otheradults', 'Yes');
            },
            nondependant3_student: ["nondependant3_forename"],
            nondependant3_care: ["nondependant3_forename"],
            nondependant3_care35: function () {
              return this.lib.visibleAndIcontains('nondependant3_care', 'Yes');
            },
            nondependant3_care24: ["nondependant3_care35"],
            nondependant3_vol: ["nondependant3_care35"],
            nondependant3_pip: ["nondependant3_forename"],
            nondependant3_pipdaily: function () {
              return this.lib.visibleAndIcontains('nondependant3_pip', 'Yes');
            },
            nondependant3_pipmot: ["nondependant3_pipdaily"],
            nondependant3_mental: ["nondependant3_forename"],
            nondependant3_benefits: ["nondependant3_forename"],
            nondependant3_dlacare: function () {
              return (this.lib.visibleAndGtOrEq('nondependants', 3)) && (this.lib.visibleAndArrayContainsElement('nondependant3_benefits', 'Disability Living Allowance')) !== -1;
            },
            nondependant3_dlamot: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndArrayContainsElement('nondependant3_benefits', 'Disability Living Allowance') !== -1;
            },
            nondependant3_esa: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndArrayContainsElement('nondependant3_benefits', 'Income-related Employment and Support Allowance') !== -1;
            },
            nondependant3_uc: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndArrayContainsElement('nondependant3_benefits', 'Universal Credit') !== -1;
            },
            nondependant3_work: ["nondependant3_forename"],
            nondependant3_work_hours: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('nondependant3_work', 'Yes');
            },
            nondependant3_work_pay: ["nondependant3_work_hours"],
            nondependant3_work_pay_frequency: ["nondependant3_work_hours"],
            nondependant3_contjsa: ["nondependant3_forename"],
            nondependant3_contjsa_amount: function () {
              return this.lib.visibleAndIcontains('nondependant3_contjsa', 'Yes');
            },
            nondependant3_contjsa_frequency: ["nondependant3_contjsa_amount"],
            nondependant3_contesa: ["nondependant3_forename"],
            nondependant3_contesarate: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('nondependant3_contesa', 'Yes');
            },
            nondependant3_contesafrequency: ["nondependant3_contesarate"],
            nondependant3_contesaamount: ["nondependant3_contesarate"],
            nondependant3_otherincome: ["nondependant3_forename"],
            nondependant3_otherincome_number: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('nondependant3_otherincome', 'Yes');
            },
            nondependant3_otherincome1_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant3_otherincome_number', 1);
            },
            nondependant3_otherincome1_amount: ["nondependant3_otherincome1_type"],
            nondependant3_otherincome1_frequency: ["nondependant3_otherincome1_type"],
            nondependant3_otherincome2_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant3_otherincome_number', 2);
            },
            nondependant3_otherincome2_amount: ["nondependant3_otherincome2_type"],
            nondependant3_otherincome2_frequency: ["nondependant3_otherincome2_type"],
            nondependant3_otherincome3_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant3_otherincome_number', 3);
            },
            nondependant3_otherincome3_amount: ["nondependant3_otherincome3_type"],
            nondependant3_otherincome3_frequency: ["nondependant3_otherincome3_type"],
            nondependant3_otherincome4_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant3_otherincome_number', 4);
            },
            nondependant3_otherincome4_amount: ["nondependant3_otherincome4_type"],
            nondependant3_otherincome4_frequency: ["nondependant3_otherincome4_type"],
            nondependant3_custody: ["nondependant3_forename"],
            nondependant3_custody_released: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('nondependant3_custody', 'Yes');
            },
            nondependant3_hospital: ["nondependant3_forename"],
            nondependant3_hospital_in: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('nondependant3_hospital', 'Yes');
            },
            nondependant3_hospital_knownout: ["nondependant3_hospital_in"],
            nondependant3_hospital_out: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('nondependant3_hospital_knownout', 'Yes');
            },
            nondependant4_forename: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4);
            },
            nondependant4_surname: ["nondependant4_forename"],
            nondependant4_dob: ["nondependant4_forename"],
            nondependant4_gender: ["nondependant4_forename"],
            nondependant4_ni: ["nondependant4_forename"],
            nondependant4_relationship_applicant: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndIcontains('couple', 'No');
            },
            nondependant4_relationship_couple: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            nondependant4_relationship_otheradults: ["nondependant4_forename"],
            nondependant4_relationship_otheradults_name: function () {
              return this.lib.visibleAndIcontains('nondependant4_relationship_otheradults', 'Yes');
            },
            nondependant4_student: ["nondependant4_forename"],
            nondependant4_care: ["nondependant4_forename"],
            nondependant4_care35: function () {
              return this.lib.visibleAndIcontains('nondependant4_care', 'Yes');
            },
            nondependant4_care24: ["nondependant4_care35"],
            nondependant4_vol: ["nondependant4_care35"],
            nondependant4_pip: ["nondependant4_forename"],
            nondependant4_pipdaily: function () {
              return this.lib.visibleAndIcontains('nondependant4_pip', 'Yes');
            },
            nondependant4_pipmot: ["nondependant4_pipdaily"],
            nondependant4_mental: ["nondependant4_forename"],
            nondependant4_benefits: ["nondependant4_forename"],
            nondependant4_dlacare: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndArrayContainsElement('nondependant4_benefits', 'Disability Living Allowance') !== -1;
            },
            nondependant4_dlamot: ["nondependant4_dlacare"],
            nondependant4_esa: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndArrayContainsElement('nondependant4_benefits', 'Income-related Employment and Support Allowance') !== -1;
            },
            nondependant4_uc: function () {
              return (this.lib.visibleAndGtOrEq('nondependants', 4)) && (this.lib.visibleAndArrayContainsElement('nondependant4_benefits', 'Universal Credit')) !== -1;
            },
            nondependant4_work: ["nondependant4_forename"],
            nondependant4_work_hours: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndIcontains('nondependant4_work', 'Yes');
            },
            nondependant4_work_pay: ["nondependant4_work_hours"],
            nondependant4_work_pay_frequency: ["nondependant4_work_hours"],
            nondependant4_contjsa: ["nondependant4_forename"],
            nondependant4_contjsa_amount: function () {
              return this.lib.visibleAndIcontains('nondependant4_contjsa', 'Yes');
            },
            nondependant4_contjsa_frequency: ["nondependant4_contjsa_amount"],
            nondependant4_contesa: ["nondependant4_forename"],
            nondependant4_contesarate: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndIcontains('nondependant4_contesa', 'Yes');
            },
            nondependant4_contesafrequency: ["nondependant4_contesarate"],
            nondependant4_contesaamount: ["nondependant4_contesarate"],
            nondependant4_otherincome: ["nondependant4_forename"],
            nondependant4_otherincome_number: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('nondependant4_otherincome', 'Yes');
            },
            nondependant4_otherincome1_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant4_otherincome_number', 1);
            },
            nondependant4_otherincome1_amount: ["nondependant4_otherincome1_type"],
            nondependant4_otherincome1_frequency: ["nondependant4_otherincome1_type"],
            nondependant4_otherincome2_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant4_otherincome_number', 2);
            },
            nondependant4_otherincome2_amount: ["nondependant4_otherincome2_type"],
            nondependant4_otherincome2_frequency: ["nondependant4_otherincome2_type"],
            nondependant4_otherincome3_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant4_otherincome_number', 3);
            },
            nondependant4_otherincome3_amount: ["nondependant4_otherincome3_type"],
            nondependant4_otherincome3_frequency: ["nondependant4_otherincome3_type"],
            nondependant4_otherincome4_type: function () {
              return this.lib.visibleAndGtOrEq('nondependant4_otherincome_number', 4);
            },
            nondependant4_otherincome4_amount: ["nondependant4_otherincome4_type"],
            nondependant4_otherincome4_frequency: ["nondependant4_otherincome4_type"],
            nondependant4_custody: ["nondependant4_forename"],
            nondependant4_custody_released: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndIcontains('nondependant4_custody', 'Yes');
            },
            nondependant4_hospital: ["nondependant4_forename"],
            nondependant4_hospital_in: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndIcontains('nondependant4_hospital', 'Yes');
            },
            nondependant4_hospital_knownout: ["nondependant4_hospital_in"],
            nondependant4_hospital_out: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndIcontains('nondependant4_hospital_knownout', 'Yes');
            },
            nondependant1_children: ["nondependant1_forename"],
            nondependant1_childrenno: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 1) && this.lib.visibleAndIcontains('nondependant1_children', 'Yes');
            },
            nondependant1_child1_forename: ["nondependant1_childrenno"],
            nondependant1_child1_lastname: ["nondependant1_childrenno"],
            nondependant1_child1_cb: ["nondependant1_childrenno"],
            nondependant1_child1_dob: ["nondependant1_childrenno"],
            nondependant1_child1_gender: ["nondependant1_childrenno"],
            nondependant1_child1_bed: ["nondependant1_childrenno"],
            nondependant1_child2_forename: ["nondependant1_childrenno", 'fieldset_nondependant1child2'],
            nondependant1_child2_lastname: ["nondependant1_childrenno", 'fieldset_nondependant1child2'],
            nondependant1_child2_cb: ["nondependant1_childrenno", 'fieldset_nondependant1child2'],
            nondependant1_child2_dob: ["nondependant1_childrenno", 'fieldset_nondependant1child2'],
            nondependant1_child2_gender: ["nondependant1_childrenno", 'fieldset_nondependant1child2'],
            nondependant1_child2_bed: ["nondependant1_childrenno", 'fieldset_nondependant1child2'],
            nondependant1_child3_forename: ["nondependant1_childrenno", 'fieldset_nondependant1child3'],
            nondependant1_child3_lastname: ["nondependant1_childrenno", 'fieldset_nondependant1child3'],
            nondependant1_child3_cb: ["nondependant1_childrenno", 'fieldset_nondependant1child3'],
            nondependant1_child3_dob: ["nondependant1_childrenno", 'fieldset_nondependant1child3'],
            nondependant1_child3_gender: ["nondependant1_childrenno", 'fieldset_nondependant1child3'],
            nondependant1_child3_bed: ["nondependant1_childrenno", 'fieldset_nondependant1child3'],
            nondependant1_child4_forename: ["nondependant1_childrenno", 'fieldset_nondependant1child4'],
            nondependant1_child4_lastname: ["nondependant1_childrenno", 'fieldset_nondependant1child4'],
            nondependant1_child4_cb: ["nondependant1_childrenno", 'fieldset_nondependant1child4'],
            nondependant1_child4_dob: ["nondependant1_childrenno", 'fieldset_nondependant1child4'],
            nondependant1_child4_gender: ["nondependant1_childrenno", 'fieldset_nondependant1child4'],
            nondependant1_child4_bed: ["nondependant1_childrenno", 'fieldset_nondependant1child4'],
            nondependant2_children: ["nondependant2_forename"],
            nondependant2_childrenno: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 2) && this.lib.visibleAndIcontains('nondependant2_children', 'Yes');
            },
            nondependant2_child1_forename: ["nondependant2_childrenno", 'fieldset_nondependant2child1'],
            nondependant2_child1_lastname: ["nondependant2_childrenno", 'fieldset_nondependant2child1'],
            nondependant2_child1_cb: ["nondependant2_childrenno", 'fieldset_nondependant2child1'],
            nondependant2_child1_dob: ["nondependant2_childrenno", 'fieldset_nondependant2child1'],
            nondependant2_child1_gender: ["nondependant2_childrenno", 'fieldset_nondependant2child1'],
            nondependant2_child1_bed: ["nondependant2_childrenno", 'fieldset_nondependant2child1'],
            nondependant2_child2_forename: ["nondependant2_childrenno", 'fieldset_nondependant2child2'],
            nondependant2_child2_lastname: ["nondependant2_childrenno", 'fieldset_nondependant2child2'],
            nondependant2_child2_cb: ["nondependant2_childrenno", 'fieldset_nondependant2child2'],
            nondependant2_child2_dob: ["nondependant2_childrenno", 'fieldset_nondependant2child2'],
            nondependant2_child2_gender: ["nondependant2_childrenno", 'fieldset_nondependant2child2'],
            nondependant2_child2_bed: ["nondependant2_childrenno", 'fieldset_nondependant2child2'],
            nondependant2_child3_forename: ["nondependant2_childrenno", 'fieldset_nondependant2child3'],
            nondependant2_child3_lastname: ["nondependant2_childrenno", 'fieldset_nondependant2child3'],
            nondependant2_child3_cb: ["nondependant2_childrenno", 'fieldset_nondependant2child3'],
            nondependant2_child3_dob: ["nondependant2_childrenno", 'fieldset_nondependant2child3'],
            nondependant2_child3_gender: ["nondependant2_childrenno", 'fieldset_nondependant2child3'],
            nondependant2_child3_bed: ["nondependant2_childrenno", 'fieldset_nondependant2child3'],
            nondependant2_child4_forename: ["nondependant2_childrenno", 'fieldset_nondependant2child4'],
            nondependant2_child4_lastname: ["nondependant2_childrenno", 'fieldset_nondependant2child4'],
            nondependant2_child4_cb: ["nondependant2_childrenno", 'fieldset_nondependant2child4'],
            nondependant2_child4_dob: ["nondependant2_childrenno", 'fieldset_nondependant2child4'],
            nondependant2_child4_gender: ["nondependant2_childrenno", 'fieldset_nondependant2child4'],
            nondependant2_child4_bed: ["nondependant2_childrenno", 'fieldset_nondependant2child4'],
            nondependant3_children: ["nondependant3_forename"],
            nondependant3_childrenno: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 3) && this.lib.visibleAndIcontains('nondependant3_children', 'Yes');
            },
            nondependant3_child1_forename: ["nondependant3_childrenno", 'fieldset_nondependant3child1'],
            nondependant3_child1_lastname: ["nondependant3_childrenno", 'fieldset_nondependant3child1'],
            nondependant3_child1_cb: ["nondependant3_childrenno", 'fieldset_nondependant3child1'],
            nondependant3_child1_dob: ["nondependant3_childrenno", 'fieldset_nondependant3child1'],
            nondependant3_child1_gender: ["nondependant3_childrenno", 'fieldset_nondependant3child1'],
            nondependant3_child1_bed: ["nondependant3_childrenno", 'fieldset_nondependant3child1'],
            nondependant3_child2_forename: ["nondependant3_childrenno", 'fieldset_nondependant3child2'],
            nondependant3_child2_lastname: ["nondependant3_childrenno", 'fieldset_nondependant3child2'],
            nondependant3_child2_cb: ["nondependant3_childrenno", 'fieldset_nondependant3child2'],
            nondependant3_child2_dob: ["nondependant3_childrenno", 'fieldset_nondependant3child2'],
            nondependant3_child2_gender: ["nondependant3_childrenno", 'fieldset_nondependant3child2'],
            nondependant3_child2_bed: ["nondependant3_childrenno", 'fieldset_nondependant3child2'],
            nondependant3_child3_forename: ["nondependant3_childrenno", 'fieldset_nondependant3child3'],
            nondependant3_child3_lastname: ["nondependant3_childrenno", 'fieldset_nondependant3child3'],
            nondependant3_child3_cb: ["nondependant3_childrenno", 'fieldset_nondependant3child3'],
            nondependant3_child3_dob: ["nondependant3_childrenno", 'fieldset_nondependant3child3'],
            nondependant3_child3_gender: ["nondependant3_childrenno", 'fieldset_nondependant3child3'],
            nondependant3_child3_bed: ["nondependant3_childrenno", 'fieldset_nondependant3child3'],
            nondependant3_child4_forename: ["nondependant3_childrenno", 'fieldset_nondependant3child4'],
            nondependant3_child4_lastname: ["nondependant3_childrenno", 'fieldset_nondependant3child4'],
            nondependant3_child4_cb: ["nondependant3_childrenno", 'fieldset_nondependant3child4'],
            nondependant3_child4_dob: ["nondependant3_childrenno", 'fieldset_nondependant3child4'],
            nondependant3_child4_gender: ["nondependant3_childrenno", 'fieldset_nondependant3child4'],
            nondependant3_child4_bed: ["nondependant3_childrenno", 'fieldset_nondependant3child4'],
            nondependant4_children: ["nondependant4_forename"],
            nondependant4_childrenno: function () {
              return this.lib.visibleAndGtOrEq('nondependants', 4) && this.lib.visibleAndIcontains('nondependant4_children', 'Yes');
            },
            nondependant4_child1_forename: ["nondependant4_childrenno", 'fieldset_nondependant4child1'],
            nondependant4_child1_lastname: ["nondependant4_childrenno", 'fieldset_nondependant4child1'],
            nondependant4_child1_cb: ["nondependant4_childrenno", 'fieldset_nondependant4child1'],
            nondependant4_child1_dob: ["nondependant4_childrenno", 'fieldset_nondependant4child1'],
            nondependant4_child1_gender: ["nondependant4_childrenno", 'fieldset_nondependant4child1'],
            nondependant4_child1_bed: ["nondependant4_childrenno", 'fieldset_nondependant4child1'],
            nondependant4_child2_forename: ["nondependant4_childrenno", 'fieldset_nondependant4child2'],
            nondependant4_child2_lastname: ["nondependant4_childrenno", 'fieldset_nondependant4child2'],
            nondependant4_child2_cb: ["nondependant4_childrenno", 'fieldset_nondependant4child2'],
            nondependant4_child2_dob: ["nondependant4_childrenno", 'fieldset_nondependant4child2'],
            nondependant4_child2_gender: ["nondependant4_childrenno", 'fieldset_nondependant4child2'],
            nondependant4_child2_bed: ["nondependant4_childrenno", 'fieldset_nondependant4child2'],
            nondependant4_child3_forename: ["nondependant4_childrenno", 'fieldset_nondependant4child3'],
            nondependant4_child3_lastname: ["nondependant4_childrenno", 'fieldset_nondependant4child3'],
            nondependant4_child3_cb: ["nondependant4_childrenno", 'fieldset_nondependant4child3'],
            nondependant4_child3_dob: ["nondependant4_childrenno", 'fieldset_nondependant4child3'],
            nondependant4_child3_gender: ["nondependant4_childrenno", 'fieldset_nondependant4child3'],
            nondependant4_child3_bed: ["nondependant4_childrenno", 'fieldset_nondependant4child3'],
            nondependant4_child4_forename: ["nondependant4_childrenno", 'fieldset_nondependant4child4'],
            nondependant4_child4_lastname: ["nondependant4_childrenno", 'fieldset_nondependant4child4'],
            nondependant4_child4_cb: ["nondependant4_childrenno", 'fieldset_nondependant4child4'],
            nondependant4_child4_dob: ["nondependant4_childrenno", 'fieldset_nondependant4child4'],
            nondependant4_child4_gender: ["nondependant4_childrenno", 'fieldset_nondependant4child4'],
            nondependant4_child4_bed: ["nondependant4_childrenno", 'fieldset_nondependant4child4'],
            applicant_kind_of_work_se: function () {
              return this.lib.visibleAndIcontains('applicant_self_employed', 'Yes');
            },
            applicant_business_start_se: ["applicant_kind_of_work_se"],
            applicant_business_name_se: ["applicant_kind_of_work_se"],
            applicant_business_addressline1: ["applicant_kind_of_work_se"],
            applicant_business_addressline2: ["applicant_kind_of_work_se"],
            applicant_business_addressline3: ["applicant_kind_of_work_se"],
            applicant_business_postcode_se: ["applicant_kind_of_work_se"],
            applicant_business_partner: ["applicant_kind_of_work_se"],
            applicant_business_partner_name: function () {
              return this.lib.visibleAndIcontains('applicant_business_partner', 'Yes');
            },
            applicant_business_partner_addressline1: ["applicant_business_partner_name"],
            applicant_business_partner_addressline2: ["applicant_business_partner_name", function () {
              return this.lib.getModelVisible('applicant_business_partner_addressline1');
            }],
            applicant_business_partner_addressline3: ["applicant_business_partner_addressline2"],
            applicant_business_partner_postcode: ["applicant_business_partner_name"],
            applicant_self_employed_startup_allow: ["applicant_kind_of_work_se"],
            applicant_self_employed_startup_allow_amount: function () {
              return this.lib.visibleAndIcontains('applicant_self_employed_startup_allow', 'Yes');
            },
            applicant_self_employed_startup_allow_frequency: ["applicant_self_employed_startup_allow_amount"],
            applicant_self_employed_pension: ["applicant_kind_of_work_se"],
            applicant_self_employed_pension_amount: function () {
              return this.lib.visibleAndIcontains('applicant_self_employed_pension', 'Yes');
            },
            applicant_self_employed_pension_frequency: ["applicant_self_employed_pension_amount"],
            partner_self_employed: ["couple_livingaway"],
            partner_kind_of_work_se: function () {
              return this.lib.visibleAndIcontains('partner_self_employed', 'Yes');
            },
            partner_business_start_se: ["partner_kind_of_work_se"],
            partner_business_name_se: ["partner_kind_of_work_se"],
            partner_business_address_se_1: function () {
              return this.lib.visibleAndIcontains('partner_self_employed', 'Yes');
            },
            partner_business_address_se_2: ["partner_business_address_se_1"],
            partner_business_address_se_3: ["partner_business_address_se_1"],
            partner_business_postcode: ["partner_kind_of_work_se"],
            partner_business_partner: ["partner_kind_of_work_se"],
            partner_business_partner_name: function () {
              return this.lib.visibleAndIcontains('partner_business_partner', 'Yes');
            },
            partner_business_partner_addressline1: ["partner_business_partner_name"],
            partner_business_partner_addressline2: ["partner_business_partner_name", function () {
              return this.lib.getModelVisible('partner_business_partner_addressline1');
            }],
            partner_business_partner_addressline3: ["partner_business_partner_addressline2"],
            partner_business_partner_postcode: ["partner_business_partner_name"],
            partner_self_employed_startup_allow: ["partner_kind_of_work_se"],
            partner_self_employed_startup_allow_amount: function () {
              return this.lib.visibleAndIcontains('partner_self_employed_startup_allow', 'Yes');
            },
            partner_self_employed_startup_allow_frequency: ["partner_self_employed_startup_allow_amount"],
            partner_self_employed_pension: ["partner_kind_of_work_se"],
            partner_self_employed_pension_amount: function () {
              return this.lib.visibleAndIcontains('partner_self_employed_pension', 'Yes');
            },
            partner_self_employed_pension_frequency: ["partner_self_employed_pension_amount"],
            applicant_number_of_employers: function () {
              return this.lib.visibleAndIcontains('applicant_employed', 'Yes');
            },
            applicant_kind_of_work_e1: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 1) && this.lib.visibleAndIcontains('applicant_employed', 'Yes');
            },
            applicant_business_name_e1: ["applicant_kind_of_work_e1"],
            applicant_business1_addressline1: ["applicant_kind_of_work_e1"],
            applicant_business1_addressline2: ["applicant_kind_of_work_e1", function () {
              return this.lib.getModelVisible('applicant_business1_addressline1');
            }],
            applicant_business1_addressline3: ["applicant_business1_addressline2"],
            applicant_business1_postcode: ["applicant_kind_of_work_e1"],
            applicant_job_start1: ["applicant_kind_of_work_e1"],
            applicant_employed_director1: ["applicant_kind_of_work_e1"],
            applicant_employed_company_own1: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 1) && this.lib.visibleAndIcontains('applicant_employed_director1', 'Yes');
            },
            applicant_employed_company_dividend1: ["applicant_employed_company_own1"],
            applicant_employed_company_dividendvalue1: function () {
              return this.lib.visibleAndIcontains('applicant_employed_company_dividend1', 'Yes');
            },
            applicant_employed_company_dividend_frequency1: ["applicant_employed_company_dividendvalue1"],
            applicant_employed_temporary1: ["applicant_kind_of_work_e1"],
            applicant_employed_temporary_finish_date1: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 1) && this.lib.visibleAndIcontains('applicant_employed_temporary1', 'Yes');
            },
            applicant_employed_pay_frequency1: ["applicant_kind_of_work_e1"],
            applicant_employed_pay_gross1: ["applicant_kind_of_work_e1"],
            applicant_employed_pay_method1: ["applicant_kind_of_work_e1"],
            applicant_employed_payrise1: ["applicant_kind_of_work_e1"],
            applicant_employed_pay_expected_rise1: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 1) && this.lib.visibleAndIcontains('applicant_employed_payrise1', 'Yes');
            },
            applicant_employed_ssp1: ["applicant_kind_of_work_e1"],
            applicant_employed_ssp_other1: ["applicant_kind_of_work_e1"],
            applicant_kind_of_work_e2: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 2) && this.lib.visibleAndIcontains('applicant_employed', 'Yes');
            },
            applicant_business_name_e2: ["applicant_kind_of_work_e2"],
            applicant_business2_addressline1: ["applicant_kind_of_work_e2"],
            applicant_business2_addressline2: ["applicant_kind_of_work_e2", function () {
              return this.lib.getModelVisible('applicant_business2_addressline1');
            }],
            applicant_business2_addressline3: ["applicant_business2_addressline2"],
            applicant_business2_postcode: ["applicant_kind_of_work_e2"],
            applicant_job_start2: ["applicant_kind_of_work_e2"],
            applicant_employed_director2: ["applicant_kind_of_work_e2"],
            applicant_employed_company_own2: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 2) && this.lib.visibleAndIcontains('applicant_employed_director2', 'Yes');
            },
            applicant_employed_company_dividend2: ["applicant_employed_company_own2"],
            applicant_employed_company_dividendvalue2: function () {
              return this.lib.visibleAndIcontains('applicant_employed_company_dividend2', 'Yes');
            },
            applicant_employed_company_dividend_frequency2: ["applicant_employed_company_dividendvalue2"],
            applicant_employed_temporary2: ["applicant_kind_of_work_e2"],
            applicant_employed_temporary_finish_date2: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 2) && this.lib.visibleAndIcontains('applicant_employed_temporary2', 'Yes');
            },
            applicant_employed_pay_frequency2: ["applicant_kind_of_work_e2"],
            applicant_employed_pay_gross2: ["applicant_kind_of_work_e2"],
            applicant_employed_pay_method2: ["applicant_kind_of_work_e2"],
            applicant_employed_payrise2: ["applicant_kind_of_work_e2"],
            applicant_employed_pay_expected_rise2: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 2) && this.lib.visibleAndIcontains('applicant_employed_payrise2', 'Yes');
            },
            applicant_employed_ssp2: ["applicant_kind_of_work_e2"],
            applicant_employed_ssp_other2: ["applicant_kind_of_work_e2"],
            applicant_kind_of_work_e3: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 3) && this.lib.visibleAndIcontains('applicant_employed', 'Yes');
            },
            applicant_business_name_e3: ["applicant_kind_of_work_e3"],
            applicant_business3_addressline1: ["applicant_kind_of_work_e3"],
            applicant_business3_addressline2: ["applicant_kind_of_work_e3", function () {
              return this.lib.getModelVisible('applicant_business3_addressline1');
            }],
            applicant_business3_addressline3: ["applicant_business3_addressline2"],
            applicant_business3_postcode: ["applicant_kind_of_work_e3"],
            applicant_job_start3: ["applicant_kind_of_work_e3"],
            applicant_employed_director3: ["applicant_kind_of_work_e3"],
            applicant_employed_company_own3: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 3) && this.lib.visibleAndIcontains('applicant_employed_director3', 'Yes');
            },
            applicant_employed_company_dividend3: ["applicant_employed_company_own3"],
            applicant_employed_company_dividendvalue3: function () {
              return this.lib.visibleAndIcontains('applicant_employed_company_dividend3', 'Yes');
            },
            applicant_employed_company_dividend_frequency3: ["applicant_employed_company_dividendvalue3"],
            applicant_employed_temporary3: ["applicant_kind_of_work_e3"],
            applicant_employed_temporary_finish_date3: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 3) && this.lib.visibleAndIcontains('applicant_employed_temporary3', 'Yes');
            },
            applicant_employed_pay_frequency3: ["applicant_kind_of_work_e3"],
            applicant_employed_pay_gross3: ["applicant_kind_of_work_e3"],
            applicant_employed_pay_method3: ["applicant_kind_of_work_e3"],
            applicant_employed_payrise3: ["applicant_kind_of_work_e3"],
            applicant_employed_pay_expected_rise3: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 3) && this.lib.visibleAndIcontains('applicant_employed_payrise3', 'Yes');
            },
            applicant_employed_ssp3: ["applicant_kind_of_work_e3"],
            applicant_employed_ssp_other3: ["applicant_kind_of_work_e3"],
            applicant_kind_of_work_e4: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 4) && this.lib.visibleAndIcontains('applicant_employed', 'Yes');
            },
            applicant_business_name_e4: ["applicant_kind_of_work_e4"],
            applicant_business4_addressline1: ["applicant_kind_of_work_e4"],
            applicant_business4_addressline2: ["applicant_kind_of_work_e4", function () {
              return this.lib.getModelVisible('applicant_business4_addressline1');
            }],
            applicant_business4_addressline3: ["applicant_business4_addressline2"],
            applicant_business4_postcode: ["applicant_kind_of_work_e4"],
            applicant_job_start4: ["applicant_kind_of_work_e4"],
            applicant_employed_director4: ["applicant_kind_of_work_e4"],
            applicant_employed_company_own4: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 4) && this.lib.visibleAndIcontains('applicant_employed_director4', 'Yes');
            },
            applicant_employed_company_dividend4: ["applicant_employed_company_own4"],
            applicant_employed_company_dividendvalue4: function () {
              return this.lib.visibleAndIcontains('applicant_employed_company_dividend4', 'Yes');
            },
            applicant_employed_company_dividend_frequency4: ["applicant_employed_company_dividendvalue4"],
            applicant_employed_temporary4: ["applicant_kind_of_work_e4"],
            applicant_employed_temporary_finish_date4: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 4) && this.lib.visibleAndIcontains('applicant_employed_temporary4', 'Yes');
            },
            applicant_employed_pay_frequency4: ["applicant_kind_of_work_e4"],
            applicant_employed_pay_gross4: ["applicant_kind_of_work_e4"],
            applicant_employed_pay_method4: ["applicant_kind_of_work_e4"],
            applicant_employed_payrise4: ["applicant_kind_of_work_e4"],
            applicant_employed_pay_expected_rise4: function () {
              return this.lib.visibleAndGtOrEq('applicant_number_of_employers', 4) && this.lib.visibleAndIcontains('applicant_employed_payrise4', 'Yes');
            },
            applicant_employed_ssp4: ["applicant_kind_of_work_e4"],
            applicant_employed_ssp_other4: ["applicant_kind_of_work_e4"],
            applicant_employed_pension_number: function () {
              return this.lib.visibleAndIcontains('applicant_employed_pension', 'Yes');
            },
            applicant_employed_pension_name1: function () {
              return this.lib.visibleAndGtOrEq('applicant_employed_pension_number', 1) && this.lib.visibleAndIcontains('applicant_employed_pension', 'Yes');
            },
            applicant_employed_pension_amount1: ["applicant_employed_pension_name1"],
            applicant_employed_pension_frequency1: ["applicant_employed_pension_name1"],
            applicant_employed_pension_name2: function () {
              return this.lib.visibleAndGtOrEq('applicant_employed_pension_number', 2) && this.lib.visibleAndIcontains('applicant_employed_pension', 'Yes');
            },
            applicant_employed_pension_amount2: ["applicant_employed_pension_name2"],
            applicant_employed_pension_frequency2: ["applicant_employed_pension_name2"],
            applicant_employed_pension_name3: function () {
              return this.lib.visibleAndGtOrEq('applicant_employed_pension_number', 3) && this.lib.visibleAndIcontains('applicant_employed_pension', 'Yes');
            },
            applicant_employed_pension_amount3: ["applicant_employed_pension_name3"],
            applicant_employed_pension_frequency3: ["applicant_employed_pension_name3"],
            applicant_employed_pension_name4: function () {
              return this.lib.visibleAndGtOrEq('applicant_employed_pension_number', 4) && this.lib.visibleAndIcontains('applicant_employed_pension', 'Yes');
            },
            applicant_employed_pension_amount4: ["applicant_employed_pension_name4"],
            applicant_employed_pension_frequency4: ["applicant_employed_pension_name4"],
            partner_employed: ["couple_livingaway"],
            partner_number_of_employers: function () {
              return this.lib.visibleAndIcontains('partner_employed', 'Yes');
            },
            partner_kind_of_work_e1: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 1) && this.lib.visibleAndIcontains('partner_employed', 'Yes');
            },
            partner_business_name_e1: ["partner_kind_of_work_e1"],
            partner_business1_addressline1: ["partner_kind_of_work_e1"],
            partner_business1_addressline2: ["partner_kind_of_work_e1", function () {
              return this.lib.getModelVisible('partner_business1_addressline1');
            }],
            partner_business1_addressline3: ["partner_business1_addressline2"],
            partner_business1_postcode: ["partner_kind_of_work_e1"],
            partner_job_start1: ["partner_kind_of_work_e1"],
            partner_employed_director1: ["partner_kind_of_work_e1"],
            partner_employed_company_own1: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 1) && this.lib.visibleAndIcontains('partner_employed_director1', 'Yes');
            },
            partner_employed_company_dividend1: ["partner_employed_company_own1"],
            partner_employed_company_dividendvalue1: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 1) && this.lib.visibleAndIcontains('partner_employed_company_dividend1', 'Yes');
            },
            partner_employed_company_dividend_frequency1: ["partner_employed_company_dividendvalue1"],
            partner_employed_temporary1: ["partner_kind_of_work_e1"],
            partner_employed_temporary_finish_date1: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 1) && this.lib.visibleAndIcontains('partner_employed_temporary1', 'Yes');
            },
            partner_employed_pay_frequency1: ["partner_kind_of_work_e1"],
            partner_employed_pay_gross1: ["partner_kind_of_work_e1"],
            partner_employed_pay_method1: ["partner_kind_of_work_e1"],
            partner_employed_payrise1: ["partner_kind_of_work_e1"],
            partner_employed_pay_expected_rise1: function () {
              return this.lib.visibleAndIcontains('partner_employed_payrise1', 'Yes');
            },
            partner_employed_ssp1: ["partner_kind_of_work_e1"],
            partner_employed_ssp_other1: ["partner_kind_of_work_e1"],
            partner_kind_of_work_e2: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 2) && this.lib.visibleAndIcontains('partner_employed', 'Yes');
            },
            partner_business_name_e2: ["partner_kind_of_work_e2"],
            partner_business2_addressline1: ["partner_kind_of_work_e2"],
            partner_business2_addressline2: ["partner_kind_of_work_e2", function () {
              return this.lib.getModelVisible('partner_business2_addressline1');
            }],
            partner_business2_addressline3: ["partner_business2_addressline2"],
            partner_business2_postcode: ["partner_kind_of_work_e2"],
            partner_job_start2: ["partner_kind_of_work_e2"],
            partner_employed_director2: ["partner_kind_of_work_e2"],
            partner_employed_company_own2: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 2) && this.lib.visibleAndIcontains('partner_employed_director2', 'Yes');
            },
            partner_employed_company_dividend2: ["partner_employed_company_own2"],
            partner_employed_company_dividendvalue2: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 2) && this.lib.visibleAndIcontains('partner_employed_company_dividend2', 'Yes');
            },
            partner_employed_company_dividend_frequency2: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 2) && this.lib.visibleAndGtOrEq('partner_number_of_employers', 2) && this.lib.visibleAndIcontains('partner_employed_company_dividend2', 'Yes');
            },
            partner_employed_temporary2: ["partner_kind_of_work_e2"],
            partner_employed_temporary_finish_date2: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 2) && this.lib.visibleAndIcontains('partner_employed_temporary2', 'Yes');
            },
            partner_employed_pay_frequency2: ["partner_kind_of_work_e2"],
            partner_employed_pay_gross2: ["partner_kind_of_work_e2"],
            partner_employed_pay_method2: ["partner_kind_of_work_e2"],
            partner_employed_payrise2: ["partner_kind_of_work_e2"],
            partner_employed_pay_expected_rise2: function () {
              return this.lib.visibleAndIcontains('partner_employed_payrise2', 'Yes');
            },
            partner_employed_ssp2: ["partner_kind_of_work_e2"],
            partner_employed_ssp_other2: ["partner_kind_of_work_e2"],
            partner_kind_of_work_e3: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 3) && this.lib.visibleAndIcontains('partner_employed', 'Yes');
            },
            partner_business_name_e3: ["partner_kind_of_work_e3"],
            partner_business3_addressline1: ["partner_kind_of_work_e3"],
            partner_business3_addressline2: ["partner_kind_of_work_e3", function () {
              return this.lib.getModelVisible('partner_business3_addressline1');
            }],
            partner_business3_addressline3: ["partner_business3_addressline2"],
            partner_business3_postcode: ["partner_kind_of_work_e3"],
            partner_job_start3: ["partner_kind_of_work_e3"],
            partner_employed_director3: ["partner_kind_of_work_e3"],
            partner_employed_company_own3: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 3) && this.lib.visibleAndIcontains('partner_employed_director3', 'Yes');
            },
            partner_employed_company_dividend3: ["partner_employed_company_own3"],
            partner_employed_company_dividendvalue3: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 3) && this.lib.visibleAndIcontains('partner_employed_company_dividend3', 'Yes');
            },
            partner_employed_company_dividend_frequency3: ["partner_employed_company_dividendvalue3"],
            partner_employed_temporary3: ["partner_kind_of_work_e3"],
            partner_employed_temporary_finish_date3: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 3) && this.lib.visibleAndIcontains('partner_employed_temporary3', 'Yes');
            },
            partner_employed_pay_frequency3: ["partner_kind_of_work_e3"],
            partner_employed_pay_gross3: ["partner_kind_of_work_e3"],
            partner_employed_pay_method3: ["partner_kind_of_work_e3"],
            partner_employed_payrise3: ["partner_kind_of_work_e3"],
            partner_employed_pay_expected_rise3: function () {
              return this.lib.visibleAndIcontains('partner_employed_payrise3', 'Yes');
            },
            partner_employed_ssp3: ["partner_kind_of_work_e3"],
            partner_employed_ssp_other3: ["partner_kind_of_work_e3"],
            partner_kind_of_work_e4: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 4) && this.lib.visibleAndIcontains('partner_employed', 'Yes');
            },
            partner_business_name_e4: ["partner_kind_of_work_e4"],
            partner_business4_addressline1: ["partner_kind_of_work_e4"],
            partner_business4_addressline2: ["partner_kind_of_work_e4", function () {
              return this.lib.getModelVisible('partner_business4_addressline1');
            }],
            partner_business4_addressline3: ["partner_business4_addressline2"],
            partner_business4_postcode: ["partner_kind_of_work_e4"],
            partner_job_start4: ["partner_kind_of_work_e4"],
            partner_employed_director4: ["partner_kind_of_work_e4"],
            partner_employed_company_own4: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 4) && this.lib.visibleAndIcontains('partner_employed_director4', 'Yes');
            },
            partner_employed_company_dividend4: ["partner_employed_company_own4"],
            partner_employed_company_dividendvalue4: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 4) && this.lib.visibleAndIcontains('partner_employed_company_dividend4', 'Yes');
            },
            partner_employed_company_dividend_frequency4: ["partner_employed_company_dividendvalue4"],
            partner_employed_temporary4: ["partner_kind_of_work_e4"],
            partner_employed_temporary_finish_date4: function () {
              return this.lib.visibleAndGtOrEq('partner_number_of_employers', 4) && this.lib.visibleAndIcontains('partner_employed_temporary4', 'Yes');
            },
            partner_employed_pay_frequency4: ["partner_kind_of_work_e4"],
            partner_employed_pay_gross4: ["partner_kind_of_work_e4"],
            partner_employed_pay_method4: ["partner_kind_of_work_e4"],
            partner_employed_payrise4: ["partner_kind_of_work_e4"],
            partner_employed_pay_expected_rise4: function () {
              return this.lib.visibleAndIcontains('partner_employed_payrise4', 'Yes');
            },
            partner_employed_ssp4: ["partner_kind_of_work_e4"],
            partner_employed_ssp_other4: ["partner_kind_of_work_e4"],
            partner_employed_pension_number: function () {
              return this.lib.visibleAndIcontains('partner_employed_pension', 'Yes');
            },
            partner_employed_pension_name1: function () {
              return this.lib.visibleAndGtOrEq('partner_employed_pension_number', 1) && this.lib.visibleAndIcontains('partner_employed_pension', 'Yes');
            },
            partner_employed_pension_amount1: ["partner_employed_pension_name1"],
            partner_employed_pension_frequency1: ["partner_employed_pension_name1"],
            partner_employed_pension_name2: function () {
              return this.lib.visibleAndGtOrEq('partner_employed_pension_number', 2) && this.lib.visibleAndIcontains('partner_employed_pension', 'Yes');
            },
            partner_employed_pension_amount2: ["partner_employed_pension_name2"],
            partner_employed_pension_frequency2: ["partner_employed_pension_name2"],
            partner_employed_pension_name3: function () {
              return this.lib.visibleAndGtOrEq('partner_employed_pension_number', 3) && this.lib.visibleAndIcontains('partner_employed_pension', 'Yes');
            },
            partner_employed_pension_amount3: ["partner_employed_pension_name3"],
            partner_employed_pension_frequency3: ["partner_employed_pension_name3"],
            partner_employed_pension_name4: function () {
              return this.lib.visibleAndGtOrEq('partner_employed_pension_number', 4) && this.lib.visibleAndIcontains('partner_employed_pension', 'Yes');
            },
            partner_employed_pension_amount4: ["partner_employed_pension_name4"],
            partner_employed_pension_frequency4: ["partner_employed_pension_name4"],
            applicant_other_work_type: function () {
              return this.lib.visibleAndIcontains('applicant_other_work', 'Yes');
            },
            applicant_other_work_employer: ["applicant_other_work_type"],
            applicant_other_work_employer_address1: ["applicant_other_work_type"],
            applicant_other_work_employer_address2: ["applicant_other_work_type"],
            applicant_other_work_employer_address3: ["applicant_other_work_type"],
            applicant_other_work_employer_postcode: ["applicant_other_work_type"],
            applicant_other_work_employer_start_dt: ["applicant_other_work_type"],
            applicant_other_work_director: ["applicant_other_work_type"],
            applicant_other_work_company_own: function () {
              return this.lib.visibleAndIcontains('applicant_other_work_director', 'Yes');
            },
            applicant_other_work_company_dividend: ["applicant_other_work_company_own"],
            applicant_other_work_company_dividend_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_work_company_dividend', 'Yes');
            },
            applicant_other_work_company_dividend_frequency: ["applicant_other_work_company_dividend_amount"],
            partner_other_work: ["couple_livingaway"],
            partner_other_work_type: function () {
              return this.lib.visibleAndIcontains('partner_other_work', 'Yes');
            },
            partner_other_work_employer: ["partner_other_work_type"],
            partner_other_work_employer_address1: ["partner_other_work_type"],
            partner_other_work_employer_address2: ["partner_other_work_type"],
            partner_other_work_employer_address3: ["partner_other_work_type"],
            partner_other_work_employer_postcode: ["partner_other_work_type"],
            partner_other_work_employer_tel: ["partner_other_work_type"],
            partner_other_work_employer_start_dt: ["partner_other_work_type"],
            partner_other_work_director: ["partner_other_work_type"],
            partner_other_work_company_own: function () {
              return this.lib.visibleAndIcontains('partner_other_work_director', 'Yes');
            },
            partner_other_work_company_dividend: ["partner_other_work_company_own"],
            partner_other_work_company_dividend_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_work_company_dividend', 'Yes');
            },
            partner_other_work_company_dividend_frequency: ["partner_other_work_company_dividend_amount"],
            applicant_other_benefit_adoptionpay_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Adoption Pay') !== -1;
            },
            applicant_other_benefit_adoptionpay_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_adoptionpay_status', 'Getting now');
            },
            applicant_other_benefit_adoptionpay_frequency: ["applicant_other_benefit_adoptionpay_amount"],
            applicant_other_benefit_armedforcescompensation_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Armed Forces Compensation Scheme (AFCS)') !== -1;
            },
            applicant_other_benefit_armedforcescompensation_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_armedforcescompensation_status', 'Getting now');
            },
            applicant_other_benefit_armedforcescompensation_frequency: ["applicant_other_benefit_armedforcescompensation_amount"],
            applicant_other_benefit_bereavementallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Bereavement Allowance') !== -1;
            },
            applicant_other_benefit_bereavementallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_bereavementallowance_status', 'Getting now');
            },
            applicant_other_benefit_bereavementallowance_frequency: ["applicant_other_benefit_bereavementallowance_amount"],
            applicant_other_benefit_carersallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Carer\'s Allowance') !== -1;
            },
            applicant_other_benefit_carersallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_carersallowance_status', 'Getting now');
            },
            applicant_other_benefit_carersallowance_frequency: ["applicant_other_benefit_carersallowance_amount"],
            applicant_other_benefit_childbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Child Benefit') !== -1;
            },
            applicant_other_benefit_childbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_childbenefit_status', 'Getting now');
            },
            applicant_other_benefit_childbenefit_frequency: ["applicant_other_benefit_childbenefit_amount"],
            applicant_other_benefit_childtaxcredit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Child Tax Credit') !== -1;
            },
            applicant_other_benefit_childtaxcredit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_childtaxcredit_status', 'Getting now');
            },
            applicant_other_benefit_childtaxcredit_frequency: ["applicant_other_benefit_childtaxcredit_amount"],
            applicant_other_benefit_contributionesa_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Contribution-based Employment and Support Allowance') !== -1;
            },
            applicant_other_benefit_contributionesa_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_contributionesa_status', 'Getting now');
            },
            applicant_other_benefit_contributionesa_frequency: ["applicant_other_benefit_contributionesa_amount"],
            applicant_other_benefit_contributionjsa_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Contribution-based Jobseeker\'s Allowance') !== -1;
            },
            applicant_other_benefit_contributionjsa_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_contributionjsa_status', 'Getting now');
            },
            applicant_other_benefit_contributionjsa_frequency: ["applicant_other_benefit_contributionjsa_amount"],
            applicant_other_benefit_fosteringallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Fostering Allowance') !== -1;
            },
            applicant_other_benefit_fosteringallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_fosteringallowance_status', 'Getting now');
            },
            applicant_other_benefit_fosteringallowance_frequency: ["applicant_other_benefit_fosteringallowance_amount"],
            applicant_other_benefit_guardiansallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Guardian\'s Allowance') !== -1;
            },
            applicant_other_benefit_guardiansallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_guardiansallowance_status', 'Getting now');
            },
            applicant_other_benefit_guardiansallowance_frequency: ["applicant_other_benefit_guardiansallowance_amount"],
            applicant_other_benefit_incapacitybenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Incapacity Benefit') !== -1;
            },
            applicant_other_benefit_incapacitybenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_incapacitybenefit_status', 'Getting now');
            },
            applicant_other_benefit_incapacitybenefit_frequency: ["applicant_other_benefit_incapacitybenefit_amount"],
            applicant_other_benefit_industrialdeathbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Industrial Death Benefit') !== -1;
            },
            applicant_other_benefit_industrialdeathbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_industrialdeathbenefit_status', 'Getting now');
            },
            applicant_other_benefit_industrialdeathbenefit_frequency: ["applicant_other_benefit_industrialdeathbenefit_amount"],
            applicant_other_benefit_injuriesdisablementbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Industrial Injuries Disablement Benefit') !== -1;
            },
            applicant_other_benefit_injuriesdisablementbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_injuriesdisablementbenefit_status', 'Getting now');
            },
            applicant_other_benefit_injuriesdisablementbenefit_frequency: ["applicant_other_benefit_injuriesdisablementbenefit_amount"],
            applicant_other_benefit_maintenancepayment_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Maintenance Payment') !== -1;
            },
            applicant_other_benefit_maintenancepayment_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_maintenancepayment_status', 'Getting now');
            },
            applicant_other_benefit_maintenancepayment_frequency: ["applicant_other_benefit_maintenancepayment_amount"],
            applicant_other_benefit_maternityallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Maternity Allowance') !== -1;
            },
            applicant_other_benefit_maternityallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_maternityallowance_status', 'Getting now');
            },
            applicant_other_benefit_maternityallowance_frequency: ["applicant_other_benefit_maternityallowance_amount"],
            applicant_other_benefit_severedisablementallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Severe Disablement Allowance') !== -1;
            },
            applicant_other_benefit_severedisablementallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_severedisablementallowance_status', 'Getting now');
            },
            applicant_other_benefit_severedisablementallowance_frequency: ["applicant_other_benefit_severedisablementallowance_amount"],
            applicant_other_benefit_stateretirementpension_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'State Retirement Pension') !== -1;
            },
            applicant_other_benefit_stateretirementpension_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_stateretirementpension_status', 'Getting now');
            },
            applicant_other_benefit_stateretirementpension_frequency: ["applicant_other_benefit_stateretirementpension_amount"],
            applicant_other_benefit_statutorymaternitypay_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Statutory Maternity Pay') !== -1;
            },
            applicant_other_benefit_statutorymaternitypay_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_statutorymaternitypay_status', 'getting now');
            },
            applicant_other_benefit_statutorymaternitypay_frequency: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_statutorymaternitypay_status', 'Getting now');
            },
            applicant_other_benefit_statutorypaternitypay_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Statutory Paternity Pay') !== -1;
            },
            applicant_other_benefit_statutorypaternitypay_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_statutorypaternitypay_status', 'Getting now');
            },
            applicant_other_benefit_statutorypaternitypay_frequency: ["applicant_other_benefit_statutorypaternitypay_amount"],
            applicant_other_benefit_statutorysickpay_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Statutory Sick Pay') !== -1;
            },
            applicant_other_benefit_statutorysickpay_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_statutorysickpay_status', 'Getting now');
            },
            applicant_other_benefit_statutorysickpay_frequency: ["applicant_other_benefit_statutorysickpay_amount"],
            applicant_other_benefit_trainingallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Training Allowance') !== -1;
            },
            applicant_other_benefit_trainingallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_trainingallowance_status', 'Getting now');
            },
            applicant_other_benefit_trainingallowance_frequency: ["applicant_other_benefit_trainingallowance_amount"],
            applicant_other_benefit_wardisablementbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'War Disablement Benefit') !== -1;
            },
            applicant_other_benefit_wardisablementbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_wardisablementbenefit_status', 'Getting now');
            },
            applicant_other_benefit_wardisablementbenefit_frequency: ["applicant_other_benefit_wardisablementbenefit_amount"],
            applicant_other_benefit_warpension_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'War Pension or War Widow\'s Pension') !== -1;
            },
            applicant_other_benefit_warpension_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_warpension_status', 'Getting now');
            },
            applicant_other_benefit_warpension_frequency: ["applicant_other_benefit_warpension_amount"],
            applicant_other_benefit_widowbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Widow or Widower\'s Benefit') !== -1;
            },
            applicant_other_benefit_widowbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_widowbenefit_status', 'Getting now');
            },
            applicant_other_benefit_widowbenefit_frequency: ["applicant_other_benefit_widowbenefit_amount"],
            applicant_other_benefit_widowedparentsallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Widowed Parent\'s Allowance') !== -1;
            },
            applicant_other_benefit_widowedparentsallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_widowedparentsallowance_status', 'Getting now');
            },
            applicant_other_benefit_widowedparentsallowance_frequency: ["applicant_other_benefit_widowedparentsallowance_amount"],
            applicant_other_benefit_workingtaxcredit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Working Tax Credit') !== -1;
            },
            applicant_other_benefit_workingtaxcredit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_workingtaxcredit_status', 'Getting now');
            },
            applicant_other_benefit_workingtaxcredit_frequency: ["applicant_other_benefit_workingtaxcredit_amount"],
            partner_other_benefit_type: function () {
              return this.lib.visibleAndIcontains('couple', 'Yes');
            },
            partner_other_benefit_adoptionpay_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Adoption Pay') !== -1;
            },
            partner_other_benefit_adoptionpay_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_adoptionpay_status', 'Getting now');
            },
            partner_other_benefit_adoptionpay_frequency: ["partner_other_benefit_adoptionpay_amount"],
            partner_other_benefit_armedforcescompensation_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Armed Forces Compensation Scheme (AFCS)') !== -1;
            },
            partner_other_benefit_armedforcescompensation_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_armedforcescompensation_status', 'Getting now');
            },
            partner_other_benefit_armedforcescompensation_frequency: ["partner_other_benefit_armedforcescompensation_amount"],
            partner_other_benefit_bereavementallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Bereavement Allowance') !== -1;
            },
            partner_other_benefit_bereavementallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_bereavementallowance_status', 'Getting now');
            },
            partner_other_benefit_bereavementallowance_frequency: ["partner_other_benefit_bereavementallowance_amount"],
            partner_other_benefit_carersallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Carer\'s Allowance') !== -1;
            },
            partner_other_benefit_carersallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_carersallowance_status', 'Getting now');
            },
            partner_other_benefit_carersallowance_frequency: ["partner_other_benefit_carersallowance_amount"],
            partner_other_benefit_childbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Child Benefit') !== -1;
            },
            partner_other_benefit_childbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_childbenefit_status', 'Getting now');
            },
            partner_other_benefit_childbenefit_frequency: ["partner_other_benefit_childbenefit_amount"],
            partner_other_benefit_childtaxcredit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Child Tax Credit') !== -1;
            },
            partner_other_benefit_childtaxcredit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_childtaxcredit_status', 'Getting now');
            },
            partner_other_benefit_childtaxcredit_frequency: ["partner_other_benefit_childtaxcredit_amount"],
            partner_other_benefit_contributionesa_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Contribution-based Employment and Support Allowance') !== -1;
            },
            partner_other_benefit_contributionesa_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_contributionesa_status', 'Getting now');
            },
            partner_other_benefit_contributionesa_frequency: ["partner_other_benefit_contributionesa_amount"],
            partner_other_benefit_contributionjsa_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Contribution-based Jobseeker\'s Allowance') !== -1;
            },
            partner_other_benefit_contributionjsa_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_contributionjsa_status', 'Getting now');
            },
            partner_other_benefit_contributionjsa_frequency: ["partner_other_benefit_contributionjsa_amount"],
            partner_other_benefit_fosteringallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Fostering Allowance') !== -1;
            },
            partner_other_benefit_fosteringallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_fosteringallowance_status', 'Getting now');
            },
            partner_other_benefit_fosteringallowance_frequency: ["partner_other_benefit_fosteringallowance_amount"],
            partner_other_benefit_guardiansallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Guardian\'s Allowance') !== -1;
            },
            partner_other_benefit_guardiansallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_guardiansallowance_status', 'Getting now');
            },
            partner_other_benefit_guardiansallowance_frequency: ["partner_other_benefit_guardiansallowance_amount"],
            partner_other_benefit_incapacitybenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Incapacity Benefit') !== -1;
            },
            partner_other_benefit_incapacitybenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_incapacitybenefit_status', 'Getting now');
            },
            partner_other_benefit_incapacitybenefit_frequency: ["partner_other_benefit_incapacitybenefit_amount"],
            partner_other_benefit_industrialdeathbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Industrial Death Benefit') !== -1;
            },
            partner_other_benefit_industrialdeathbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_industrialdeathbenefit_status', 'Getting now');
            },
            partner_other_benefit_industrialdeathbenefit_frequency: ["partner_other_benefit_industrialdeathbenefit_amount"],
            partner_other_benefit_injuriesdisablementbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Industrial Injuries Disablement Benefit') !== -1;
            },
            partner_other_benefit_injuriesdisablementbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_injuriesdisablementbenefit_status', 'Getting now');
            },
            partner_other_benefit_injuriesdisablementbenefit_frequency: ["partner_other_benefit_injuriesdisablementbenefit_amount"],
            partner_other_benefit_maintenancepayment_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Maintenance Payment') !== -1;
            },
            partner_other_benefit_maintenancepayment_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_maintenancepayment_status', 'Getting now');
            },
            partner_other_benefit_maintenancepayment_frequency: ["partner_other_benefit_maintenancepayment_amount"],
            partner_other_benefit_maternityallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Maternity Allowance') !== -1;
            },
            partner_other_benefit_maternityallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_maternityallowance_status', 'Getting now');
            },
            partner_other_benefit_maternityallowance_frequency: ["partner_other_benefit_maternityallowance_amount"],
            partner_other_benefit_severedisablementallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Severe Disablement Allowance') !== -1;
            },
            partner_other_benefit_severedisablementallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_severedisablementallowance_status', 'Getting now');
            },
            partner_other_benefit_severedisablementallowance_frequency: ["partner_other_benefit_severedisablementallowance_amount"],
            partner_other_benefit_stateretirementpension_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'State Retirement Pension') !== -1;
            },
            partner_other_benefit_stateretirementpension_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_stateretirementpension_status', 'Getting now');
            },
            partner_other_benefit_stateretirementpension_frequency: ["partner_other_benefit_stateretirementpension_amount"],
            partner_other_benefit_statutorymaternitypay_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Statutory Maternity Pay') !== -1;
            },
            partner_other_benefit_statutorymaternitypay_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_statutorymaternitypay_status', 'Getting now');
            },
            partner_other_benefit_statutorymaternitypay_frequency: ["partner_other_benefit_statutorymaternitypay_amount"],
            partner_other_benefit_statutorypaternitypay_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Statutory Paternity Pay') !== -1;
            },
            partner_other_benefit_statutorypaternitypay_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_statutorypaternitypay_status', 'Getting now');
            },
            partner_other_benefit_statutorypaternitypay_frequency: ["partner_other_benefit_statutorypaternitypay_amount"],
            partner_other_benefit_statutorysickpay_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Statutory Sick Pay') !== -1;
            },
            partner_other_benefit_statutorysickpay_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_statutorysickpay_status', 'Getting now');
            },
            partner_other_benefit_statutorysickpay_frequency: ["partner_other_benefit_statutorysickpay_amount"],
            partner_other_benefit_trainingallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Training Allowance') !== -1;
            },
            partner_other_benefit_trainingallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_trainingallowance_status', 'Getting now');
            },
            partner_other_benefit_trainingallowance_frequency: ["partner_other_benefit_trainingallowance_amount"],
            partner_other_benefit_wardisablementbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'War Disablement Benefit') !== -1;
            },
            partner_other_benefit_wardisablementbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_wardisablementbenefit_status', 'Getting now');
            },
            partner_other_benefit_wardisablementbenefit_frequency: ["partner_other_benefit_wardisablementbenefit_amount"],
            partner_other_benefit_warpension_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'War Pension or War Widow\'s Pension') !== -1;
            },
            partner_other_benefit_warpension_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_warpension_status', 'Getting now');
            },
            partner_other_benefit_warpension_frequency: ["partner_other_benefit_warpension_amount"],
            partner_other_benefit_widowbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Widow or Widower\'s Benefit') !== -1;
            },
            partner_other_benefit_widowbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_widowbenefit_status', 'Getting now');
            },
            partner_other_benefit_widowbenefit_frequency: ["partner_other_benefit_widowbenefit_amount"],
            partner_other_benefit_widowedparentsallowance_status: function () {
              return (this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Widowed Parent\'s Allowance')) !== -1;
            },
            partner_other_benefit_widowedparentsallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_widowedparentsallowance_status', 'Getting now');
            },
            partner_other_benefit_widowedparentsallowance_frequency: ["partner_other_benefit_widowedparentsallowance_amount"],
            partner_other_benefit_workingtaxcredit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Working Tax Credit') !== -1;
            },
            partner_other_benefit_workingtaxcredit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_workingtaxcredit_status', 'Getting now');
            },
            partner_other_benefit_workingtaxcredit_frequency: ["partner_other_benefit_workingtaxcredit_amount"],
            applicant_other_benefit_pension_number: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_pension', 'Yes');
            },
            applicant_other_benefit_pension_company1: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_benefit_pension_number', 1) && this.lib.visibleAndIcontains('applicant_other_benefit_pension', 'Yes');
            },
            applicant_other_benefit_pension_value1: ["applicant_other_benefit_pension_company1"],
            applicant_other_benefit_pension_frequency1: ["applicant_other_benefit_pension_company1"],
            applicant_other_benefit_pension_increase1: ["applicant_other_benefit_pension_company1"],
            applicant_other_benefit_pension_company2: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_benefit_pension_number', 2) && this.lib.visibleAndIcontains('applicant_other_benefit_pension', 'Yes');
            },
            applicant_other_benefit_pension_value2: ["applicant_other_benefit_pension_company2"],
            applicant_other_benefit_pension_frequency2: ["applicant_other_benefit_pension_company2"],
            applicant_other_benefit_pension_increase2: ["applicant_other_benefit_pension_company2"],
            applicant_other_benefit_pension_company3: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_benefit_pension_number', 3) && this.lib.visibleAndIcontains('applicant_other_benefit_pension', 'Yes');
            },
            applicant_other_benefit_pension_value3: ["applicant_other_benefit_pension_company3"],
            applicant_other_benefit_pension_frequency3: ["applicant_other_benefit_pension_company3"],
            applicant_other_benefit_pension_increase3: ["applicant_other_benefit_pension_company3"],
            applicant_other_benefit_pension_company4: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_benefit_pension_number', 4) && this.lib.visibleAndIcontains('applicant_other_benefit_pension', 'Yes');
            },
            applicant_other_benefit_pension_value4: ["applicant_other_benefit_pension_company4"],
            applicant_other_benefit_pension_frequency4: ["applicant_other_benefit_pension_company4"],
            applicant_other_benefit_pension_increase4: ["applicant_other_benefit_pension_company4"],

            applicant_other_benefit_pension_defer_lump_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_pension_defer_lump', 'Yes');
            },
            partner_other_benefit_pension: ["couple_livingaway"],
            partner_other_benefit_pension_number: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_pension', 'Yes');
            },
            partner_other_benefit_pension_company1: function () {
              return this.lib.visibleAndGtOrEq('partner_other_benefit_pension_number', 1) && this.lib.visibleAndIcontains('partner_other_benefit_pension', 'Yes');
            },
            partner_other_benefit_pension_value1: ["partner_other_benefit_pension_company1"],
            partner_other_benefit_pension_frequency1: function () {
              return this.lib.visibleAndGtOrEq('partner_other_benefit_pension_number', 1);
            },
            partner_other_benefit_pension_increase1: ["partner_other_benefit_pension_company1"],
            partner_other_benefit_pension_company2: function () {
              return this.lib.visibleAndGtOrEq('partner_other_benefit_pension_number', 2) && this.lib.visibleAndIcontains('partner_other_benefit_pension', 'Yes');
            },
            partner_other_benefit_pension_value2: ["partner_other_benefit_pension_company2"],
            partner_other_benefit_pension_frequency2: function () {
              return this.lib.visibleAndGtOrEq('partner_other_benefit_pension_number', 2);
            },
            partner_other_benefit_pension_increase2: ["partner_other_benefit_pension_frequency2"],
            partner_other_benefit_pension_company3: function () {
              return this.lib.visibleAndGtOrEq('partner_other_benefit_pension_number', 3) && this.lib.visibleAndIcontains('partner_other_benefit_pension', 'Yes');
            },
            partner_other_benefit_pension_value3: ["partner_other_benefit_pension_company3"],
            partner_other_benefit_pension_frequency3: function () {
              return this.lib.visibleAndGtOrEq('partner_other_benefit_pension_number', 3);
            },
            partner_other_benefit_pension_increase3: ["partner_other_benefit_pension_frequency3"],
            partner_other_benefit_pension_company4: function () {
              return this.lib.visibleAndGtOrEq('partner_other_benefit_pension_number', 4) && this.lib.visibleAndIcontains('partner_other_benefit_pension', 'Yes');
            },
            partner_other_benefit_pension_value4: ["partner_other_benefit_pension_company4"],
            partner_other_benefit_pension_frequency4: function () {
              return this.lib.visibleAndGtOrEq('partner_other_benefit_pension_number', 4);
            },
            partner_other_benefit_pension_increase4: ["partner_other_benefit_pension_frequency4"],
            partner_other_benefit_pension_defer: ["couple_livingaway"],
            partner_other_benefit_pension_defer_lump: ["couple_livingaway"],
            partner_other_benefit_pension_defer_lump_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_pension_defer_lump', 'Yes');
            },
            applicant_other_income: ["applicant_livingaway"],
            couple_other_income: ["couple_livingaway"],
            applicant_other_income_no: function () {
              return this.lib.visibleAndIcontains('applicant_other_income', 'Yes') || this.lib.visibleAndIcontains('couple_other_income', 'Yes');
            },
            other_income_1_use: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_income_no', 1) && (this.lib.visibleAndIcontains('couple_other_income', 'Yes') || this.lib.visibleAndIcontains('applicant_other_income', 'Yes'));
            },
            other_income_1_pay_method: ["other_income_1_use"],
            other_income_1_recipient: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_income_no', 1) && this.lib.visibleAndIcontains('couple_other_income', 'Yes');
            },
            other_income_1_amount: ["other_income_1_use"],
            other_income_1_frequency: ["other_income_1_use"],
            other_income_1_start_date: ["other_income_1_use"],
            other_income_1_increase: ["other_income_1_use"],
            other_income_2_use: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_income_no', 2) && (this.lib.visibleAndIcontains('couple_other_income', 'Yes') || this.lib.visibleAndIcontains('applicant_other_income', 'Yes'));
            },
            other_income_2_pay_method: ["other_income_2_use"],
            other_income_2_recipient: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_income_no', 2) && this.lib.visibleAndIcontains('couple_other_income', 'Yes');
            },
            other_income_2_amount: ["other_income_2_use"],
            other_income_2_frequency: ["other_income_2_use"],
            other_income_2_start_date: ["other_income_2_use"],
            other_income_2_increase: ["other_income_2_use"],
            other_income_3_use: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_income_no', 3) && (this.lib.visibleAndIcontains('couple_other_income', 'Yes') || this.lib.visibleAndIcontains('applicant_other_income', 'Yes'));
            },
            other_income_3_pay_method: ["other_income_3_use"],
            other_income_3_recipient: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_income_no', 3) && this.lib.visibleAndIcontains('couple_other_income', 'Yes');
            },
            other_income_3_amount: ["other_income_3_use"],
            other_income_3_frequency: ["other_income_3_use"],
            other_income_3_start_date: ["other_income_3_use"],
            other_income_3_increase: ["other_income_3_use"],
            other_income_4_use: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_income_no', 4) && (this.lib.visibleAndIcontains('couple_other_income', 'Yes') || this.lib.visibleAndIcontains('applicant_other_income', 'Yes'));
            },
            other_income_4_pay_method: ["other_income_4_use"],
            other_income_4_recipient: function () {
              return this.lib.visibleAndGtOrEq('applicant_other_income_no', 4) && this.lib.visibleAndIcontains('couple_other_income', 'Yes');
            },
            other_income_4_amount: ["other_income_4_use"],
            other_income_4_frequency: ["other_income_4_use"],
            other_income_4_start_date: ["other_income_4_use"],
            other_income_4_increase: ["other_income_4_use"],
            applicant_other_income_owed: ["applicant_livingaway"],
            applicant_other_income_owed_reason: function () {
              return this.lib.visibleAndIcontains('applicant_other_income_owed', 'Yes');
            },
            applicant_other_income_owed_amount: ["applicant_other_income_owed_reason"],
            applicant_other_income_expected: ["applicant_livingaway"],
            applicant_other_income_expected_reason: function () {
              return this.lib.visibleAndIcontains('applicant_other_income_expected', 'Yes');
            },
            applicant_other_income_expected_amount: ["applicant_other_income_expected_reason"],
            couple_other_income_owed: ["couple_livingaway"],
            couple_other_income_owed_reason: function () {
              return this.lib.visibleAndIcontains('couple_other_income_owed', 'Yes');
            },
            couple_other_income_owed_recipient: ["couple_other_income_owed_reason"],
            couple_other_income_owed_amount: ["couple_other_income_owed_reason"],
            couple_other_income_expected: ["couple_livingaway"],
            couple_other_income_expected_reason: function () {
              return this.lib.visibleAndIcontains('couple_other_income_expected', 'Yes');
            },
            couple_other_income_expected_recipient: ["couple_other_income_expected_reason"],
            couple_other_income_expected_amount: ["couple_other_income_expected_reason"],
            applicant_accounts: ["applicant_livingaway"],
            applicant_account1_bank: function () {
              return this.lib.visibleAndGtOrEq('applicant_accounts', 1);
            },
            applicant_account1_number: ["applicant_account1_bank"],
            applicant_account1_balance: ["applicant_account1_bank"],
            applicant_account2_bank: function () {
              return this.lib.visibleAndGtOrEq('applicant_accounts', 2);
            },
            applicant_account2_number: ["applicant_account2_bank"],
            applicant_account2_balance: ["applicant_account2_bank"],
            applicant_account3_bank: function () {
              return this.lib.visibleAndGtOrEq('applicant_accounts', 3);
            },
            applicant_account3_number: ["applicant_account3_bank"],
            applicant_account3_balance: ["applicant_account3_bank"],
            applicant_account4_bank: function () {
              return this.lib.visibleAndGtOrEq('applicant_accounts', 4);
            },
            applicant_account4_number: ["applicant_account4_bank"],
            applicant_account4_balance: ["applicant_account4_bank"],
            couple_accounts: ["couple_livingaway"],
            couple_account1_bank: function () {
              return this.lib.visibleAndGtOrEq('couple_accounts', 1) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            couple_account1_name: ["couple_account1_bank"],
            couple_account1_number: ["couple_account1_bank"],
            couple_account1_balance: ["couple_account1_bank"],
            couple_account2_bank: function () {
              return this.lib.visibleAndGtOrEq('couple_accounts', 2) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            couple_account2_name: ["couple_account2_bank"],
            couple_account2_number: ["couple_account2_bank"],
            couple_account2_balance: ["couple_account2_bank"],
            couple_account3_bank: function () {
              return this.lib.visibleAndGtOrEq('couple_accounts', 3) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            couple_account3_name: ["couple_account3_bank"],
            couple_account3_number: ["couple_account3_bank"],
            couple_account3_balance: ["couple_account3_bank"],
            couple_account4_bank: function () {
              return this.lib.visibleAndGtOrEq('couple_accounts', 4) && this.lib.visibleAndIcontains('couple', 'Yes');
            },
            couple_account4_name: ["couple_account4_bank"],
            couple_account4_number: ["couple_account4_bank"],
            couple_account4_balance: ["couple_account4_bank"],
            applicant_capital: ["applicant_livingaway"],
            applicant_capitaldetails: function () {
              return this.lib.visibleAndIcontains('applicant_capital', 'Yes');
            },
            applicant_nsc: ["applicant_livingaway"],
            applicant_stocks: ["applicant_livingaway"],
            applicant_stocks_number: function () {
              return this.lib.visibleAndIcontains('applicant_stocks', 'Yes');
            },
            applicant_stocks_company1: function () {
              return this.lib.visibleAndGtOrEq('applicant_stocks_number', 1) && this.lib.visibleAndIcontains('applicant_stocks', 'Yes');
            },
            applicant_stocks_no1: ["applicant_stocks_company1"],
            applicant_stocks_company2: function () {
              return this.lib.visibleAndGtOrEq('applicant_stocks_number', 2) && this.lib.visibleAndIcontains('applicant_stocks', 'Yes');
            },
            applicant_stocks_no2: ["applicant_stocks_company2"],
            applicant_stocks_company3: function () {
              return this.lib.visibleAndGtOrEq('applicant_stocks_number', 3) && this.lib.visibleAndIcontains('applicant_stocks', 'Yes');
            },
            applicant_stocks_no3: ["applicant_stocks_company3"],
            applicant_stocks_company4: function () {
              return this.lib.visibleAndGtOrEq('applicant_stocks_number', 4) && this.lib.visibleAndIcontains('applicant_stocks', 'Yes');
            },
            applicant_stocks_no4: ["applicant_stocks_company4"],
            couple_capital: ["couple_livingaway"],
            couple_capitaldetails: function () {
              return this.lib.visibleAndIcontains('couple_capital', 'Yes');
            },
            couple_nsc: ["couple_livingaway"],
            couple_stocks: ["couple_livingaway"],
            couple_stocks_number: function () {
              return this.lib.visibleAndIcontains('couple_stocks', 'Yes');
            },
            couple_stocks_company1: function () {
              return this.lib.visibleAndGtOrEq('couple_stocks_number', 1) && this.lib.visibleAndIcontains('couple_stocks', 'Yes');
            },
            couple_stocks_no1: ["couple_stocks_company1"],
            couple_stocks_company2: function () {
              return this.lib.visibleAndGtOrEq('couple_stocks_number', 2) && this.lib.visibleAndIcontains('couple_stocks', 'Yes');
            },
            couple_stocks_no2: ["couple_stocks_company2"],
            couple_stocks_company3: function () {
              return this.lib.visibleAndGtOrEq('couple_stocks_number', 3) && this.lib.visibleAndIcontains('couple_stocks', 'Yes');
            },
            couple_stocks_no3: ["couple_stocks_company3"],
            couple_stocks_company4: function () {
              return this.lib.visibleAndGtOrEq('couple_stocks_number', 4) && this.lib.visibleAndIcontains('couple_stocks', 'Yes');
            },
            couple_stocks_no4: ["couple_stocks_company4"],
            applicant_savings: ["applicant_livingaway"],
            applicant_savingsno: function () {
              return this.lib.visibleAndIcontains('applicant_savings', 'Yes');
            },
            applicant_savingsvalue: ["applicant_savingsno"],
            applicant_premiumbonds: ["applicant_livingaway"],
            applicant_premiumbondsno: function () {
              return this.lib.visibleAndIcontains('applicant_premiumbonds', 'Yes');
            },
            applicant_premiumbondsvalue: ["applicant_premiumbondsno"],
            applicant_isa: ["applicant_livingaway"],
            applicant_isano: function () {
              return this.lib.visibleAndIcontains('applicant_isa', 'Yes');
            },
            applicant_isavalue: ["applicant_isano"],
            applicant_incomebonds: ["applicant_livingaway"],
            applicant_incomebondsno: function () {
              return this.lib.visibleAndIcontains('applicant_incomebonds', 'Yes');
            },
            applicant_incomebondsvalue: ["applicant_incomebondsno"],
            applicant_trust: ["applicant_livingaway"],
            applicant_trust_property: function () {
              return this.lib.visibleAndIcontains('applicant_trust', 'Property') || this.lib.visibleAndIcontains('applicant_trust', 'Both');
            },
            applicant_trust_property_value: ["applicant_trust_property"],
            applicant_trust_money: function () {
              return this.lib.visibleAndIcontains('applicant_trust', 'Money') || this.lib.visibleAndIcontains('applicant_trust', 'Both');
            },
            applicant_cash: ["applicant_livingaway"],
            applicant_cashamount: function () {
              return this.lib.visibleAndIcontains('applicant_cash', 'Yes');
            },
            applicant_savingsother: ["applicant_livingaway"],
            applicant_savingsotherdetails: function () {
              return this.lib.visibleAndIcontains('applicant_savingsother', 'Yes');
            },
            applicant_savingsothervalue: ["applicant_savingsotherdetails"],
            applicant_savingsinclude: ["applicant_livingaway"],
            applicant_property: ["applicant_livingaway"],
            couple_savings: ["couple_livingaway"],
            couple_savingsno: function () {
              return this.lib.visibleAndIcontains('couple_savings', 'Yes');
            },
            couple_savingsvalue: ["couple_savingsno"],
            couple_premiumbonds: ["couple_livingaway"],
            couple_premiumbondsno: function () {
              return this.lib.visibleAndIcontains('couple_premiumbonds', 'Yes');
            },
            couple_premiumbondsvalue: ["couple_premiumbondsno"],
            couple_isa: ["couple_livingaway"],
            couple_isano: function () {
              return this.lib.visibleAndIcontains('couple_isa', 'Yes');
            },
            couple_isavalue: ["couple_isano"],
            couple_incomebonds: ["couple_livingaway"],
            couple_incomebondsno: function () {
              return this.lib.visibleAndIcontains('couple_incomebonds', 'Yes');
            },
            couple_incomebondsvalue: ["couple_incomebondsno"],
            couple_trust: ["couple_livingaway"],
            couple_trust_property: function () {
              return this.lib.visibleAndIcontains('couple_trust', 'Property') || this.lib.visibleAndIcontains('couple_trust', 'Both');
            },
            couple_trust_property_trust: ["couple_trust_property"],
            couple_trust_money: function () {
              return this.lib.visibleAndIcontains('couple_trust', 'Money') || this.lib.visibleAndIcontains('couple_trust', 'Both');
            },
            couple_cash: ["couple_livingaway"],
            couple_cashamount: function () {
              return this.lib.visibleAndIcontains('couple_cash', 'Yes');
            },
            couple_savingsother: ["couple_livingaway"],
            couple_savingsotherdetails: function () {
              return this.lib.visibleAndIcontains('couple_savingsother', 'Yes');
            },
            couple_savingsothervalue: ["couple_savingsotherdetails"],
            couple_savingsinclude: ["couple_livingaway"],
            couple_property: ["couple_livingaway"],
            household_applicant_ct_responsibility: ["applicant_livingaway"],
            household_couple_ct_responsibility: ["couple_livingaway"],
            household_ct_responsibility_someone_else: function () {
              return this.lib.visibleAndIcontains('household_applicant_ct_responsibility', 'Someone else') || this.lib.visibleAndIcontains('household_applicant_ct_responsibility', 'Shared between you or your partner and someone else') || this.lib.visibleAndIcontains('household_couple_ct_responsibility', 'Someone else');
            },
            household_ct_responsibility_who: function () {
              return this.lib.visibleAndIcontains('household_ct_responsibility_someone_else', 'Another person');
            },
            household_ct_responsibility_who_residence: ["household_ct_responsibility_who"],
            household_ct_responsibility_who_residence_addressline1: function () {
              return this.lib.visibleAndIcontains('household_ct_responsibility_who_residence', 'No');
            },
            household_ct_responsibility_who_residence_addressline2: ["household_ct_responsibility_who_residence_addressline1"],
            household_ct_responsibility_who_residence_addressline3: ["household_ct_responsibility_who_residence_addressline1"],
            household_ct_responsibility_who_residence_postcode: ["household_ct_responsibility_who_residence_addressline1"],
            household_shared_co_owned_which: function () {
              return this.lib.visibleAndIcontains('household_shared_co_owned', 'Yes');
            },
            household_owned_mortgaged_joint_someone_else: function () {
              return this.lib.visibleAndIcontains('household_owned_mortgaged', 'Yes');
            },
            household_owned_mortgaged_joint_someone_else_who: function () {
              return this.lib.visibleAndIcontains('household_owned_mortgaged_joint_someone_else', 'Yes');
            },
            household_owned_mortgaged_joint_someone_else_residence: ["household_owned_mortgaged_joint_someone_else_who"],
            household_someoneelse_residence_addressline1: function () {
              return this.lib.visibleAndIcontains('household_owned_mortgaged_joint_someone_else_residence', 'No');
            },
            household_someoneelse_residence_addressline2: ["household_someoneelse_residence_addressline1"],
            household_someoneelse_residence_addressline3: ["household_someoneelse_residence_addressline1"],
            household_someoneelse_residence_postcode: ["household_someoneelse_residence_addressline1"],
            household_applicant_previously_owned: ["applicant_livingaway", function () {
              return this.lib.visibleAndIcontains('household_owned_mortgaged', 'No');
            }],
            household_couple_previously_owned: ["couple_livingaway", function () {
              return this.lib.visibleAndIcontains('household_owned_mortgaged', 'No');
            }],
            household_couple_previously_owned_addressline1: function () {
              return this.lib.visibleAndIcontains('household_main_home_elsewhere', 'Yes');
            },
            household_couple_previously_owned_addressline2: ["household_couple_previously_owned_addressline1"],
            household_couple_previously_owned_addressline3: ["household_couple_previously_owned_addressline1"],
            household_couple_previously_owned_postcode: ["household_couple_previously_owned_addressline1"],
            household_applicant_charged_rent: ["applicant_livingaway"],
            household_couple_charged_rent: ["couple_livingaway"],
            household_rent_charger: function () {
              return this.lib.visibleAndIcontains('household_applicant_charged_rent', 'Yes, I am charged rent or I already get Housing Benefit') ||
                this.lib.visibleAndIcontains('household_couple_charged_rent', 'Yes, I am charged rent or I already get Housing Benefit');
            },
            household_perm_temp_accommodation: ['household_rent_charger'],

            household_questions: function () {
              var showHouseholdQuestions = this.lib.visibleAndInList('household_rent_charger', [
                "I am charged rent by a housing association",
                "I am charged rent by a private landlord"
              ]);

              if (!showHouseholdQuestions) {
                return false;
              }

              var universalCredit = this.isReceivingUniversalCredit();
              var accommodation = this.isPermOrTempAccommodation();
              return !(universalCredit && accommodation);
            },

            not_receiving_uc_or_not_perm_temp_accommodation: function () {
              var universalCredit = this.isReceivingUniversalCredit();
              var accommodation = this.isPermOrTempAccommodation();
              return !(universalCredit && accommodation);
            },

            household_landlord_residence: ["household_questions"],
            household_building_type: ["household_questions"],
            household_building_type_houseboat: function () {
              return this.lib.visibleAndIcontains('household_building_type', 'Houseboat');
            },
            household_building_type_other: function () {
              return this.lib.visibleAndIcontains('household_building_type', 'Other');
            },
            household_building_facilities: ["household_questions"],
            household_building_decorating_responsibilities_applicant: ["household_questions", function () {
              return this.lib.visibleAndNotInList('applicant_uc', ["Yes", "Waiting to hear"]);
            }],
            household_building_decorating_responsibilities_couple: ["household_questions", function () {
              return this.lib.visibleAndNotInList('couple_uc', [
                "I get Universal Credit",
                "My partner gets Universal Credit",
                "We both get Universal Credit",
                "We are waiting to hear about our claim"]);
            }],
            household_building_furnishings: ["household_questions"],
            household_building_floors: ["household_questions"],
            household_building_floor_occupancy: ["household_questions"],
            household_building_floor_occupancy_other: function () {
              return this.lib.visibleAndArrayContainsElement('household_building_floor_occupancy', 'Other floor') !== -1;
            },
            household_building_sole_use: ["household_questions"],

            household_building_location_use: function () {
              return this.lib.visibleAndIcontains('household_building_sole_use', 'No');
            },
            household_livingrooms_wholebuilding: ["household_questions"],
            household_livingrooms_soleyapplicantshousehold: ["household_building_location_use"],
            household_livingrooms_shared: ["household_building_location_use"],
            household_diningrooms_wholebuilding: ["household_questions"],
            household_diningrooms_soleyapplicantshousehold: ["household_building_location_use"],
            household_diningrooms_shared: ["household_building_location_use"],
            household_bedsittingrooms_wholebuilding: ["household_questions"],
            household_bedsittingrooms_soleyapplicantshousehold: ["household_building_location_use"],
            household_bedsittingrooms_shared: ["household_building_location_use"],
            household_bedrooms_wholebuilding: ["household_questions"],
            household_bedrooms_soleyapplicantshousehold: ["household_building_location_use"],
            household_bedrooms_shared: ["household_building_location_use"],
            household_bathrooms_wholebuilding: ["household_questions"],
            household_bathrooms_soleyapplicantshousehold: ["household_building_location_use"],
            household_bathrooms_shared: ["household_building_location_use"],
            household_WC_wholebuilding: ["household_questions"],
            household_WC_soleyapplicantshousehold: ["household_building_location_use"],
            household_WC_shared: ["household_building_location_use"],
            household_kitchens_wholebuilding: ["household_questions"],
            household_kitchens_soleyapplicantshousehold: ["household_building_location_use"],
            household_kitchens_shared: ["household_building_location_use"],
            household_otherrooms_wholebuilding: ["household_questions"],
            household_otherrooms_soleyapplicantshousehold: ["household_building_location_use"],
            household_otherrooms_shared: ["household_building_location_use"],
            household_otherrooms_described: function () {
              return this.lib.getModelVisible('household_otherrooms_wholebuilding') >= 1;
            },
            household_bedroom_nonliveincarer: ["household_questions"],

            rent_from_housing_association_or_private_landlord: function () {
              return this.lib.visibleAndIcontains('household_rent_charger', 'I am charged rent by a housing association') ||
                this.lib.visibleAndIcontains('household_rent_charger', 'I am charged rent by a private landlord');
            },
            applicant_landlord: ["not_receiving_uc_or_not_perm_temp_accommodation", "rent_from_housing_association_or_private_landlord"],
            applicant_landlordsname: ["applicant_landlord"],
            applicant_landlordsaddressline1: ["applicant_landlord"],
            applicant_landlordsaddressline2: ["applicant_landlord"],
            applicant_landlordsaddressline3: ["applicant_landlord"],
            applicant_landlordspostcode: ["applicant_landlord"],
            applicant_landlordsphonenumber: ["applicant_landlord"],
            applicant_agent: ["applicant_landlord"],
            applicant_agentname: function () {
              return this.lib.visibleAndIcontains('applicant_agent', 'Yes');
            },
            applicant_agentaddress1: ["applicant_agentname"],
            applicant_agentaddress2: ["applicant_agentname"],
            applicant_agentaddress3: ["applicant_agentname"],
            applicant_agentpostcode: ["applicant_agentname"],
            applicant_agentphonenumber: ["applicant_agentname"],
            applicant_landlord_related: ["not_receiving_uc_or_not_perm_temp_accommodation", function () {
              return this.isRentingFromLandlordOrHousingAssociation('No');
            }],
            couple_landlord_related: ["not_receiving_uc_or_not_perm_temp_accommodation", function () {
              return this.isRentingFromLandlordOrHousingAssociation('Yes');
            }],
            applicant_landlord_relatedname: function () {
              return this.lib.visibleAndIcontains('applicant_landlord_related', 'Yes') ||
                this.lib.visibleAndIcontains('couple_landlord_related', 'Yes');
            },
            applicant_landlord_relationship: ["applicant_landlord_relatedname"],
            applicant_landlord_relatedto: ["applicant_landlord_relatedname"],
            applicant_rent_startdate: function () {
              return this.isRentingFromLandlordOrHousingAssociation('No');
            },
            couple_rent_startdate: function () {
              return this.isRentingFromLandlordOrHousingAssociation('Yes');
            },
            applicant_rent_howmuch: function () {
              return this.isRentingFromLandlordOrHousingAssociation('No');
            },
            couple_rent_howmuch: function () {
              return this.isRentingFromLandlordOrHousingAssociation('Yes');
            },
            applicant_rent_weeks_not_due_number: function () {
              return this.lib.visibleAndIcontains('applicant_rent_weeks_not_due', 'Yes');
            },
            applicant_rent_arrears_weeks: function () {
              return this.lib.visibleAndIcontains('applicant_rent_arrears', 'Yes');
            },
            applicant_rent_responsibility_shared: function () {
              return this.isRentingFromLandlordOrHousingAssociation('No');
            },
            couple_rent_responsibility_shared: function () {
              return this.isRentingFromLandlordOrHousingAssociation('Yes');
            },
            applicant_rent_responsibility_shared_names: function () {
              return this.lib.visibleAndIcontains('applicant_rent_responsibility_shared', 'Yes') ||
                this.lib.visibleAndIcontains('couple_rent_responsibility_shared', 'Yes');
            },
            applicant_rent_shared_amount: ["applicant_rent_responsibility_shared_names"],
            applicant_rent_shared_frequency: ["applicant_rent_responsibility_shared_names"],
            applicant_rent_heating_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_heating_charges', 'Yes');
            },
            applicant_rent_hotwater_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_hotwater_charges', 'Yes');
            },
            applicant_rent_cookingfuel_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_cookingfuel_charges', 'Yes');
            },
            applicant_rent_cleaningsharedareas_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_cleaningsharedareas_charges', 'Yes');
            },
            applicant_rent_personalcaresupport_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_personalcaresupport_charges', 'Yes');
            },
            applicant_rent_warden_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_warden_charges', 'Yes');
            },
            applicant_rent_meals_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_meals_charges', 'Yes');
            },
            applicant_rent_meals_included: ["applicant_rent_meals_charges_price"],
            applicant_rent_garageparking_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_garageparking_charges', 'Yes');
            },
            applicant_rent_garageparking_tenancyagreement: ["applicant_rent_garageparking_charges_price"],
            applicant_rent_lighting_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_lighting_charges', 'Yes');
            },
            applicant_rent_electricty_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_electricty_charges', 'Yes');
            },
            applicant_rent_laundry_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_laundry_charges', 'Yes');
            },
            applicant_rent_gardeningsharedareas_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_gardeningsharedareas_charges', 'Yes');
            },
            applicant_rent_emergencyalarm_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_emergencyalarm_charges', 'Yes');
            },
            applicant_rent_otherservices_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_otherservices_charges', 'Yes');
            },
            applicant_rent_otherservices_description: ["applicant_rent_otherservices_charges_price"],
            applicant_rent_seperate_charges_price: function () {
              return this.lib.visibleAndIcontains('applicant_rent_seperate_charges', 'Yes');
            },
            applicant_rent_seperate_charges_frequency: ["applicant_rent_seperate_charges_price"],
            applicant_rent_seperate_charges_description: ["applicant_rent_seperate_charges_price"],
            applicant_rent_securitysupport_responsibility: function () {
              return this.lib.visibleAndIcontains('applicant_rent_securitysupport', 'Yes');
            },
            applicant_rent_securitysupport_price: ["applicant_rent_securitysupport_responsibility"],
            applicant_rent_securitysupport_frequency: ["applicant_rent_securitysupport_responsibility"],
            applicant_payment_option_private: ["not_receiving_uc_or_not_perm_temp_accommodation", function () {
              return this.lib.visibleAndIcontains('household_rent_charger', 'I am charged rent by a private landlord');
            }],
            applicant_payment_option_ha: ["not_receiving_uc_or_not_perm_temp_accommodation", function () {
              return this.lib.visibleAndIcontains('household_rent_charger', 'I am charged rent by a housing association');
            }],
            applicant_paymenttype: function () {
              return this.lib.visibleAndIcontains('applicant_payment_option_private', "I would like any benefit paid directly to myself") ||
                this.lib.visibleAndIcontains('applicant_payment_option_ha', 'I would like any benefit paid directly to myself');
            },
            applicant_payment_accountname: function () {
              return this.lib.visibleAndIcontains('applicant_paymenttype', "Into a bank account");
            },
            applicant_payment_accountnumber: ["applicant_payment_accountname"],
            applicant_payment_accountsortcode: ["applicant_payment_accountname"],
            applicant_payment_bank: ["applicant_payment_accountname"],
            applicant_payment_bankaddressline1: ["applicant_payment_accountname"],
            applicant_payment_bankaddressline2: ["applicant_payment_accountname"],
            applicant_payment_bankaddressline3: ["applicant_payment_accountname"],
            applicant_payment_bankpostcode: ["applicant_payment_accountname"],
            applicant_payment_rollnumber: ["applicant_payment_accountname"],
            applicant_payment_difficulties_reason: function () {
              return this.lib.visibleAndIcontains('applicant_payment_option_private', "I would like any benefit paid directly to my landlord as I have difficulty managing my finances");
            },
            applicant_paymenttype_inarrears_privatedifficulties: ["applicant_payment_difficulties_reason"],
            applicant_paymenttype_inarrears: function () {
              return this.lib.visibleAndIcontains('applicant_payment_option_private', "I would like any benefit paid directly to my landlord as I am in rent arrears of 8 weeks or more");
            },
            applicant_payment_inarrears_accountname: function () {
              return this.lib.visibleAndIcontains('applicant_paymenttype_inarrears', 'Yes') ||
                this.lib.visibleAndIcontains('applicant_paymenttype_inarrears_privatedifficulties', 'Yes');
            },
            applicant_payment_inarrears_accountnumber: ["applicant_payment_inarrears_accountname"],
            applicant_payment_inarrears_accountsortcode: ["applicant_payment_inarrears_accountname"],
            applicant_payment_inarrears_bank: ["applicant_payment_inarrears_accountname"],
            applicant_payment_inarrears_bankaddressline1: ["applicant_payment_inarrears_accountname"],
            applicant_payment_inarrears_bankaddressline2: ["applicant_payment_inarrears_accountname"],
            applicant_payment_inarrears_bankaddressline3: ["applicant_payment_inarrears_accountname"],
            applicant_payment_inarrears_postcode: ["applicant_payment_inarrears_accountname"],
            applicant_payment_inarrears_rollnumber: ["applicant_payment_inarrears_accountname"],
            applicant_backdating_date: function () {
              return this.lib.visibleAndIcontains('applicant_backdating', 'Yes');
            },
            applicant_backdating_changes: ["applicant_backdating_date"],
            applicant_backdating_claimearlier: ["applicant_backdating_date"],
            applicant_completedby_reason: function () {
              return this.lib.visibleAndIcontains('applicant_completedby', 'Yes');
            },
            applicant_completedby_forename: ["applicant_completedby_reason"],
            applicant_completedby_lastname: ["applicant_completedby_reason"],
            applicant_completedby_addressline1: ["applicant_completedby_reason"],
            applicant_completedby_addressline2: ["applicant_completedby_reason"],
            applicant_completedby_addressline3: ["applicant_completedby_reason"],
            applicant_completedby_postcode: ["applicant_completedby_reason"],
            applicant_completedby_tel: ["applicant_completedby_reason"],
            applicant_completedby_relationship: ["applicant_completedby_reason"],
            applicant_completedby_declaration: ["applicant_completedby_reason"],
            applicant_letterofauthority_name: function () {
              return this.lib.visibleAndIcontains('applicant_letterofauthority', 'Yes');
            },
            applicant_letterofauthority_addressline1: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_addressline2: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_addressline3: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_postcode: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_tel: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_relationship: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_declaration: function () {
              return this.lib.visibleAndIcontains('applicant_letterofauthority', 'Yes');
            },
            partner_declaration: ["partner_title"],
            fieldset_dependantchild2: function () {
              return this.lib.visibleAndGtOrEq('dependantchildren_no', 2);
            },
            fieldset_dependantchild3: function () {
              return this.lib.visibleAndGtOrEq('dependantchildren_no', 3);
            },
            fieldset_dependantchild4: function () {
              return this.lib.visibleAndGtOrEq('dependantchildren_no', 4);
            },
            group_other_adults: function () {
              return this.lib.visibleAndIcontains('other_adults', 'Yes');
            },
            sum_of_adults_other_adults: ["group_other_adults"],
            help_joint_tenant_1: ["jointtenant1_surname"],
            help_joint_tenant_2: ["jointtenant2_forename"],
            help_joint_tenant_3: ["jointtenant3_forename"],
            help_joint_tenant_4: ["jointtenant4_forename"],
            help_boarder_1: ["boarder1_forename"],
            help_boarder_2: ["boarder2_forename"],
            help_boarder_3: ["boarder3_forename"],
            help_boarder_4: ["boarder4_forename"],
            help_nondependant_1: ["nondependant1_forename"],
            template_nondependant1_otherincome1: ["nondependant1_otherincome1_type"],
            template_nondependant1_otherincome2: ["nondependant1_otherincome2_type"],
            template_nondependant1_otherincome3: ["nondependant1_otherincome3_type"],
            template_nondependant1_otherincome4: ["nondependant1_otherincome4_type"],
            help_nondependant_2: ["nondependant2_forename"],
            template_nondependant2_otherincome1: ["nondependant2_otherincome1_type"],
            template_nondependant2_otherincome2: ["nondependant2_otherincome2_type"],
            template_nondependant2_otherincome3: ["nondependant2_otherincome3_type"],
            template_nondependant2_otherincome4: ["nondependant2_otherincome4_type"],
            help_nondependant_3: ["nondependant3_forename"],
            template_nondependant3_otherincome1: ["nondependant3_otherincome1_type"],
            template_nondependant3_otherincome2: ["nondependant3_otherincome2_type"],
            template_nondependant3_otherincome3: ["nondependant3_otherincome3_type"],
            template_nondependant3_otherincome4: ["nondependant3_otherincome4_type"],
            help_nondependant_4: ["nondependant4_forename"],
            template_nondependant4_otherincome1: ["nondependant4_otherincome1_type"],
            template_nondependant4_otherincome2: ["nondependant4_otherincome2_type"],
            template_nondependant4_otherincome3: ["nondependant4_otherincome3_type"],
            template_nondependant4_otherincome4: ["nondependant4_otherincome4_type"],
            help_nondependant_1_with_children: ["nondependant1_forename"],
            fieldset_nondependant1child1: function () {
              return this.lib.visibleAndGtOrEq('nondependant1_childrenno', 1);
            },
            fieldset_nondependant1child2: function () {
              return this.lib.visibleAndGtOrEq('nondependant1_childrenno', 2);
            },
            fieldset_nondependant1child3: function () {
              return this.lib.visibleAndGtOrEq('nondependant1_childrenno', 3);
            },
            fieldset_nondependant1child4: function () {
              return this.lib.visibleAndGtOrEq('nondependant1_childrenno', 4);
            },
            help_nondependant_2_with_children: ["nondependant2_forename"],
            fieldset_nondependant2child1: function () {
              return this.lib.visibleAndGtOrEq('nondependant2_childrenno', 1);
            },
            fieldset_nondependant2child2: function () {
              return this.lib.visibleAndGtOrEq('nondependant2_childrenno', 2);
            },
            fieldset_nondependant2child3: function () {
              return this.lib.visibleAndGtOrEq('nondependant2_childrenno', 3);
            },
            fieldset_nondependant2child4: function () {
              return this.lib.visibleAndGtOrEq('nondependant2_childrenno', 4);
            },
            help_nondependant_3_with_children: ["nondependant3_forename"],
            fieldset_nondependant3child1: function () {
              return this.lib.visibleAndGtOrEq('nondependant3_childrenno', 1);
            },
            fieldset_nondependant3child2: function () {
              return this.lib.visibleAndGtOrEq('nondependant3_childrenno', 2);
            },
            fieldset_nondependant3child3: function () {
              return this.lib.visibleAndGtOrEq('nondependant3_childrenno', 3);
            },
            fieldset_nondependant3child4: function () {
              return this.lib.visibleAndGtOrEq('nondependant3_childrenno', 4);
            },
            help_nondependant_4_with_children: ["nondependant4_forename"],
            fieldset_nondependant4child1: function () {
              return this.lib.visibleAndGtOrEq('nondependant4_childrenno', 1);
            },
            fieldset_nondependant4child2: function () {
              return this.lib.visibleAndGtOrEq('nondependant4_childrenno', 2);
            },
            fieldset_nondependant4child3: function () {
              return this.lib.visibleAndGtOrEq('nondependant4_childrenno', 3);
            },
            fieldset_nondependant4child4: function () {
              return this.lib.visibleAndGtOrEq('nondependant4_childrenno', 4);
            },
            help_applicant_self_employed_time: ["applicant_kind_of_work_se"],
            section_applicant_self_employed_time: ["applicant_kind_of_work_se"],
            help_partner_self_employed_time: ["partner_kind_of_work_se"],
            section_partner_self_employed_time: ["partner_kind_of_work_se"],
            template_applicant_employer1: ["applicant_kind_of_work_e1"],
            help_applicant_employed1_time: ["applicant_kind_of_work_e1"],
            section_applicant_employed1_time: ["applicant_kind_of_work_e1"],
            applicant_employed_hours_worked1_minutes: function () {
              return this.lib.visibleAndGtOrEq('applicant_employed_hours_worked1_hours', 0);
            },
            section_applicant_employed2_time: ["applicant_kind_of_work_e2"],
            applicant_employed_hours_worked2_minutes: function () {
              return this.lib.visibleAndGtOrEq('applicant_employed_hours_worked2_hours', 0);
            },
            section_applicant_employed3_time: ["applicant_kind_of_work_e3"],
            applicant_employed_hours_worked3_minutes: function () {
              return this.lib.visibleAndGtOrEq('applicant_employed_hours_worked3_hours', 0);
            },
            section_applicant_employed4_time: ["applicant_kind_of_work_e4"],
            applicant_employed_hours_worked4_minutes: function () {
              return this.lib.visibleAndGtOrEq('applicant_employed_hours_worked4_hours', 0);
            },
            template_applicant_employer2: ["applicant_kind_of_work_e2"],
            help_applicant_employed2_time: ["applicant_kind_of_work_e2"],
            template_applicant_employer3: ["applicant_kind_of_work_e3"],
            help_applicant_employed3_time: ["applicant_kind_of_work_e3"],
            template_applicant_employer4: ["applicant_kind_of_work_e4"],
            help_applicant_employed4_time: ["applicant_kind_of_work_e4"],
            template_applicant_pension1: ["applicant_employed_pension_name1"],
            template_applicant_pension2: ["applicant_employed_pension_name2"],
            template_applicant_pension3: ["applicant_employed_pension_name3"],
            template_applicant_pension4: ["applicant_employed_pension_name4"],
            partner_applicant_employer1: ["partner_kind_of_work_e1"],
            help_partner_employed1_time: ["partner_kind_of_work_e1"],
            partner_employed_hours_worked1_minutes: function () {
              return this.lib.visibleAndGtOrEq('partner_employed_hours_worked1_hours', 0);
            },
            section_partner_employed1_time: ["partner_kind_of_work_e1"],
            section_partner_employed2_time: ["partner_kind_of_work_e2"],
            section_partner_employed3_time: ["partner_kind_of_work_e3"],
            section_partner_employed4_time: ["partner_kind_of_work_e4"],
            partner_applicant_employer2: ["partner_kind_of_work_e2"],
            help_partner_employed2_time: ["partner_kind_of_work_e2"],
            partner_employed_hours_worked2_minutes: function () {
              return this.lib.visibleAndGtOrEq('partner_employed_hours_worked2_hours', 0);
            },
            partner_applicant_employer3: ["partner_kind_of_work_e3"],
            help_partner_employed3_time: ["partner_kind_of_work_e3"],
            partner_employed_hours_worked3_minutes: function () {
              return this.lib.visibleAndGtOrEq('partner_employed_hours_worked3_hours', 0);
            },
            partner_applicant_employer4: ["partner_kind_of_work_e4"],
            help_partner_employed4_time: ["partner_kind_of_work_e4"],
            partner_employed_hours_worked4_minutes: function () {
              return this.lib.visibleAndGtOrEq('partner_employed_hours_worked4_hours', 0);
            },
            template_partner_pension1: ["partner_employed_pension_name1"],
            template_partner_pension2: ["partner_employed_pension_name2"],
            template_partner_pension3: ["partner_employed_pension_name3"],
            template_partner_pension4: ["partner_employed_pension_name4"],
            template_applicant_company1: ["applicant_other_benefit_pension_company1"],
            template_applicant_company2: ["applicant_other_benefit_pension_company2"],
            template_applicant_company3: ["applicant_other_benefit_pension_company3"],
            template_applicant_company4: ["applicant_other_benefit_pension_company4"],
            template_partner_company1: ["partner_other_benefit_pension_company1"],
            template_partner_company2: ["partner_other_benefit_pension_company2"],
            template_partner_company3: ["partner_other_benefit_pension_company3"],
            template_partner_company4: ["partner_other_benefit_pension_company4"],
            template_applicant_other_income1: ["other_income_1_use"],
            template_applicant_other_income2: ["other_income_2_use"],
            template_applicant_other_income3: ["other_income_3_use"],
            template_applicant_other_income4: ["other_income_4_use"],
            template_applicant_account1: ["applicant_account1_bank"],
            template_applicant_account2: ["applicant_account2_bank"],
            template_applicant_account3: ["applicant_account3_bank"],
            template_applicant_account4: ["applicant_account4_bank"],
            template_couple_account1: ["couple_account1_bank"],
            template_couple_account2: ["couple_account2_bank"],
            template_couple_account3: ["couple_account3_bank"],
            template_couple_account4: ["couple_account4_bank"],
            help_your_landlord: ["your_landlord"],
            template_applicant_landlord_address_lookup: ["applicant_landlord"],
            help_applicant_payment_difficulties_reason: ["applicant_payment_difficulties_reason", 'rent_charge'],
            fieldset_dependantchild1_child_care_1: function () {
              return this.lib.visibleAndGtOrEq('dependantchild1_childcare_number', 1);
            },
            fieldset_dependantchild1_child_care_2: function () {
              return this.lib.visibleAndGtOrEq('dependantchild1_childcare_number', 2);
            },
            fieldset_dependantchild1_child_care_3: function () {
              return this.lib.visibleAndGtOrEq('dependantchild1_childcare_number', 3);
            },
            fieldset_dependantchild1_child_care_4: function () {
              return this.lib.visibleAndGtOrEq('dependantchild1_childcare_number', 4);
            },
            fieldset_dependantchild2_child_care_1: function () {
              return this.lib.visibleAndGtOrEq('dependantchild2_childcare_number', 1);
            },
            fieldset_dependantchild2_child_care_2: function () {
              return this.lib.visibleAndGtOrEq('dependantchild2_childcare_number', 2);
            },
            fieldset_dependantchild2_child_care_3: function () {
              return this.lib.visibleAndGtOrEq('dependantchild2_childcare_number', 3);
            },
            fieldset_dependantchild2_child_care_4: function () {
              return this.lib.visibleAndGtOrEq('dependantchild2_childcare_number', 4);
            },
            fieldset_dependantchild3_child_care_1: function () {
              return this.lib.visibleAndGtOrEq('dependantchild3_childcare_number', 1);
            },
            fieldset_dependantchild3_child_care_2: function () {
              return this.lib.visibleAndGtOrEq('dependantchild3_childcare_number', 2);
            },
            fieldset_dependantchild3_child_care_3: function () {
              return this.lib.visibleAndGtOrEq('dependantchild3_childcare_number', 3);
            },
            fieldset_dependantchild3_child_care_4: function () {
              return this.lib.visibleAndGtOrEq('dependantchild3_childcare_number', 4);
            },
            fieldset_dependantchild4_child_care_1: function () {
              return this.lib.visibleAndGtOrEq('dependantchild4_childcare_number', 1);
            },
            fieldset_dependantchild4_child_care_2: function () {
              return this.lib.visibleAndGtOrEq('dependantchild4_childcare_number', 2);
            },
            fieldset_dependantchild4_child_care_3: function () {
              return this.lib.visibleAndGtOrEq('dependantchild4_childcare_number', 3);
            },
            fieldset_dependantchild4_child_care_4: function () {
              return this.lib.visibleAndGtOrEq('dependantchild4_childcare_number', 4);
            },
            template_dependantchild1_address_lookup: function () {
              return this.lib.visibleAndIcontains('dependantchild1_school', 'Yes');
            },
            template_dependantchild2_address_lookup: function () {
              return this.lib.visibleAndIcontains('dependantchild3_school', 'Yes');
            },
            template_dependantchild3_address_lookup: ["template_dependantchild2_address_lookup"],
            template_dependantchild4_address_lookup: function () {
              return this.lib.visibleAndIcontains('dependantchild4_school', 'Yes');
            },
            applicant_righttostay: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('applicant_residentsincedate'), 5);
            },
            applicant_asylum: ["applicant_righttostay"],
            applicant_care: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('applicant_dob'), 22);
            },
            partner_righttostay: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('partner_residentsincedate'), 5);
            },
            partner_asylum: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('partner_residentsincedate'), 5);
            },
            partner_care: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('partner_dob'), 22);
            },
            dependantchild1_pip: function () {
              return dateUtils.isOlderOrEquals(this.lib.getModelVisible('dependantchild1_dob'), 16);
            },
            dependantchild1_school: function () {
              return (this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes') || this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes')) && dateUtils.isOfSchoolAge(this.lib.getModelVisible('dependantchild1_dob'));
            },
            dependantchild2_pip: function () {
              return dateUtils.isOlderOrEquals(this.lib.getModelVisible('dependantchild2_dob'), 16);
            },
            dependantchild2_school: function () {
              return (this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes') || this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes')) && dateUtils.isOfSchoolAge(this.lib.getModelVisible('dependantchild2_dob'));
            },
            dependantchild3_pip: function () {
              return dateUtils.isOlderOrEquals(this.lib.getModelVisible('dependantchild3_dob'), 16);
            },
            dependantchild3_school: function () {
              return (this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes') || this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes')) && dateUtils.isOfSchoolAge(this.lib.getModelVisible('dependantchild3_dob'));
            },
            dependantchild4_pip: function () {
              return dateUtils.isOlderOrEquals(this.lib.getModelVisible('dependantchild4_dob'), 16);
            },
            dependantchild4_school: function () {
              return (this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes') || this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes')) && dateUtils.isOfSchoolAge(this.lib.getModelVisible('dependantchild4_dob'));
            },
            has_other_adults: function () {
              return this.lib.visibleAndIcontains('other_adults', 'Yes');
            },
            has_joint_tenant: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 1);
            },
            has_boarder: [
              function () {
                return this.lib.visibleAndGtOrEq('boarders', 1);
              }
            ],
            rent_charge: [
              function () {
                return this.lib.visibleAndIcontains('household_applicant_charged_rent', 'Yes, I am charged rent or I already get Housing Benefit') ||
                  this.lib.visibleAndIcontains('household_couple_charged_rent', 'Yes, I am charged rent or I already get Housing Benefit') ||
                  this.lib.visibleAndIcontains('household_rent_charger', 'I am charged rent by a housing association') ||
                  this.lib.visibleAndIcontains('household_rent_charger', 'I am charged rent by a private landlord');
              }
            ],
            your_landlord: function () {
              return this.isRentingFromLandlordOrHousingAssociation();
            },
            applicant_rent_affordable_initally: ["applicant_landlord"],
            applicant_rent_arrears: ["applicant_landlord"],
            applicant_rent_cleaningsharedareas_charges: ["applicant_landlord"],
            applicant_rent_cookingfuel_charges: ["applicant_landlord"],
            applicant_rent_electricty_charges: ["applicant_landlord"],
            applicant_rent_emergencyalarm_charges: ["applicant_landlord"],
            applicant_rent_fair_rent_registered: ["applicant_landlord"],
            applicant_rent_frequency: function () {
              return this.isRentingFromLandlordOrHousingAssociation();
            },
            applicant_rent_garageparking_charges: ["applicant_landlord"],
            applicant_rent_gardeningsharedareas_charges: ["applicant_landlord"],
            applicant_rent_heating_charges: ["applicant_landlord"],
            applicant_rent_hotwater_charges: ["applicant_landlord"],
            applicant_rent_increase_due: ["applicant_landlord"],
            applicant_rent_laundry_charges: ["applicant_landlord"],
            applicant_rent_lighting_charges: ["applicant_landlord"],
            applicant_rent_meals_charges: ["applicant_landlord"],
            applicant_rent_otherservices_charges: ["applicant_landlord"],
            applicant_rent_personalcaresupport_charges: ["applicant_landlord"],
            applicant_rent_securitysupport: ["applicant_landlord"],
            applicant_rent_seperate_charges: ["applicant_landlord"],
            applicant_rent_tenancyenddate: ["applicant_landlord"],
            applicant_rent_tenancytype: ["applicant_landlord"],
            applicant_rent_warden_charges: ["applicant_landlord"],
            applicant_rent_weeks_not_due: ["applicant_landlord"],
            applicant_rentcharge_startdate: function () {
              return this.isRentingFromLandlordOrHousingAssociation();
            },
            applicant_water_bill_seperate: ["applicant_landlord"],
            partner_employed_pension: ["partner_title"]
          }
        });

        service.isApplicantReceivingUniversalCredit = function () {
          return this.visibleAndInList('applicant_uc', ["Yes", "Waiting to hear"]);
        };

        service.isReceivingUniversalCredit = function () {
          var coupleUniversalCredit = this.visibleAndInList('couple_uc', [
            "I get Universal Credit",
            "My partner gets Universal Credit",
            "We both get Universal Credit",
            "We are waiting to hear about our claim"]);

          return coupleUniversalCredit || this.isApplicantReceivingUniversalCredit();
        };

        service.isPermOrTempAccommodation = function () {
          return this.visibleAndInList('household_perm_temp_accommodation', [
            "I live in permanent accommodation",
            "I live in temporary accommodation"]);
        };

        service.isRentingFromLandlordOrHousingAssociation = function (coupleStatus) {
          var coupleStatusResult = coupleStatus ? this.visibleAndIcontains('couple', coupleStatus) : true;
          return coupleStatusResult && this.visibleAndInList('household_rent_charger',
            ['I am charged rent by a housing association', 'I am charged rent by a private landlord']);
        };

        return service;
      }]);

  }(angular)
);
