'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'DE_FCA_FORM',
    [
      'FormUI',
      'derbyshireCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      'fieldDefinitionService',
      'DE_FCA_CONDITIONS',
      '$window',

      function (
        FormUI,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        fieldDefinitionService,
        conditions,
        $window
      ) {

      var constants = $window.kerpCfa.getConstants('DE_FCA');

        var formUI = new FormUI();

        function periodicPaymentField(fieldName, options) {
          return {
            type: 'section',
            htmlClass: 'row',
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-4"
              }
            ]
          };
        }

        function fullWidthPeriodicPaymentField(fieldName, options, condition) {
          condition = condition || 'true';

          return {
            type: 'section',
            htmlClass: 'row',
            condition: condition,
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-7 col-sm-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-3 col-sm-4"
              }
            ]
          };
        }

        function paymentField(fieldName, overrides) {
          return angular.extend({
            key: fieldName,
            feedback: false
          }, overrides);
        }

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel);
        };

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          var withholdFinancialDetailsAndPayFullCost = intro.provideFinancialDetails === 'payFullCost';
          var withholdFinancialDetailsAndArrangeOwnCare = intro.provideFinancialDetails === 'arrangeMyOwn';

          let hiddenPages = [];

          if (withholdFinancialDetailsAndPayFullCost) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9];
          } else if (withholdFinancialDetailsAndArrangeOwnCare) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9, 10, 11];
          } else {
            hiddenPages = [];
          }

          return hiddenPages;
        };

        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            htmlClass: 'row',
            items: [
              {
                type: 'fieldset',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_01_intro.html'
                  },
                ]
              },
              {
                type: 'fieldset',
                title: 'People acting on your behalf',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/agentVideoGuidance.html'
                  },
                  'agent.hasAppointee',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>Appointeeship for State Benefits gives a person the right to deal with the benefits of someone who cannot manage their own affairs because they\'re mentally incapable or severely disabled. Additional information is available on <a href=\"https://www.gov.uk/become-appointee-for-someone-claiming-benefits\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasAppointee',
                    key: 'agent.appointeeDetails'
                  },
                  'agent.hasDeputy',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>You are someone’s deputy if they \'lack mental capacity\'. This means they cannot make a decision for themselves at the time it needs to be made. They may still be able to make decisions for themselves at certain times. Additional information is available on <a href=\"https://www.gov.uk/become-deputy\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasDeputy',
                    key: 'agent.deputyDetails'
                  },
                  'agent.hasPowerOfAttorney',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>A power of attorney is a legal document that allows someone to make decisions for you, or act on your behalf, if you\'re no longer able to or if you no longer want to make your own decisions. Additional information is available on <a href=\"https://www.gov.uk/power-of-attorney\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasPowerOfAttorney',
                    key: 'agent.powerOfAttorneyDetails'
                  },
                  'agent.isCompletedByAgent',
                  {
                    condition: 'model.agent.isCompletedByAgent',
                    key: 'agent.completedByDetails'
                  }
                ]
              },
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 2',
            page: 2,
            htmlClass: "row",
            items: [
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: ['clientID']
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About you',
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_02_about-you.html'
                  },
                  'person.details.title',
                  'person.details.firstName',
                  'person.details.middleName',
                  'person.details.lastName',
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/DE_FCA/templates/addressLookup.html",
                    homeAddressDescription: "If you have moved into a care home please put the address that you were living at before your move."
                  },
                  // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                  {
                    key: 'person.details.address.line1',
                    title: 'Address line 1',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line2',
                    title: 'Address line 2',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line3',
                    title: 'Address line 3',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.postcode',
                    title: 'Postcode',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.uprn',
                    title: 'Property Reference',
                    type: "hidden"
                  },
                  'person.details.homePhoneNumber',
                  'person.details.mobilePhoneNumber',
                  'person.details.email',
                  'person.details.nhsNumber',
                  {
                    key: 'person.details.dob',
                    description: 'We need this information to calculate your State Pension age'
                  }
                ]
              },
              {
                type: 'fieldset',
                title: "Declaring your financial circumstances",
                htmlClass: "col-md-12",
                items: [
                  'person.intro.provideFinancialDetails',
                  {
                    key: 'person.details.nationalInsuranceNumber',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.hasPartner',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DE_FCA/templates/partnerDescription.html')
                  },
                  {
                    key: 'person.intro.partnerStatus',
                    condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.otherRecievesCarersAllowance',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"'
                  },
                ]
              },

              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About your partner',
                condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                items: [
                  'partner.intro.alreadyReceivingCare',
                  {
                    key: 'partner.details.partnerReceivesResidentialCare',
                    condition: 'model.person.intro.partnerStatus === "spouse"',
                  },
                  'partner.details.sameAddress'
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 3',
            page: 3,
            title: 'People living with you',
            items: [
              {
                key: 'peopleLivingWithYou.hasDependentChildren',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DE_FCA/templates/dependentChildrenDescription.html')
              },
              {
                key: 'peopleLivingWithYou.dependentChildren',
                condition: 'model.peopleLivingWithYou.hasDependentChildren',
                add: "Add Child",
                title: 'Dependent children details',
                validationMessage: 'Complete all required fields for at least one child'
              },
              {
                key: 'peopleLivingWithYou.hasOtherPeople',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DE_FCA/templates/nonDependentsDescription.html')
              },
              {
                key: 'peopleLivingWithYou.otherPeople',
                condition: 'model.peopleLivingWithYou.hasOtherPeople',
                add: "Add Person",
                title: 'Details of other people living with you',
                validationMessage: 'Complete all required fields for at least one person'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 4',
            page: 4,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income and benefits',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_04_income-intro.html'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'person.income.hasWage',
                        title: 'Are you currently employed?'
                      },
                      {
                        key: 'person.income.wages',
                        condition: 'model.person.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pension or Annuity</h4>"
                      },
                      {
                        key: 'person.income.hasPrivatePension',
                        title: 'Are you in receipt of private/works pension or annuity?'
                      },
                      {
                        key: 'person.income.privatePensions',
                        condition: 'model.person.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      {
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?',
                        description: 'Universal Credit (UC) is made up of several elements, not all of these will be used in your financial assessment for adult care. The elements you receive are listed on your UC award. In order to work out the amount of UC to use in your assessment, you need to enter all the elements of UC you receive and all the amounts deducted from your UC payment, as listed on your award letter.',
                      },
                      {
                        key: 'person.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.person.income.hasUniversalCredit'
                      },


                      periodicPaymentField('person.income.retirementPension'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.incomeSupportPayment'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.incapacityBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.employmentSupport'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.jobSeekersAllowance'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.industrialInjuriesBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.rentalIncome'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.workingTaxCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.childTaxCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.maintenance'),
                      periodicPaymentField('person.income.warPension', {
                        description: 'A War Pension payable to a former member of the armed forces will be fully disregarded.'
                      }),
                      periodicPaymentField('person.income.warWidowsPension'),
                      periodicPaymentField('person.income.warSpecialPayment', {
                        description: 'The supplementary element of a War Widow(er) Pension will be fully disregarded. The current amount from 8 April 2024 is £119.23.'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.childBenefit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.carersAllowance'),

                      paymentField('person.income.attendanceAllowance', {
                        condition: conditions.toString('person.income.attendanceAllowance')
                      }),
                      paymentField('person.income.dlaCare', {
                        condition: conditions.toString('person.income.dlaCare')
                      }),
                      paymentField('person.income.pipDailyLiving', {
                        condition: conditions.toString('person.income.pipDailyLiving')
                      }),

                      paymentField('person.income.mobilityAllowance'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.pensionGuaranteeCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.pensionSavingsCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.charitableIncome'),

                      periodicPaymentField('person.income.cashInLieu'),

                      periodicPaymentField('person.income.housingBenefit'),

                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },
                      'person.details.inEducation',
                      {
                        key: 'person.details.educationDetails',
                        condition: 'model.person.details.inEducation'
                      },
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 5',
            page: 5,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your money or investments',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_03_capital-intro.html'
                      },
                      {
                        key: 'person.capital.hasSavingsInvestments',
                        title: 'Do you have any bank, building society accounts, savings or investments held in your own, sole name?',
                        description: 'This includes your main account where you receive your income, benefits, pension, wages etc, any current accounts, bill paying accounts, post office accounts, savings, ISAs, fixed rate bonds etc even if your account is overdrawn.'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasSavingsInvestments',
                        title: 'Do you have any bank, building society account, savings or investments held in your name together with someone else?',
                        description: 'You should include the accounts where you receive your income, benefits, pension, wages etc, any current accounts, bill paying accounts, post office accounts, savings, ISAs, fixed rate bonds etc even if the account is overdrawn.'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/capitalDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of your savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of jointly owned savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your shareholdings',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasShareholdings',
                        title: 'Are you the sole owner of any company shares?'
                      },
                      {
                        key: 'person.capital.shareholdings',
                        condition: 'model.person.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of your shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasShareholdings',
                        title: 'Do you and someone else jointly own any company shares?'
                      },
                      {
                        key: 'joint.capital.shareholdings',
                        condition: 'model.joint.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of jointly owned shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 6',
            page: 6,
            title: 'Property/Land you own or rent',
            items: [
              'person.property.ownership',
              'person.property.propertyType',
              {
                key: 'person.property.exercisedRightToBuy',
                title: 'Did you purchase your property under the Right to Buy scheme?',
                condition: 'model.person.property.ownership === "ownedByYou" || model.person.property.ownership === "ownedByPartner" || model.person.property.ownership === "ownedJointly"'
              },
              {
                key: 'person.property.rightToBuyInformation',
                condition: 'model.person.property.exercisedRightToBuy'
              },
              {
                key: 'person.property.otherProperty',
                condition: 'model.person.property.ownership === "other"'
              },
              {
                key: 'person.property.value',
                description: "Input approximate value of the property if applying for residential/nursing care funding. If applying for a non-residential care service put 0 for the value.",
                condition: conditions.toString('person.property.value')
              },
              'person.property.hasAdditionalProperties',
              {
                key: 'person.property.additionalProperties',
                condition: 'model.person.property.hasAdditionalProperties',
                add: "Add property",
                title: 'We need to know value(s) of any property/properties, including land, owned and/or jointly owned by you in this country or abroad other than your main home.',
                validationMessage: 'Enter details of at least one property'
              },
              'person.property.additionalInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 7',
            page: 7,
            title: 'Sale or Transfer of property, money or investments',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12_capital_transfers-gifting-intro.html'
              },
              'deprivationOfAssets.hasDisposedOfAssets',
              {
                key: 'deprivationOfAssets.disposedAssets.assetValue',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.dateOfDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.assetsDisposed',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/deprivationOfAssetsDescription.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.reasonForDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/deprivationOfAssetsReason.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 8',
            page: 8,
            title: 'Household Expenditure',
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-8",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_05_outgoings-intro.html'
                      },
                      fullWidthPeriodicPaymentField('person.expenses.mortgageLessHousing', {}, '!model.person.income.hasUniversalCredit'),
                      fullWidthPeriodicPaymentField('person.expenses.mortgage', {}, 'model.person.income.hasUniversalCredit'),

                      fullWidthPeriodicPaymentField('person.expenses.rentLessHousing', {}, '!model.person.income.hasUniversalCredit'),
                      fullWidthPeriodicPaymentField('person.expenses.rent', {}, 'model.person.income.hasUniversalCredit'),

                      paymentField('person.expenses.councilTax'),
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/councilTaxDescription.html'
                      },

                      fullWidthPeriodicPaymentField('person.expenses.endowmentInsurance'),

                      fullWidthPeriodicPaymentField('person.expenses.serviceCharge'),

                      fullWidthPeriodicPaymentField('person.expenses.groundRent'),

                      fullWidthPeriodicPaymentField('person.expenses.homeInsurance'),
                      {
                        key: 'person.expenses.isHomeInsuranceCombined',
                        condition: 'model.person.expenses.homeInsurance'
                      },

                      fullWidthPeriodicPaymentField('person.expenses.compulsoryCourtPayments'),

                      fullWidthPeriodicPaymentField('person.expenses.communityAlarm'),

                      'person.expenses.hasAdditionalExpenses',

                      {
                        condition: 'model.person.expenses.hasAdditionalExpenses',
                        key: 'person.expenses.additionalExpenses'
                      },
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/householdExpensesDescription.html'
                      }

                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 9',
            page: 9,
            title: 'Disability-Related Expenses',
            items: [
              'person.intro.excludeNonResidentialCaretypes',
              {
                type: 'fieldset',
                condition: 'model.person.intro.excludeNonResidentialCaretypes === false',
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/DE_FCA/templates/dre.html"
                  },
                  'person.expenses.disabilityRelated.hasOtherDRE',
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/dreGuidance.html',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.otherDRE',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                    htmlClass: 'invisible'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 10',
            page: 10,
            title: 'Other information',
            items: [
              'otherInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 11',
            page: 11,
            title: 'Declaration',
            items: [
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/DE_FCA/templates/declaration-intro.html"
              },
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/DE_FCA/templates/declaration-financial-assessment.html"
              },
              'declarations.financialAssessmentDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/DE_FCA/templates/declaration-changes.html"
              },
              'declarations.financialChangesDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/DE_FCA/templates/declaration-failure-to-pay.html"
              },
              'declarations.failureToPayDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/DE_FCA/templates/declaration-deprivation.html"
              },
              'declarations.deprivationDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/DE_FCA/templates/declaration-evidence.html"
              },
              'declarations.declaration'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 12',
            page: 12,
            title: 'Cost of care',
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/costOfCareIntro.html'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/costOfCareNonResHelp.html'
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Non-residential (Community Contribution)',
                    htmlClass: "col-md-6",
                    condition: conditions.toString('person.intro.excludeNonResidentialCaretypes'),
                    items: [
                      {
                        key: 'careAssessment.nonResidentialCoFunding.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DE_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.nonResidentialCoFunding,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum non-residential (community contribution) care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: [
                          'careAssessment.nonResidentialCoFunding.workings.grossIncome',
                          'careAssessment.nonResidentialCoFunding.workings.capitalTariff',
                          'careAssessment.nonResidentialCoFunding.workings.disregardsTotal',
                          'careAssessment.nonResidentialCoFunding.workings.expensesTotal',
                          'careAssessment.nonResidentialCoFunding.workings.dreTotal',
                          'careAssessment.nonResidentialCoFunding.workings.livingAllowanceTotal',
                        ]
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: 'Non-residential (Extra Care)',
                    htmlClass: "col-md-6",
                    condition: conditions.toString('person.intro.excludeNonResidentialCaretypes'),
                    items: [
                      {
                        key: 'careAssessment.nonResidentialExtraCare.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DE_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.nonResidentialExtraCare,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum non-residential (Extra Care) contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: [
                          'careAssessment.nonResidentialExtraCare.workings.grossIncome',
                          'careAssessment.nonResidentialExtraCare.workings.capitalTariff',
                          'careAssessment.nonResidentialExtraCare.workings.disregardsTotal',
                          'careAssessment.nonResidentialExtraCare.workings.expensesTotal',
                          'careAssessment.nonResidentialExtraCare.workings.livingAllowanceTotal',
                        ]
                      }
                    ]
                  },
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/costOfCareResidentialHelp.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Residential Weeks 1-12",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.residentialWeek1.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DE_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.residentialWeek1,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: [
                          'careAssessment.residentialWeek1.workings.grossIncome',
                          'careAssessment.residentialWeek1.workings.capitalTariff',
                          'careAssessment.residentialWeek1.workings.disregardsTotal',
                          'careAssessment.residentialWeek1.workings.expensesTotal',
                          'careAssessment.residentialWeek1.workings.livingAllowanceTotal',
                        ]
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Residential Weeks 13+",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.residentialWeek13.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DE_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.residentialWeek13,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: [
                          'careAssessment.residentialWeek13.workings.grossIncome',
                          'careAssessment.residentialWeek13.workings.capitalTariff',
                          'careAssessment.residentialWeek13.workings.disregardsTotal',
                          'careAssessment.residentialWeek13.workings.expensesTotal',
                          'careAssessment.residentialWeek13.workings.livingAllowanceTotal',
                        ]
                      }
                    ]
                  },
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DE_FCA/templates/costOfCareHelp.html'
              },

            ]
          },
          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);
