'use strict';

angular.module('kerp-forms.forms').factory(
  'WOL_FCA_CONDITIONS',
  [
    'FormConditions',

    function (
      FormConditions
    ) {


      return FormConditions.clone({
        conditions: {
          'person.property.value': function () {
            return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
          },

          'person.intro.excludeNonResidentialCaretypes': function() {
            var excludeNonResidentialCaretypes = this.lib.getModelValue('person.intro.excludeNonResidentialCaretypes');
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');

            // residential care types should be visible only if the user has chosen to complete DRE
            // OR they have chosen not to agree to provide their financial details
            return !excludeNonResidentialCaretypes || provideFinancialDetails !== 'agree';
          },

          'person.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'person.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'person.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'person.income.pipMobilityAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

            return !hasDlaCare;
          },

          'person.income.dlaMobilityAllowance': function () {
            var hasPip = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasPip;
          },

          'partner.income.pipMobilityAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);

            return !hasDlaCare;
          },

          'partner.income.dlaMobilityAllowance': function () {
            var hasPip = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasPip;
          },

          'person.income.receivesExtraCare': function() {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return hasAA || hasDlaCare || hasPipDla;
          },

          'partner.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'partner.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'partner.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'partner.income.receivesExtraCare': function() {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return hasAA || hasDlaCare || hasPipDla;
          },

          'person.income.universalCredit': function () {
            var uc = this.lib.getModelValue('person.income.universalCredit');
            return uc > 0;
          },

          previousAddresses: function() {
            var dateMovedIn = this.lib.getModelValue('person.details.address.movedIn');
            var previousAddresses = this.lib.getModelValue('person.details.address.previousAddresses');

            const movedInDate = moment(dateMovedIn, 'DD/MM/YYYY');
            const threshold = moment().subtract(5, 'years');
            const dateDiff = movedInDate.diff(threshold, 'days');

            return (dateDiff > 0) || previousAddresses !== undefined;
          },

          applyingForNonResidentialCare: function() {
            const applyingForCareType = this.lib.getModelValue('person.intro.applyingForCaretype');

            return applyingForCareType === 'nonResidential' || applyingForCareType === 'unknown';
          },

          applyingForRespiteCare: function() {
            const applyingForCareType = this.lib.getModelValue('person.intro.applyingForCaretype');

            return applyingForCareType === 'respite' || applyingForCareType === 'unknown';
          },

          applyingForResidentialCare: function() {
            const applyingForCareType = this.lib.getModelValue('person.intro.applyingForCaretype');

            return applyingForCareType === 'residential' || applyingForCareType === 'unknown';
          },

          notApplyingForNonResidentialCare: function() {
            const applyingForCareType = this.lib.getModelValue('person.intro.applyingForCaretype');

            return applyingForCareType === 'residential' || applyingForCareType === 'respite' || applyingForCareType === 'unknown';
          },

          notApplyingForResidentialCare: function() {
            const applyingForCareType = this.lib.getModelValue('person.intro.applyingForCaretype');

            return applyingForCareType === 'nonResidential' || applyingForCareType === 'respite' || applyingForCareType === 'unknown';
          },

          applyingForResidentialCareOnly: function() {
            const applyingForCareType = this.lib.getModelValue('person.intro.applyingForCaretype');

            return applyingForCareType === 'residential';
          },

          showNonResidentialCostOfCare: function() {
            const excludeNonResidentialCaretypes = this.lib.getModelValue('person.intro.excludeNonResidentialCaretypes');
            const applyingForCareType = this.lib.getModelValue('person.intro.applyingForCaretype');


            return (applyingForCareType === 'nonResidential') || (applyingForCareType === 'unknown' && excludeNonResidentialCaretypes !== true);
          }
        },


      });
    }]);
